import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const OrdersTravelS = ({id}) => {

  const {t} = useTranslation();
  const [officesTravel,setofficesTravel] = useState([]);
  const fetchCategories = async () => {
    await axios.get('https://nawyapp.com/api/showorder_travel/' + id,
    ).then(({ data }) => {
      setofficesTravel(data)
    });
  }
  useEffect(() => {
    fetchCategories();
  }, [])

  return (
    <div className="text-right pt-8 bg-[#EBEAFA]">
      <main>
        <section className="flex flex-wrap justify-center max-w-7xl mx-auto py-3">
          {officesTravel.map((officeTravel) => (
            <div
              key={officeTravel.id}
              className="w-full sm:w-1/2 lg:w-1/2 p-2 my-[-60px] mt-[-30px] pb-[-40px]">
              <div className="w-full h-[60%] rounded-lg shadow-md overflow-hidden mb-2 bg-white float-right">
                <div className="flex pl-2">
                  <img
                    className="w-20 h-20 mt-[20px] object-cover"
                    src={`https://nawyapp.com/storage/${officeTravel.image}`}
                    alt="/"
                  />
                  <div className="p-4 flex flex-col justify-between float-right mt-[-5px]">
                    <div>
                      <div className="grid mr-[390px] mb-[-30px]">
                        <span className=" text-gray-500 px-2 py-2 rounded-full text-xs font-semibold mr-2">
                          {officeTravel.followers}
                        </span>
                      </div>

                      <div className="mb-12">
                        <h3 className="text-[23px] mr-[-15px] font-semibold">
                          {officeTravel.locationfor.city} - {officeTravel.locationto.city}
                        </h3>
                          <p className="text-blue-700 text-md p-2 ml-[290px] text-lg font-semibold cursor-pointer hover:text-[#FFA500]">
                            {t('navbar_travel_office')}
                          </p>
                        <p className="text-[#990033] hover:text-[#FFA500] cursor-pointer text-2xl flex float-right ml-3 pt-2 pb-4">
                          {officeTravel.price} $
                        </p>
                        <div className="flex mt-[-10px] pb-12 mr-[-10px]">
                          <Link to="/order_travel/travel_offieces_services/">
                            <span className=" text-gray-500 rounded-full text-lg font-semibold mr-2 hover:text-[#800080]">
                              {t('all_b_details')}
                            </span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </section>
      </main>
    </div>
  );
};
export default OrdersTravelS;