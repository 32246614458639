
import React, { useEffect, useState,useMemo  } from 'react';
import { Link } from 'react-router-dom';
import Image2 from '../../src/assets/ImagesVehicle/Profile.jfif';
// import '../../css/style2.css';
import { IoClose, IoAdd, IoMic, IoHappy } from 'react-icons/io5';
import EmojiPicker, { EmojiClickData } from 'emoji-picker-react';
import AudioReactRecorder, { RecordState } from 'audio-react-recorder';
import { FaPaperPlane } from 'react-icons/fa';
import { useDropzone } from 'react-dropzone';
import { TiAttachmentOutline } from "react-icons/ti";
import { useTranslation } from 'react-i18next';
import { useReactMediaRecorder } from "react-media-recorder";
import { FaStop } from "react-icons/fa";
import axios from 'axios';
import { jwtDecode } from 'jwt-decode'; // استيراد الدالة بشكل صحيح
import Pusher from 'pusher-js';



const Support = () => {

  const [isRecording, setIsRecording] = useState(false);
  const {
    startRecording,
    stopRecording,
    mediaBlobUrl,
  } = useReactMediaRecorder({ audio: true });

  const handleStartRecording = () => {
    setIsRecording(true);
    startRecording();
  };

  const handleStopRecording = () => {
    setIsRecording(false);
    stopRecording();
    setshowModalaudio(true)
  };

  const removeFile = (file) => () => {
    setFiles((prevFiles) => prevFiles.filter((f) => f !== file));
  };
  const token = localStorage.getItem('token');
  const decodedToken = jwtDecode(token); // فك تشفير الـ token
  const userId = decodedToken.sub; //


  const [fileimg, setFiles] = useState([]);
  const [base64Files, setBase64Files] = useState([]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*,video/*",
    onDrop: (acceptedFiles) => {
      setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
      setShowModal(true)
    },

  });


  const convertFilesToBase64 = async (files) => {
      const promises = files.map(file => {
          return new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => {
                  const fileType = file.type;
                  resolve({ base64: reader.result, type: fileType });
              };
              reader.onerror = error => reject(error);
          });
      });

      try {
          const base64Files = await Promise.all(promises);
          setBase64Files(base64Files);
          return base64Files; // Return the base64 files
      } catch (error) {
          console.error('Error converting files to Base64:', error);
          throw error; // Rethrow the error for handling in the calling function
      }
  };

  const [Messageapi, setMessageapi] = useState([]); 


  

  const [ChatMembers, setChatMembers] = useState([]);

  useEffect(() => {
    ChatMember();
  }, [])
  const ChatMember = async () => {
    
    await axios.get('https://nawyapp.com/api/ChatMember', {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }).then(({ data }) => {
      setChatMembers(data)
    }).then()
  }





  const [messages, setMessages] = useState([]);
  useEffect(() => {
    const pusher = new Pusher('0bd2ccc01118ca8a0230', {
      cluster: 'ap2',
      forceTLS: true
    });
    const channel_name1= `GRP-${chat_full_id1}` 

    const channel = pusher.subscribe(channel_name1);
    channel.bind(`${chat_full_id1}`, function (data) {
   
      setMessages((prevMessages) => [...prevMessages, data]);
    });

    // تنظيف الاشتراك عند إلغاء تثبيت المكون
    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
  }, [Messageapi]);

  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [message, setMessage] = useState('');

  const [inputValue, setInputValue] = useState('');
  const [recordState, setRecordState] = useState(null);
  const [audioFile, setAudioFile] = useState(null);
  const fileInputRef = React.useRef(null);
  const [showModal, setShowModal] = useState(false);
const [showModalaudio,setshowModalaudio] = useState(false)

  const handleDelete = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const openFileDialog = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const { t } = useTranslation();

  const handleEmojiClick = (emojiData) => {
    setMessage((prevMessage) => prevMessage + emojiData.emoji);
    setShowEmojiPicker(false);
  };
  const onStop = (audioData) => {
    const audioBlob = new Blob([audioData.blob], { type: 'audio/wav' });
    const audioFile = new File([audioBlob], 'recording.wav', {
      type: 'audio/wav',
    });
    setFiles((prevFiles) => [...prevFiles, audioFile]);
    setAudioFile(audioFile);
  };


  const sendText = () => {
    
    setInputValue('');
  }
 

  

  const [searchTerm, setSearchTerm] = useState('');
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  }

  const filteredConversations = ChatMembers.filter(conversation =>
    conversation.chat_full.title.toLowerCase().includes(searchTerm.toLowerCase())
  );
    const [chat_full_id1, setchat_full_id]=useState(0);
    console.log( `GRP-${chat_full_id1}`)
  const showmessage = async (id) => {
    await axios.get('http://143.198.166.228/api/message/' + id,).then(({ data }) => {
      setMessageapi(data.messages)
      setchat_full_id(id)
    }).then()
  }


  // const addmessage = async () => {
    
  //   //  if (mediaBlobUrl) {
  //     try {
  //       // تحويل mediaBlobUrl إلى Blob
  //       const response = await fetch(mediaBlobUrl);
  //       const audioBlob = await response.blob();
  //       // تأكد من أن Blob صالح
  //       if (audioBlob instanceof Blob) {
  //         // تحويل Blob إلى Base64
  //         const base64Audio = await convertBlobToBase64(audioBlob);
  //         const data = {
  //           message_content: message,
  //           chat_full_id: chat_full_id1,
  //           file: null,
  //           type:'audio'
  //         };
  //         await axios.post('http://143.198.166.228/api/Chat', data,
  //           {
  //             headers: {
  //               'Authorization': `Bearer ${token}`
  //             }
  //           }
  //         )
  //         // هنا يمكنك إرسال base64Audio إلى الخادم أو معالجته حسب الحاجة
  //       } else {
  //         const data = {
  //           message_content: message,
  //           chat_full_id: chat_full_id1,
  //           file: null,
  //           type:'audio'
  //         };
  //         await axios.post('http://143.198.166.228/api/Chat', data,
  //           {
  //             headers: {
  //               'Authorization': `Bearer ${token}`
  //             }
  //           }
  //         )
  //       }
  //     } catch (error) {
  //       console.error('Error fetching or converting audio:', error);
  //     }
  //   }
    // console.log(filteredConversations[0].) 
    

    const setaddimages = async () => {

      const base64File =  await convertFilesToBase64(fileimg);
      const data={
        message_content:'',
        chat_full_id:chat_full_id1, 
        file:base64File
      };
      await axios.post('http://143.198.166.228/api/Chat',data,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      )
    }

    const addmessage = async () => {
      const data={
        message_content:message,
        chat_full_id:chat_full_id1,
        file:null
      };
      await axios.post('http://143.198.166.228/api/Chat',data,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      )
    }
  return (
    <div className="min-h-screen bg-gray-200 flex flex-col items-center dir">
      <div className="w-full max-w-6xl bg-white rounded-lg shadow-lg overflow-hidden flex flex-col md:flex-row mt-10">
        {/* Sidebar */}
        <div className="w-full md:w-1/3 bg-white p-4">
          <div className="text-xl font-semibold mb-4"></div>
          <input
            type="search"
            className="border-2 border-gray-200 rounded-lg w-full h-8 text-right p-5 pl-3  mb-4 focus:outline-none focus:border-purple-600"
            placeholder="ابحث عن محادثة"
            value={searchTerm}
            onChange={handleSearch}
          />
          <a href="#1">
            <svg
              className="mt-[-48px] ml-1"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
                stroke="#828282"
                stroke-width="2"
              />
              <path
                d="M21.0004 20.9999L16.6504 16.6499"
                stroke="#828282"
                stroke-width="2"
              />
            </svg>
          </a>
          <br></br>
          {filteredConversations.map((c) => (
            <div key={c.id} className="flex border-2 items-center " onClick={() => showmessage(c.chat_full_id)}>
              <img src={Image2} className='rounded-full w-16 h-16' alt="" srcset="" />
              <h1 className='p-3'>{c.chat_full.title}</h1>
              <br /> <br />
            </div>
          ))
          }
        </div>
        {/* Chat area */}
        <div className="w-full md:w-2/3 bg-white flex flex-col">
          <div className="border-b p-4 flex items-center justify-between">
            <div className="flex items-center space-x-4">
              <img
                src={Image2}
                alt="Profile"
                className="w-10 h-10 rounded-full"
              />
              <div>
                <input
                  className="font-semibold bg-white"
                // value={username}
                // onChange={(e) => setUsername(e.target.value)}
                />
                <div className="text-sm text-gray-600"></div>
              </div>
            </div>
          </div>

          {/* Chat messages */}
          <div className="flex-1 p-6 space-y-4 overflow-auto bg-[#ُEBEAFA]">
            {
              Messageapi.map((c) => (
                c.sender_id == userId ?
                  <div className="flex justify-end">
                    <div className="bg-[#800080] text-white p-2 rounded-lg max-w-xs">
                      
                    { c.file === ''? c.message_content:
                  <img src={ `https://nawyapp.com/storage/${c.file}`} alt="" srcset="" />
                //    : c.type=== 'video'?
                //    <video className="w-full h-full sm:w-auto object-cover" controls>
                //    <source src={`https://nawyapp.com/storage/${c.message_content}`} type="video/mp4" />
                //  </video> 
                  }
                    </div>
                  </div>
                  :
                  <div className="flex">
                    <div className="bg-[#f0f0f0] p-2 rounded-lg max-w-xs">
                    { c.file == null? c.message_content:
                      <img src={ `https://nawyapp.com/storage/${c.file}`} alt="" srcset="" />
                    //    : c.type=== 'video'?
                    //    <video className="w-full h-full sm:w-auto object-cover" controls>
                    //    <source src={`https://nawyapp.com/storage/${c.message_content}`} type="video/mp4" />
                    //  </video> 
                      }
                    
                    </div>
                  </div>
              ))
            }
            {messages.map((message, index) => (
              <div className="flex justify-end">
                <div className="bg-[#800080] text-white p-2 rounded-lg max-w-xs">
                  
                  { message.type=== 'image'?
                  <img src={ `https://nawyapp.com/storage/${message.message_content}`} alt="" srcset="" />
                   : message.type=== 'video'?
                   <video className="w-full h-full sm:w-auto object-cover" controls>
                   <source src={`https://nawyapp.com/storage/${message.message_content}`} type="video/mp4" />
                 </video> 
                 : message.message_content
                  }
                </div>
              </div>
            ))}

          </div>



          <div className="flex items-center gap-2">

            <div className="chat-audio-recorder">
        
              {!isRecording ? (
                <button onClick={handleStartRecording} className="btn btn-record"> <IoMic size={24} /> </button>
              ) : (
                <button onClick={handleStopRecording} className="btn btn-stop"> <FaStop /></button>
              )}
              <button
                onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                className="p-2 bg-yellow-500 text-white rounded-full"
              >
                <IoHappy size={24} />
              </button>
              {showEmojiPicker && (
                <div className="absolute z-10 mb-[550px]">
                  <EmojiPicker onEmojiClick={handleEmojiClick} />
                </div>
              )}
           
            </div>
            <input
              type="text"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className="flex-1 p-2 border border-gray-300 rounded-lg"
              placeholder="اكتب رسالة..."
            />
            <button onClick={addmessage} className='bg-blue-500 text-white p-1 rounded flex items-center'>
              <FaPaperPlane className='mr-2' />
            </button>
            <div className="mt-4 grid grid-cols-3 gap-3">
              <div
                {...getRootProps({ className: "dropzone" })}
                className="flex items-center justify-center rounded-2xl bg-white  cursor-pointer"
              >
                <input {...getInputProps()} />
                <span className="text-3xl" >
                  <TiAttachmentOutline />

                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full md:w-1/3 bg-white p-4">
          <div className="space-y-4">
            <div className="flex items-center justify-center">
              <img
                src={Image2}
                alt="Profile"
                className="w-[55%] h-[55%] rounded-full"
              />
            </div>
            <div className="font-semibold text-xl text-center"></div>
       
          </div>
        </div>
      </div>

      {showModalaudio && (

<div className="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
    <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
      <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <h3 className="text-2xl font-semibold mb-4 text-center" id="modal-title">
          {t('profile_send')}
        </h3>

        <div className="flex justify-center">
          <div className="mt-4 grid grid-cols-3 gap-3 p-4">

          {mediaBlobUrl && (
                <div>
                  <audio src={mediaBlobUrl} controls className="audio-player" />
                </div>
              )}

          </div>
        </div>
      </div>
      <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <button
          type="submit"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-[#FFA500] text-base font-medium text-white hover:bg-[#800080] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#FFA500] sm:ml-3 sm:w-auto sm:text-sm transition-colors duration-200"
        onClick={setaddimages}
        >
          {t('profile_send')}
        </button>
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm transition-colors duration-200"
          onClick={() => setshowModalaudio(false)}
        >
          {t('profile_close')}
        </button>
      </div>

    </div>


  </div>


</div>
)}



      {showModal && (

        <div className="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <h3 className="text-2xl font-semibold mb-4 text-center" id="modal-title">
                  {t('profile_send')}
                </h3>

                <div className="flex justify-center">
                  <div className="mt-4 grid grid-cols-3 gap-3 p-4">
                    {fileimg.map((file, index) => (
                      <div key={index} className="relative">

                        <div className="absolute top-0 right-20">
                          <button
                            type="button"
                            onClick={removeFile(file)}
                            className="bg-green-600 text-white rounded-full p-1 w-6 h-8  hover:bg-red-700"
                          >
                            x
                          </button>
                        </div>
                        {file.type.startsWith("image") ? (

                          <img
                            src={URL.createObjectURL(file)}
                            alt={file.name}
                            className="w-36 h-36 object-cover rounded"
                          />
                        ) : (
                          <video
                            controls
                            src={URL.createObjectURL(file)}
                            className="w-36 h-36 object-cover rounded"
                          />
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="submit"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-[#FFA500] text-base font-medium text-white hover:bg-[#800080] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#FFA500] sm:ml-3 sm:w-auto sm:text-sm transition-colors duration-200"
                onClick={setaddimages}
                >
                  {t('profile_send')}
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm transition-colors duration-200"
                  onClick={() => setShowModal(false)}
                >
                  {t('profile_close')}
                </button>
              </div>

            </div>


          </div>


        </div>
      )}
    </div>
  );
};

export default Support;

// import React, { useEffect, useState, useMemo } from 'react';
// import { FaPaperPlane, FaStop } from 'react-icons/fa';
// import { IoMic, IoHappy } from 'react-icons/io5';
// import { TiAttachmentOutline } from "react-icons/ti";
// import EmojiPicker from 'emoji-picker-react';
// import { useDropzone } from 'react-dropzone';
// import { useReactMediaRecorder } from "react-media-recorder";
// import axios from 'axios';
// import { jwtDecode } from 'jwt-decode'; 
// import Pusher from 'pusher-js';

// const Support = () => {
//   const [isRecording, setIsRecording] = useState(false);
//   const [showEmojiPicker, setShowEmojiPicker] = useState(false);
//   const [message, setMessages] = useState('');
//   const [files, setFiles] = useState([]);
//   const [mediaBlobUrl, setMediaBlobUrl] = useState(null);
//   const [chat_full_id1, setChatFullId] = useState(1);
//   const { startRecording, stopRecording } = useReactMediaRecorder({ audio: true });

//   const token = localStorage.getItem('token');
//   const decodedToken = jwtDecode(token);
//   const userId = decodedToken.sub;

//   const { getRootProps, getInputProps } = useDropzone({
//     accept: "image/*,video/*",
//     onDrop: (acceptedFiles) => {
//       setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
//     }
//   });

//   useEffect(() => {
//     const pusher = new Pusher('0bd2ccc01118ca8a0230', { cluster: 'ap2', forceTLS: true });
//     const channel = pusher.subscribe(`GRP-${chat_full_id1}`);
//     channel.bind(chat_full_id1, function (data) {
//       setMessages((prevMessages) => [...prevMessages, data]);
//     });
//     return () => {
//       channel.unbind_all();
//       channel.unsubscribe();
//     };
//   }, []);

//   const handleStartRecording = () => {
//     setIsRecording(true);
//     startRecording();
//   };

//   const handleStopRecording = () => {
//     setIsRecording(false);
//     stopRecording();
//   };

//   const handleEmojiClick = (emojiData) => {
//     setMessages((prevMessage) => prevMessage + emojiData.emoji);
//   };

//   const convertBlobToBase64 = (blob) => {
//     return new Promise((resolve, reject) => {
//       const reader = new FileReader();
//       reader.readAsDataURL(blob);
//       reader.onload = () => resolve(reader.result);
//       reader.onerror = error => reject(error);
//     });
//   };

//   const sendMessage = async () => {
//     let audioBase64 = null;
//     if (mediaBlobUrl) {
//       const response = await fetch(mediaBlobUrl);
//       const audioBlob = await response.blob();
//       audioBase64 = await convertBlobToBase64(audioBlob);
//     }

//     const formData = new FormData();
//     formData.append('message_content', message);
//     formData.append('chat_full_id', chat_full_id1);
//     formData.append('file', audioBase64 || files[0] || '');

//     await axios.post('http://143.198.166.228/api/Chat', formData, {
//       headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'multipart/form-data' }
//     });

//     setMessages('');
//     setFiles([]);
//     setMediaBlobUrl(null);
//   };

//   return (
//     <div className="chat-container">
//       <div className="chat-area">
//         <input type="text" value={message} onChange={(e) => setMessages(e.target.value)} placeholder="Type a message..." />
//         <button onClick={() => setShowEmojiPicker(!showEmojiPicker)}>
//           <IoHappy size={24} />
//         </button>
//         {showEmojiPicker && <EmojiPicker onEmojiClick={handleEmojiClick} />}
        
//         <div {...getRootProps()}>
//           <input {...getInputProps()} />
//           <TiAttachmentOutline size={24} />
//         </div>

//         {!isRecording ? (
//           <button onClick={handleStartRecording}>
//             <IoMic size={24} />
//           </button>
//         ) : (
//           <button onClick={handleStopRecording}>
//             <FaStop size={24} />
//           </button>
//         )}
        
//         {mediaBlobUrl && <audio src={mediaBlobUrl} controls />}

//         <button onClick={sendMessage}>
//           <FaPaperPlane size={24} />
//         </button>
//       </div>
//     </div>
//   );
// };

// export default Support;