import axios from "axios";
import React, {useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

const EditProfileComponent = ({ setNotifi }) => {
  const { t } = useTranslation(); // تهيئة الترجمة هنا
  const [showModal, setShowModal] = useState(false); // حالة البوباب

  const ratingChanged = (newRating) => {
    console.log(newRating); // يمكنك إضافة الإجراءات المطلوبة هنا
  };

  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*,video/*",
    onDrop: (acceptedFiles) => {
      setFiles([...acceptedFiles]);
    },
  });
  const [description,setdescription] = useState('');

  const [base64Files, setBase64Files] = useState([]);
  const convertFilesToBase64 = async (files) => {
      const promises = files.map(file => {
          return new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => {
                  const fileType = file.type;
                  resolve({ base64: reader.result, type: fileType });
              };
              reader.onerror = error => reject(error);
          });
      });

      try {
          const base64Files = await Promise.all(promises);
          setBase64Files(base64Files);
          return base64Files; // Return the base64 files
      } catch (error) {
          console.error('Error converting files to Base64:', error);
          throw error; // Rethrow the error for handling in the calling function
      }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const base64File =  await convertFilesToBase64(files);
      const data = {
        description:description,
        content_type: base64File[0],
      };

      const token = localStorage.getItem('token');
      await axios.post('https://nawyapp.com/api/post_influncer', data, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      });
      setShowModal(false)
      alert(t('request_sent_successfully'));
      
    } catch (error) {
  
      console.error('Error submitting form:', error);
    }
  };

  const removeFile = (fileToRemove) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToRemove));
  };


  const tabs = [{ id: 3, label: "editinf_sale" }];

  return (
    <div className="w-full bg-gray-100">
      <TabsSection tabs={tabs} setShowModal={setShowModal} t={t} />
      {showModal && (
        <Modal
        description={description}
        handleSubmit={handleSubmit}
        setdescription = {setdescription}
          setShowModal={setShowModal}
          // rating_api={rating_api}
          ratingChanged={ratingChanged}
          t={t}
          getRootProps={getRootProps}
          getInputProps={getInputProps}
          files={files}
          removeFile={removeFile}
        />
      )}
    </div>
  );
};

const TabsSection = ({ tabs, setShowModal, t }) => (
  <div className="flex text-left px-4 mt-[30px] mb-8">
    <div className="bg-white rounded-full p-1 sm:p-2 flex flex-wrap justify-center shadow-md">
      {tabs.map((tab) => (
        <button
          key={tab.id}
          className="py-2 px-4 rounded-full text-2xl focus:outline-none focus:ring-2 focus:ring-purple-500 transition-all duration-200 hover:bg-purple-50"
          onClick={() => setShowModal(true)} // فتح البوباب عند النقر على الزر
        >
          {t(tab.label)}
        </button>
      ))}
    </div>
  </div>
);

// مكون البوباب (Modal)
const Modal = ({
  setShowModal,
  description,
  setdescription,
  handleSubmit,
  t,
  getRootProps,
  getInputProps,
  files,
  removeFile,
}) => (
  <div
    className="fixed inset-0 z-50 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div className="flex items-end justify-center  pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>
      <span
        className="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
      >
        &#8203;
      </span>

      <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
        <form method="post">

          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <h3
              className="text-2xl font-semibold mb-4 text-center"
              id="modal-title">
              {t("editinf_sale")}
            </h3>

            {/* تعديل القسم الخاص بالصورة */}
            <div className="flex flex-col items-center">
              <div className="flex items-center space-x-4">
                <label className="text-lg font-bold">{t("image")}</label>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  {files.length === 0 ? (
                    <div className="w-64 h-64 flex items-center justify-center border-2 border-dashed rounded-lg cursor-pointer bg-gray-50">
                      <p className="text-gray-500 text-center">
                        {t("Click to upload or drag and drop")}<br />
                        {t("SVG, PNG, JPG or GIF (MAX. 800x400px)")}
                      </p>
                    </div>
                  ) : (
                    files.map((file, index) => (
                      <div key={index} className="relative">
                        <img
                          src={URL.createObjectURL(file)}
                          className="w-64 h-64 object-cover rounded-lg"
                          alt="Selected file"/>
                        <button
                          type="button"
                          onClick={() => removeFile(file)}
                          className="absolute top-0 right-0 bg-red-600 text-white rounded-full w-6 h-6 flex items-center justify-center">
                          x
                        </button>
                      </div>
                    ))
                  )}
                </div>
              </div>

              {/* حقل النص لوضع الوصف */}
              <div className="mt-6 w-full">
                <label className="text-lg font-bold">{t("order_travel_add")}</label>
                <textarea
                value={description}
                onChange={(e) => setdescription(e.target.value)}
                  className="w-full mt-2 p-3 h-32 border border-gray-300 rounded-lg focus:ring-2 focus:ring-purple-500 focus:outline-none resize-none"
                  placeholder={t("profile_enter")}
                ></textarea>
              </div>
            </div>
          </div>

          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              type="submit"
              onClick={handleSubmit}
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-[#FFA500] text-base font-medium text-white hover:bg-[#800080] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#FFA500] sm:ml-3 sm:w-auto sm:text-sm transition-colors duration-200"
            >
              {t("editinf_sale2") || "إضافة"}
            </button>
            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm transition-colors duration-200"
              onClick={() => setShowModal(false)} // إغلاق البوباب عند النقر على زر الإغلاق
            >
              {t("profile_close") || "إغلاق"}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
);

export default EditProfileComponent;