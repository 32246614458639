import React, { useEffect, useState } from 'react'

import Img from '../assets/Images/Flat1.png'
import { FaBath, FaBed, } from 'react-icons/fa';
import { BsTextareaResize } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import axios from 'axios';

function SortComponents() {

    const delete_api = async (id) => {

        await axios.delete('https://nawyapp.com/api/Favorite/' + id
        ).then(() => {
            fetchCategories();
        });
    }


    const { t } = useTranslation();
    const token = localStorage.getItem('token');
    const [categoriesall, setCategories] = useState([]);
    useEffect(() => {
        fetchCategories();
    }, [])
    const fetchCategories = async () => {
        await axios.get('https://nawyapp.com/api/Favorite/create',
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
        ).then(({ data }) => {
            setCategories(data)
        });
    }

    return (
        <div className="bg-[#ebeafa]">
            <br /> <br />
            <section className="grid sm:grid-cols-1 lg:w-[70%] px-9 lg:grid-cols-2 w-full lg:ml-64  py-3">
                {
                    categoriesall.length === 0 ? <div className="text-center ">
                        <h1 className='text-[30px] font-bold' >
                        {t('fav')}
                        </h1>
                    </div> :
                        categoriesall.map((order, index) => (
                            <div key={index} className="bg-white lg:w-full  shadow-lg m-3 border-4   flex justify-between p-3">
                                <div className="flex">
                                    <img src={Img} width="130px" height="50px" alt="" />
                                    <p className='bg-green-200 text-lg text-green-500 h-10 p-1 ml-2'>{order.favoritable.registration_status}</p>
                                    <div className="text-blue-800 mt-24 -mx-3  text-xl">{order.favoritable.price} $</div>
                                </div>
                                <div className="p-0.5 ">
                                    <div className=" text-right">
                                        <button onClick={() => delete_api(order.id)} className='rounded-full p-1 bg-red-800'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-x text-white" viewBox="0 0 16 16">
                                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                                            </svg>
                                        </button>
                                    </div>
                                    <h1 className='text-2xl ml-14'>{order.favoritable.vechicle_model.name}</h1>
                                    <h3 className='mt-3 ml-14'>{order.favoritable.description} </h3>
                                    {/* <div className='p-3 flex ml-2'>
                                        <p className='me-3 flex' >
                                            <BsTextareaResize className='mr-2 mt-0.5  text-[#800080]' />
                                            {order.favoritable.description}</p>
                                        <p className='me-3 flex' >
                                            <FaBath className='mr-2   text-[#800080]' />
                                            {order.favoritable.number_of_bathroom} حمام</p>
                                        <p className='me-3 flex' >
                                            <FaBed className='mr-2 mt-1   text-[#800080]' />
                                            {order.favoritable.number_of_rooms} غرف</p>
                                    </div> */}
                                </div>
                            </div>
                        ))}

            </section>

        </div>
    )
}

export default SortComponents
