import React, { useEffect, useState } from 'react'
import Cards from "./asset_comontants/Cards";
import Storyadmin from "./asset_comontants/story_admin";
import ShowInfluencer from "./asset_comontants/showInfluencer";
import Search from "./search";
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import LocationFetcher from "../../components/LocationFetcher";

const Page = () => {



  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [address, setAddress] = useState("");

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ latitude, longitude });

          fetchAddress(latitude, longitude);
        },
        (error) => {
          console.error('Error getting location:', error);
        }
      );
    } else {
      alert('Geolocation is not supported by this browser.');
    }
  }, []);

  const fetchAddress = async (latitude, longitude) => {
    const geocoder = new window.google.maps.Geocoder();
    const latlng = { lat: latitude, lng: longitude };
    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          setAddress(results[0].formatted_address);
          // console.log(results[0])
          fetchshowproperty_near(latitude, longitude, address);
        } else {
          console.log('No results found');
        }
      } else {
        console.log('Geocoder failed due to: ' + status);
      }
    });
  };



  const { t } = useTranslation();
  const [property, setproperty] = useState([]);
  const [Influencer, setInfluencer] = useState([]);
  const [lat, setlat] = useState(0);
  const [lng, setlng] = useState(0);
  const token = localStorage.getItem('token');

  const fetchpropertyallow_buy_or_sell = async (type) => {
    await axios.get('https://nawyapp.com/api/showallow_buy_or_sell/' + type
    ).then(({ data }) => {
      setproperty(data)
    });
  }
  const [property_near, setproperty_near] = useState([]);


  useEffect(() => {
    fetchshowproperty();
    apishowInfluencer();

  }, [])



  // const [location, setLocation] = useState({ latitude: null, longitude: null, address:'' });
  console.log(location)
  const fetchshowproperty = async () => {

    await axios.get('https://nawyapp.com/api/showproperty',
    ).then(({ data }) => {
      setproperty(data)
    });
  }

  const fetchshowproperty_near = async (lat, lng, address) => {
    setAddress(address)
    await axios.get('https://nawyapp.com/api/property_near', {
      params: {
        lat: parseFloat(lat),
        lng: parseFloat(lng)
      }
    }
    ).then(({ data }) => {
      setproperty_near(data)

    });
  }

  const apishowInfluencer = async () => {

    await axios.get('https://nawyapp.com/api/showInfluencer',
    ).then(({ data }) => {
      setInfluencer(data)
    });
  }
  console.log(address)


  const [loading, setLoading] = useState(true);

  return (
    <div className=' bg-[#e0cce7]'>
      <Storyadmin />
      {
        property.length ? (
          <div className="">
            <div className="" style={{ display: 'none' }}>

              <LocationFetcher onLocationFetched={(latitude, longitude, address) => {
                setAddress(address)

              }
              } />
            </div>
            <Cards title={t('page_best')} show={1} address={address} property={property} isSpecial={true} fetchpropertyallow_buy_or_sell={fetchpropertyallow_buy_or_sell} />
            <ShowInfluencer title={t('page_best_in')} Influencer={Influencer} fetchpropertyallow_buy_or_sell={fetchpropertyallow_buy_or_sell} />
            <Cards title={t('page_best_p')} show={0} property={property_near} address={address} isSpecial={false} fetchpropertyallow_buy_or_sell={fetchpropertyallow_buy_or_sell} />
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
            {/* Animation Spinner */}
            <div className="flex justify-center items-center mb-5">
              <div className="w-12 h-12 border-4 border-purple-500 border-solid rounded-full border-t-transparent animate-spin"></div>
            </div>
            {/* Loading Text */}
            <div className="text-lg font-semibold text-purple-600">
              {t('lodinP')}
            </div>
            <div className="" style={{ display: 'none' }} >

              <LocationFetcher onLocationFetched={(latitude, longitude, address) => {
                latitude === null &&
                  fetchshowproperty_near(latitude, longitude, address)
              }
              } />
            </div>

          </div>
        )
      }
    </div>
  )
}

export default Page
