import React from 'react';
import { useTranslation } from 'react-i18next';

const DescriptionCarousel = ({ property }) => {

  const { i18n, t } = useTranslation();
  const currentLanguage = i18n.language;
  return (
    <div>
      { property.map((p,index) =>(
             <div key={index} className='bg-white'>
             <p className='text-right pr-8 font-bold text-[25px]'>{currentLanguage === 'en' ? p.property_type.name_en : p.property_type.name_ar }  - {p.allow_buy_or_sell === 'بيع'? t('add_property_sale') : p.allow_buy_or_sell === 'شراء' ? t('navbar_buying'): t('navbar_rent')} 
              <br/><span className='mr-4 p-2 text-[16px] text-[#800080] font-small'>{p.created_at}</span></p>
           </div>
      ))
      }
    </div>
  )
}

export default DescriptionCarousel
