import React from "react";
import Flat2 from "../../assets/ImagesOrdertravels/1.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AllOrderBroker = ({order_property, order_vehicles}) => {
  console.log(order_property)

  const {t} = useTranslation();

  return (
    <div className=" text-right pt-12 bg-[#EBEAFA]">
      <main>
        <section className="flex flex-wrap justify-center max-w-7xl mx-auto py-3">
          {/* <h1>{t('sort_propety')}</h1> */}
          {order_property.map((officeTravel) => (
            <div
              key={officeTravel.id}
              className="w-full sm:w-1/2 lg:w-1/2 p-2 my-[-60px] mt-[-30px] pb-[-40px]"
            >
              <div className="w-full h-[60%] rounded-lg shadow-md overflow-hidden mb-2 mt-4 sm:p-2 bg-white float-right">
                <div className="flex">
                  <img
                    className="w-20 h-20 mt-[20px] object-cover"
                    src={`https://nawyapp.com/storage/${officeTravel.image.url_file}`}
                    alt="/"
                  />
                  <div className="p-4 flex flex-col justify-between float-right mt-[-5px]">
                    <div>
                      <div className="mb-12">
                        <h3 className="text-[23px] mr-[-15px] font-semibold">
                          {officeTravel.property_type.name}
                        </h3>
                        <p className="text-blue-700 text-md p-2 ml-[290px] text-lg font-semibold cursor-pointer hover:text-[#FFA500]">
                          {t('navbar_influanser')}
                        </p>
                        <p className="text-[#990033] hover:text-[#FFA500] cursor-pointer text-2xl flex float-right ml-3 pt-2 pb-4">
                          {officeTravel.price} $
                        </p>
                        <div className="flex mt-[-10px] pb-12 mr-[-10px]">
                          <Link to="/order_travel/travel_offieces_services/">
                            <span className=" text-gray-500 rounded-full text-lg font-semibold mr-2 hover:text-[#800080]">
                           {t('agency_vehicle_details')}
                            </span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}

        </section>
        <section className="flex flex-wrap justify-center max-w-7xl mx-auto py-3">
          {/* <h1>{t('sort_car')}</h1> */}
          { order_vehicles.length === 0? <h1> not found</h1> :
          order_vehicles.map((vehicle) => (
            <div
              key={vehicle.id}
              className="w-full sm:w-1/2 lg:w-1/2 p-2 my-[-60px] mt-[-30px] pb-[-40px]">
              <div className="w-full h-[60%] rounded-lg shadow-md overflow-hidden mb-2 mt-4 sm:p-2 bg-white float-right">
                <div className="flex">
                  <img
                    className="w-20 h-20 mt-[20px] object-cover"
                    src={`https://nawyapp.com/storage/${vehicle.image.url_file}`}
                    alt="/"
                  />
                  <div className="p-4 flex flex-col justify-between float-right mt-[-5px]">
                    <div>
                      <div className="mb-12">
                        <h3 className="text-[23px] mr-[-15px] font-semibold">
                          {vehicle.vechicle_make.name} - {vehicle.vechicle_model.name}
                        </h3>
                        <p className="text-blue-700 text-md p-2 ml-[290px] text-lg font-semibold cursor-pointer hover:text-[#FFA500]">
                          {t('navbar_influanser')}
                        </p>
                        <p className="text-[#990033] hover:text-[#FFA500] cursor-pointer text-2xl flex float-right ml-3 pt-2 pb-4">
                          {vehicle.price} $
                        </p>
                        <div className="flex mt-[-10px] pb-12 mr-[-10px]">
                          <Link to="/order_travel/travel_offieces_services/">
                            <span className=" text-gray-500 rounded-full text-lg font-semibold mr-2 hover:text-[#800080]">
                           {t('agency_vehicle_details')}
                            </span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> 
          ))}
        </section>
      </main>
    </div>
  );
};
export default AllOrderBroker;