import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { GoogleMap, LoadScript, Marker   } from '@react-google-maps/api';

const DescriptionTravelOrder = ({order_travel}) => {
   const latfor= parseFloat(order_travel.locationfor.latitud)
   const lngfor= parseFloat(order_travel.locationfor.longitude)
   const latto= parseFloat(order_travel.locationto.latitud)
   const lngto= parseFloat(order_travel.locationto.longitude)
  const {t} = useTranslation();
  const mapStyles = { height: "400px", width: "100%" };
  const navigate = useNavigate()
  const detailsproperty = (id) => { 
    navigate(`/details_cards_property/${id}`); 
  }
  
  const defaultCenterfor ={ lat: latfor, lng: lngfor };
  const defaultCenterto ={ lat: latto, lng: lngto };

  return (
    <div className=" text-right pt-12">
      <main>
        <section className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          <div className="bg-white shadow sm:rounded-lg">
            <div className="px-4 py-5 sm:p-6">
              <svg
                width="25"
                height="36"
                viewBox="0 0 30 36"
                fill="none"
                className="float-right"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M15 0C11.0232 0.00442346 7.21062 1.49589 4.39859 4.14723C1.58656 6.79857 0.0047075 10.3933 1.59585e-05 14.1429C-0.00474707 17.207 1.05681 20.188 3.02183 22.6286C3.02183 22.6286 3.43092 23.1364 3.49774 23.2097L15 36L26.5077 23.2033C26.5677 23.1351 26.9782 22.6286 26.9782 22.6286L26.9795 22.6247C28.9436 20.1852 30.0046 17.2056 30 14.1429C29.9953 10.3933 28.4134 6.79857 25.6014 4.14723C22.7894 1.49589 18.9768 0.00442346 15 0ZM15 19.2857C13.9212 19.2857 12.8666 18.9841 11.9696 18.419C11.0726 17.8539 10.3735 17.0507 9.96066 16.1109C9.54782 15.1712 9.4398 14.1372 9.65027 13.1395C9.86073 12.1419 10.3802 11.2255 11.1431 10.5063C11.9059 9.78707 12.8778 9.29726 13.9359 9.09882C14.9939 8.90038 16.0907 9.00223 17.0874 9.39148C18.084 9.78073 18.9359 10.4399 19.5353 11.2856C20.1346 12.1314 20.4545 13.1257 20.4545 14.1429C20.4527 15.5063 19.8775 16.8134 18.8549 17.7775C17.8324 18.7416 16.4461 19.284 15 19.2857Z"
                  fill="#415770"
                />
              </svg>
              <p className="mb-4 text-gray-600 leading-relaxed text-2xl font-bold space-y-4">
                {order_travel.description}
              </p>
            </div>
          </div>
        </section>
        <section className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          <div className="shadow sm:rounded-lg p-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
            {t('about_b_address')}
            </h3>
            <LoadScript googleMapsApiKey="AIzaSyCN1ldCkq6kz0PbBE6Y2eUTLYIGyNfcQxY">
            <GoogleMap
              mapContainerStyle={mapStyles}
              zoom={30}
              center={defaultCenterfor}
            >
              <Marker position={defaultCenterfor} />
              <Marker position={defaultCenterto} />
            </GoogleMap>
          </LoadScript>
          </div>
        </section>

          <div className="flex grid-cols-1 sm:grid-cols-2 justify-center items-center">
          <span className=" font-bold">
             {t('des_proprty_price')}
            <span className="text-2xl">
              <span className="text-blue-600 tex"> {order_travel.price} $</span>
            </span> 
          </span> <br/>
          </div>
      </main>
    </div>
  );
};
export default DescriptionTravelOrder;