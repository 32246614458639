import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../header";
import { FaCalendarAlt, FaPhone } from "react-icons/fa";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import axios from "axios";

const Add = ({formordertrovel,updateFormOrderTrovel, setformordertrovel}) => {
console.log(formordertrovel)
  const {t} = useTranslation();
  const [base64Files, setBase64Files] = useState([]);
  const navigate= useNavigate()
  const token = localStorage.getItem('token');
  const [files, setFiles] = useState([]);
  useEffect(() => {
    setformordertrovel({
      ...formordertrovel,
      image:base64Files?base64Files[0] :0 ,
    });
  }, [base64Files]); // تحديث النموذج عند تغيير markers


  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*,video/*",
    onDrop: (acceptedFiles) => {
      setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
      const base64Files12 =  convertFilesToBase64(acceptedFiles);
    },
  });

  const handleSubmit = async () => {
    try {
      const { data } = await axios.post('https://nawyapp.com/api/order_travel', formordertrovel, {
        headers: { 'Authorization': `Bearer ${token}` },
      });
      navigate('/order_travel/AllOrders');
    } catch (error) {
      console.error('Error submitting vehicle:', error);
    }
  };


  const removeFile = () => {
    setFiles([]);
  };


  console.log(base64Files)
  const convertFilesToBase64 = async (files) => {
      const promises = files.map(file => {
          return new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => {
                  const fileType = file.type;
                  resolve({ base64: reader.result, type: fileType });
              };
              reader.onerror = error => reject(error);
          });
      });

      try {
          const base64Files = await Promise.all(promises);
          setBase64Files(base64Files);
          return base64Files; // Return the base64 files
      } catch (error) {
          console.error('Error converting files to Base64:', error);
          throw error; // Rethrow the error for handling in the calling function
      }
  };
  console.log(base64Files[0])
  return (
    <div>
      <Header title={t('travel_ser_top_header')} className=" flex flex-col items-center" />
      <div className="flex flex-col items-center justify-center  text-right bg-gray-100">
            {/* قسم الصور */}
            <div className="mb-8  pt-[60px]">
            <label className="text-xl block mb-2 font-bold text-black text-right">
              {t('order_travel_add_photos')}
            </label>
            <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 bg-white border-dashed rounded-md">
              {files.length === 0 ? (
                <div {...getRootProps()} className="space-y-1 text-center">
                  <svg
                    className="mx-auto h-12 w-12 text-gray-400"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 48 48"
                    aria-hidden="true"
                  >
                    <path
                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <div className="flex text-sm text-gray-600">
                    <label
                      htmlFor="file-upload"
                      className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                    >
                      <span>{t('upload_a_file')}</span>
                      <input {...getInputProps()} id="file-upload" name="file-upload" type="file" className="sr-only" />
                    </label>
                    <p className="pl-1">{t('or_drag_and_drop')}</p>
                  </div>
                  <p className="text-xs text-gray-500">{t('png_jpg_gif_up_to_10mb')}</p>
                </div>
              ) : (
                <div className="flex items-center">
                  <img src={URL.createObjectURL(files[0])} alt="Uploaded file" className="h-32 object-cover" />
                  <button
                    type="button"
                    onClick={removeFile}
                    className="ml-4 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    {t('remove')}
                  </button>
                </div>
              )}
            </div>
          </div>
        <div className="p-8 w-full max-w-md">
        <div className="mb-4">
          <text type="hidden"  name="image" value={base64Files}
                    onChange={updateFormOrderTrovel}   />
            <label className="block text-gray-700 p-2">{t('order_travel_add')}</label>
            <div className="flex items-center mb-[100px] ">
              <textarea
                    name="description"
                    onChange={updateFormOrderTrovel}  
                    className=" text-black rounded-2xl focus:text-white-2 py-5 px-3 text-right focus:outline-none
                 focus:border-purple-800 transition-colors appearance-none h-48 w-full"
  
                placeholder={t('order_travel_add2')}>
               
                </textarea>
            </div>
          </div>


          <div className="mb-4">
            <label className="block text-black font-semibold p-2">{t('order_travel_num')}</label>
            <div className="flex items-center py-2 mb-[40px] border-2 rounded-full">
              <input
                className="appearance-none  text-right bg-transparent w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                type="text"
                name="phone"
                onChange={updateFormOrderTrovel}
                placeholder={t('order_travel_num')}
              />
              <FaPhone className="text-gray-400 mr-3" />
            </div>
          </div>

          <div className="mb-6">
            <label className="block text-gray-700 p-2">{t('order_travel_price')}</label>
            <div className="flex items-center py-2 border-2 rounded-full">
              <input
                className="appearance-none bg-transparent text-right border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                type="number"
                name="price"
                onChange={updateFormOrderTrovel}
                placeholder={t('order_travel_price2')}
              />
              <FaCalendarAlt className="text-gray-400 mr-3" />
            </div>
          </div>

          <div className="flex items-center justify-between">
            <Link to= "/order_travel/add1" >
            <button
              className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-8 rounded-full focus:outline-none focus:shadow-outline"
              type="button"
            >
              {t('travel_ser_des_order')}
            </button>
            </Link>
            <button
            onClick={handleSubmit}
              className="bg-[#FFA500]  hover:bg-[#FFA500] text-white font-bold py-2 px-8 rounded-full focus:outline-none focus:shadow-outline"
              type="button"
            >
              {t('add1_vehicle_next')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Add;
