import React, { useState, useEffect } from "react";
import { BiChevronDown } from "react-icons/bi";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { setStoredLanguage, getStoredLanguage } from "../store/languageStore";
import axios from "axios";

const Settings = () => {

  const {t, i18n} = useTranslation();
  const [language, setLanguage] = useState(i18n.language);


  const toggleLanguage = () => {
    const newLanguage = language === 'en'? 'ar' : 'en';
    setLanguage(newLanguage);
    i18n.changeLanguage(newLanguage);
  }

  const [selected, setSelected] = useState("");
  const [open, setOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  useEffect(() => {
    const currentLanguage = getStoredLanguage();
    setSelected(currentLanguage === "en" ? "English" : "العربية");
  }, []);

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    setStoredLanguage(language);
    setSelected(language === "en" ? "English" : "العربية");
    setOpen(false);
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");

    const token = localStorage.getItem('token');
    
    try {
      await axios.post('https://nawyapp.com/api/editpassword', 
        { password },
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
      setSuccess(t('password_change_success'));
      setPassword("");
    } catch (error) {
      setError(t('password_change_error'));
      console.error('Error changing password:', error);
    }
  };

  return (
    <div className=" bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto space-y-8">
        <h2 className="text-3xl font-extrabold text-center text-gray-900">{t('settings_title')}</h2>
        
        <LanguageSelector
          selected={selected}
          open={open}
          setOpen={setOpen}
          changeLanguage={changeLanguage}
          t={t}
        />

        <PasswordChange
          password={password}
          setPassword={setPassword}
          handleSubmit={handlePasswordChange}
          error={error}
          success={success}
          t={t}
        />

        <div className="flex flex-col sm:flex-row justify-between items-center space-y-4 sm:space-y-0 sm:space-x-4">
          <Link to="/Profile" className="w-full sm:w-auto">
            <button className="w-full sm:w-auto py-3 hover:bg-[#800080] hover:text-white px-6 bg-white text-purple-600 border border-purple-600 rounded-md transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
              {t('content_back')}
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

const LanguageSelector = ({ selected, open, setOpen, changeLanguage, t }) => (
  <div className="bg-white rounded-lg shadow-md p-6">
    <h3 className="text-xl font-semibold mb-4">{t('setting_lan')}</h3>
    <div className="relative">
      <div
        onClick={() => setOpen(!open)}
        className="bg-white w-full p-3 flex items-center justify-between border rounded-md cursor-pointer hover:border-purple-500 transition-colors duration-200"
      >
        <span>{selected || t('setting_ar')}</span>
        <BiChevronDown size={20} className={`transition-transform duration-300 ${open ? "rotate-180" : ""}`} />
      </div>
      {open && (
        <ul className="absolute z-10 w-full mt-1 bg-white border rounded-md shadow-lg">
          {["en", "ar"].map((lang) => (
            <li
              key={lang}
              className="p-3 hover:bg-purple-50 cursor-pointer transition-colors duration-200"
              onClick={() =>{ changeLanguage(lang);}}
            >
              {t(`setting_${lang}`)}
            </li>
          ))}
        </ul>
      )}
    </div>
  </div>
);

const PasswordChange = ({ password, setPassword, handleSubmit, error, success, t }) => (
  <form onSubmit={handleSubmit} className="bg-white rounded-lg shadow-md p-6">
    <h3 className="text-xl font-semibold mb-4">{t('setting_pa')}</h3>
    {error && <div className="mb-4 text-red-500">{error}</div>}
    {success && <div className="mb-4 text-green-500">{success}</div>}
    <div className="mb-4">
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        className="w-full p-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-purple-500"
        placeholder="••••••••"
        required
      />
    </div>
    <button 
      type="submit" 
      className="w-full py-3 px-6 bg-purple-600 text-white font-bold rounded-md hover:bg-purple-700 transition-colors duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
    >
      {t('content_sure')}
    </button>
  </form>
);

export default Settings;