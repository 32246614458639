import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import Login from "./pages/login";
import Register from "./pages/register";
import CategoriesType from "./pages/categoriesType";
import Map1 from "./pages/Map1";
import Home from "./pages/home";
import OrderInfluancer from "./pages/OrderInfluancer";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import ScrollToTop from "./helpers/ScrollToTop";
import Page from "./components/Homepage/page";
import PageVehicle from "./components/Homepage/pageVehicle";
import PageOrderTravel from "./components/Homepage/pageOrderTravel";
import DetailsCardsProperty from "./components/DetailsCard/DetailsCardsProperty";
import DetailsCardsVehicle from "./components/DetailsCard/DetailsCardsVehicle";
import DetailsTravelOrder from "./components/Order_travel/DetailsTravelOrder";
import AddProperty from "./components/Property/Add";
import AddOrderTravel from "./components/OrderTravel/Add";
import AllOrders from "./components/OrderTravel/AllOrders";
import AddTravel from "./components/OrderTravel/Add2";
import Start from "./components/OrderTravel/Start";
import Share from "./components/Property/PropertyServices";
import InfluancerServices from "./components/Property/InfluancerServices";
import Add1 from "./components/AddVehicle/Add1";
import Add2 from "./components/AddVehicle/Add2";
import Add3 from "./components/AddVehicle/Add3";
import Add4 from "./components/AddVehicle/Add4";
import Add5 from "./components/AddVehicle/Add5";
import Add6 from "./components/AddVehicle/Add6";
import Agency from "./components/Homepage/Agency";
import TravelOfficesServices from "./components/OrderTravel/TravelOfficesServices";
import BrokerServices from "./components/Homepage/BrokerServices";
import StatisticsBroker from "./components/Homepage/StatisticsBroker";
import StatisticsInfluancer from "./components/Homepage/StatisticsInfluancer";
import DetailsCardsVehicleBroker from "./components/DetailsCard/DetailsCardsVehicleBroker";
// import Payment from "./pages/Payment";
import JoinTravelOfficee from "./pages/JoinTravel_officee";
// import VerificationPayment from "./pages/VerificationPayment";
import Profile from "./pages/Profile";
import Notifications from "./pages/Notifications";
import Settings from "./pages/Settings";
import EditProfilebrin from "./pages/EditProfilebr_in";
import EditProfile from "./pages/EditProfile";
import Sort from "./pages/Sort";
import Order from "./pages/Order";
import Navbarloginproperty from "./components/Navbarloginproperty";
import JoinBroker from "./pages/JoinBroker";
import JionInfluencer from "./pages/JionInfluencer";
import DetailsPropety from './pages/DetailsPropety';
import Chat from './components/Chat1';
import { useState } from "react";
import axios from "axios";
import { useSetAtom } from 'jotai';
import { setUserAtom } from "./store/login-store";
function App() {
  const token = localStorage.getItem('token');
  const [selectedSection, setSelectedSection] = useState('');

  const handleSelection = (section) => {
    setSelectedSection(section);
  };
  const [category, setcategory] = useState(1);
  const [formordertrovel, setformordertrovel] = useState({
    latitudfor: 0, longitudefor: 0, latitudto: 0, longitudto: 0, flights_capacity: 0, start_time: 1 / 1 / 2025, end_time: 1 / 1 / 2025,
    description: '', phone: '', price: 0, image: ''
  });
  const updateFormOrderTrovel = (e) => {
    setformordertrovel({
      ...formordertrovel,
      [e.target.name]: e.target.value,
    });
  };
  const setUser = useSetAtom(setUserAtom);
  const [formData, setFormData] = useState({
    status_car: '', vechicle_model_id: 2, vechicle_make_id: 1, year: '', mileage: '',  number_of_seats: '',
    fuel_type: '', Motion_vector: '', horsepower: '', engine_size: '', Interior_color: '', air_conditioner: 0, Airbags: 0,
    Electric_windows: 0, CD: 0, usb: 0, Electric_seats: 0, exterior_color: '', structure_type: '', has_been_in_accidents: '',
    Spare_tire: 0, lights: 0, Fog_lights: 0, tinted_windows: 0, electric_mirrors: 0, panorama: 0, original_color: 0,
    Touch_screen: 0, apple_carplay: 0, rear_camera: 0, media_screen: 0, bluetooth: 0, taxes: 0, cruise_control: 0, license_status: '',
    insurance_status: '', service_history: '', payment_option: '', price: '', is_price_negotiable: 0, price_negotiable: '', description: ''
  });

  const updateFormData = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const updatecheckbox = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? (checked ? 1 : 0) : value
    }));
  };

  const handleSubmit = () => {
    axios.post('https://nawyapp.com/api/vehicle', formData, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    }
    )
      .then(response => {
        console.log('Data submitted successfully:', response.data);
      })
      .catch(error => {
        console.error('There was an error submitting the form:', error);
      });
  };

  const handleLogout = async (e) => {
    e.preventDefault();
    try {
      if (token) {
        await axios.post('https://nawyapp.com/api/logout', {}, {
          headers: { 'Authorization': `Bearer ${token}` }
        });
      }
      localStorage.removeItem('token');
      setUser(null);
      window.location.href = '/login';
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <div>
      <BrowserRouter>
        <ScrollToTop />
        {token === null ? <Navbar /> : <Navbarloginproperty category={category} handleLogout={handleLogout} />}
        <Routes>
          <Route index element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/choose_categories" element={<CategoriesType setcategory={setcategory} handleSelection={handleSelection} />} />
          <Route
            path="/JionInfluencer"
            element={
              <PrivateRoute>
                <JionInfluencer />
              </PrivateRoute>
            }
          />
          <Route
            path="/Sort"
            element={
              <PrivateRoute>
                <Sort />
              </PrivateRoute>
            }
          />
          <Route
            path="/JoinTravel_officee"
            element={
              <PrivateRoute>
                <JoinTravelOfficee />
              </PrivateRoute>
            }
          />
          <Route
            path="/JoinBroker"
            element={
              <PrivateRoute>
                <JoinBroker />
              </PrivateRoute>
            }
          />
          <Route path="/map" element={<Map1 />} />

          <Route
            path="/Order"
            element={
              <PrivateRoute>
                <Order />
              </PrivateRoute>
            }
          />
          <Route
            path="/Orderinfluancer"
            element={
              <PrivateRoute>
                <OrderInfluancer />
              </PrivateRoute>
            }
          />
          <Route
            path="/Post"
            element={
              <PrivateRoute>
                <EditProfilebrin />
              </PrivateRoute>
            }
          />
          <Route
            path="/Settings"
            element={
              <PrivateRoute>
                <Settings />
              </PrivateRoute>
            }
          />
          <Route
            path="/EditProfile"
            element={
              <PrivateRoute>
                <EditProfile />
              </PrivateRoute>
            }
          />
          <Route
            path="/Profile"
            element={
              <PrivateRoute>
                <Profile />
              </PrivateRoute>
            }
          />
          <Route
            path="/Notifications"
            element={
              <PrivateRoute>
                <Notifications />
              </PrivateRoute>
            }
          />

          <Route
            path="/choose_categories/homepage_vehicles"
            element={
                <PageVehicle />
            }
          />
          <Route
            path="/agency/:id"
            element={
              <PrivateRoute>
                <Agency />
              </PrivateRoute>
            }
          />
          <Route
            path="/details_cards_property/:id"
            element={
              <PrivateRoute>
                <DetailsCardsProperty />
              </PrivateRoute>
            }
          />
          <Route
            path="/details_cards_vehicle/:id"
            element={
              <PrivateRoute>
                <DetailsCardsVehicle />
              </PrivateRoute>
            }
          />
          <Route
            path="/BrokerServices/:id"
            element={
              <PrivateRoute>
                <BrokerServices />
              </PrivateRoute>
            }
          />
          <Route
            path="/statistics_broker"
            element={
              <PrivateRoute>
                <StatisticsBroker />
              </PrivateRoute>
            }
          />
          <Route
            path="/statistics_influancer"
            element={
              <PrivateRoute>
                <StatisticsInfluancer />
              </PrivateRoute>
            }
          />
          <Route
            path="/vehicle/add1"
            element={
              <PrivateRoute>
                <Add1 updateFormData={updateFormData} formData={formData}/>
              </PrivateRoute>
            }
          />
          <Route
            path="/vehicle/add2"
            element={
              <PrivateRoute>
                <Add2 updatecheckbox={updatecheckbox} formData={formData} updateFormData={updateFormData} />
              </PrivateRoute>
            }
          />
          <Route
            path="/vehicle/add3"
            element={
              <PrivateRoute>
                <Add3 updatecheckbox={updatecheckbox} updateFormData={updateFormData} />
              </PrivateRoute>
            }
          />
          <Route
            path="/vehicle/add4"
            element={
              <PrivateRoute>
                <Add4 updatecheckbox={updatecheckbox} />
              </PrivateRoute>
            }
          />
          <Route
            path="/vehicle/add5"
            element={
              <PrivateRoute>
                <Add5 formData={formData} updatecheckbox={updatecheckbox} updateFormData={updateFormData} />
              </PrivateRoute>
            }
          />
          <Route
            path="/vehicle/add6"
            element={
              <PrivateRoute>
                <Add6 formData={formData} updateFormData={updateFormData} handleSubmit={handleSubmit} />
              </PrivateRoute>
            }
          />
          <Route
            path="/property/add"
            element={
              <PrivateRoute>
                <AddProperty />
              </PrivateRoute>
            }
          />
          <Route
            path="/share"
            element={
              <PrivateRoute>
                <Share />
              </PrivateRoute>
            }
          />
          <Route
            path="/property/influancer_services/:id"
            element={
              <PrivateRoute>
                <InfluancerServices />
              </PrivateRoute>
            }
          />
          <Route
            path="/order_travel/travel_offieces_services/:id"
            element={
              <PrivateRoute>
                <TravelOfficesServices />
              </PrivateRoute>
            }
          />
          <Route path="/order_travel/add1" element={<AddTravel setformordertrovel={setformordertrovel} updateFormOrderTrovel={updateFormOrderTrovel} />} />
          <Route path="/order_travel/start" element={<Start />} updateFormOrderTrovel={updateFormOrderTrovel} />
          <Route
            path="/order_travel/AllOrders"
            element={
              <PrivateRoute>
                <AllOrders />
              </PrivateRoute>
            }
          />
          <Route
            path="/order_travel/add"
            element={
              <PrivateRoute>
                <AddOrderTravel setformordertrovel={setformordertrovel} formordertrovel={formordertrovel} updateFormOrderTrovel={updateFormOrderTrovel} />
              </PrivateRoute>
            }
          />
          <Route
            path="/chat"
            element={
              <PrivateRoute>
                <Chat />
              </PrivateRoute>
            }
          />
          <Route
            path="/choose_categories/homepage_properties"
            element={
              <Page />
            }
          />
          <Route
            path="/choose_categories/homepage_order_travel"
            element={
              <PageOrderTravel />
            }
          />
          <Route
            path="/details_cards_vehicle_broker/:id"
            element={
              <PrivateRoute>
                <DetailsCardsVehicleBroker />
              </PrivateRoute>
            }
          />
          <Route
            path="/order_travel/details_travel_order/:id"
            element={
              <PrivateRoute>
                <DetailsTravelOrder />
              </PrivateRoute>
            }
          />
        </Routes>
        <ScrollToTop />
      </BrowserRouter>
      <Footer />
    </div>
  );
}

const PrivateRoute = ({ children }) => {
  const token = localStorage.getItem('token');
  return token ? children : <Navigate to="/login" replace />;
};

export default App;