import React from "react";
import nawi from "../../../assets/ImagesVehicle/Firrari.png";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Important = ({title, vehicles_make}) => {

  const navigate= useNavigate();

  const detailsproperty = (id) => {
    navigate(`/agency/`+ id);
  }
  const {t} = useTranslation();

  return (
    <div className=" flex flex-col items-center pb-8">
      <h1 className="text-2xl font-bold mb-4 float-right">{title}</h1>
      <div className="p-3 flex">
        {vehicles_make.map((agency) => (
          <button 
          onClick={() => detailsproperty(agency.id)}
          >
            <div
              key={agency.id}
              className="flex flex-col-2 mx-3 float-left text-center rounded-full shadow-md bg-[#FFA500] hover:bg-[#990033]"
            >
          <p className="text-lg justify-center px-3 py-3 ml-2 text-center font-semibold text-white">{agency.name}</p>
            </div>
          </button>
        ))}
      </div>
    </div>
  );
};

export default Important;
