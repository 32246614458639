// import React, { useEffect, useState } from 'react';

// const LocationFetcher = ({ onLocationFetched }) => {
//   const [location, setLocation] = useState({ latitude: null, longitude: null });

//   useEffect(() => {
//     if (navigator.geolocation) {
//       navigator.geolocation.getCurrentPosition((position) => {
//         const { latitude, longitude } = position.coords;
//         setLocation({ latitude, longitude });
//         onLocationFetched(latitude, longitude);
//       });
//     } else {
//       alert('Geolocation is not supported by this browser.');
//     }
//   }, [onLocationFetched]);

//   return (
//     <div>
//       {location.latitude && location.longitude ? (
//         <p>Latitude: {location.latitude}, Longitude: {location.longitude}</p>
//       ) : (
//         <p>Fetching location...</p>
//       )}
//     </div>
//   );
// };

// export default LocationFetcher;


// import React, { useEffect, useState } from 'react';
// import { LoadScript, StandaloneSearchBox } from '@react-google-maps/api';

// const libraries = ["places"];

// const LocationFetcher = ({ onLocationFetched }) => {
//   const [location, setLocation] = useState({ latitude: null, longitude: null });
//   const [address, setAddress] = useState("");

//   useEffect(() => {
//     if (navigator.geolocation) {
//       navigator.geolocation.getCurrentPosition((position) => {
//         const { latitude, longitude } = position.coords;
//         setLocation({ latitude, longitude });
//         onLocationFetched(latitude, longitude);
//         fetchAddress(latitude, longitude);
//       });
//     } else {
//       alert('Geolocation is not supported by this browser.');
//     }
//   }, [onLocationFetched]);

//   const fetchAddress = async (latitude, longitude) => {
//     const geocoder = new window.google.maps.Geocoder();
//     const latlng = { lat: latitude, lng: longitude };

//     geocoder.geocode({ location: latlng }, (results, status) => {
//       if (status === 'OK') {
//         if (results[0]) {
//           setAddress(results[0].formatted_address);
//         } else {
//           console.log('No results found');
//         }
//       } else {
//         console.log('Geocoder failed due to: ' + status);
//       }
//     });
//   };

//   return (
//     <LoadScript
//       googleMapsApiKey='AIzaSyCN1ldCkq6kz0PbBE6Y2eUTLYIGyNfcQxY'
//       libraries={libraries}
//     >
//       <div>
//         {location.latitude && location.longitude ? (
//           <div>
//             <p>Latitude: {location.latitude}, Longitude: {location.longitude}</p>
//             <p>Address: {address}</p>
//           </div>
//         ) : (
//           <p>Fetching location...</p>
//         )}
//       </div>
//     </LoadScript>
//   );
// };

// export default LocationFetcher;



// import React, { useEffect, useState } from 'react';
// import { LoadScript } from '@react-google-maps/api';

// const libraries = ["places"];

// const LocationFetcher = ({ onLocationFetched }) => {
//   const [location, setLocation] = useState({ latitude: null, longitude: null  });
//   const [address, setAddress] = useState("");

//   useEffect(() => {
//     if (navigator.geolocation) {
//       navigator.geolocation.getCurrentPosition(
//         (position) => {
//           const { latitude, longitude } = position.coords;
//           setLocation({ latitude, longitude });
//           onLocationFetched(latitude, longitude,address);
//           fetchAddress(latitude, longitude);
//         },
//         (error) => {
//           console.error('Error getting location:', error);
//         }
//       );
//     } else {
//       alert('Geolocation is not supported by this browser.');
//     }
//   }, [onLocationFetched]);

//   const fetchAddress = async (latitude, longitude) => {
//     const geocoder = new window.google.maps.Geocoder();
//     const latlng = { lat: latitude, lng: longitude };
//     geocoder.geocode({ location: latlng }, (results, status) => {
//       if (status === 'OK') {
//         if (results[0]) {
//           setAddress(results[0].formatted_address);
         

//         } else {
//           console.log('No results found');
//         }
//       } else {
//         console.log('Geocoder failed due to: ' + status);
//       }
//     });
//   };

//   return (
//     <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} libraries={libraries}>
//       <div>
//         {location.latitude && location.longitude ? (
//           <div>
//             <p>Latitude: {location.latitude}, Longitude: {location.longitude}</p>
//             <p>Address: {address}</p>
//           </div>
//         ) : (
//           <p>Fetching location...</p>
//         )}
//       </div>
//     </LoadScript>
//   );
// };

// export default LocationFetcher;
import React, { useEffect, useState } from 'react';
import { LoadScript } from '@react-google-maps/api';

const libraries = ["places"];

const LocationFetcher = ({ onLocationFetched }) => {
  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [address, setAddress] = useState("");
  const [isGoogleLoaded, setIsGoogleLoaded] = useState(false); // حالة للتحقق من تحميل المكتبة

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLocation({ latitude, longitude });
          onLocationFetched(latitude, longitude, address);
          // استدعاء fetchAddress فقط إذا تم تحميل خرائط جوجل
          if (isGoogleLoaded) {
            fetchAddress(latitude, longitude);
          }
        },
        (error) => {
          console.error('Error getting location:', error);
        }
      );
    } else {
      alert('Geolocation is not supported by this browser.');
    }
  }, [onLocationFetched, isGoogleLoaded]); // الآن تعتمد على isGoogleLoaded

  const fetchAddress = async (latitude, longitude) => {
    const geocoder = new window.google.maps.Geocoder();
    const latlng = { lat: latitude, lng: longitude };
    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          setAddress(results[0].formatted_address);
        } else {
          console.log('No results found');
        }
      } else {
        console.log('Geocoder failed due to: ' + status);
      }
    });
  };

  return (
    <LoadScript
      googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
      libraries={libraries}
      onLoad={() => setIsGoogleLoaded(true)} // تعيين isGoogleLoaded عند تحميل المكتبة
    >
      <div>
        {location.latitude && location.longitude ? (
          <div>
            <p>Latitude: {location.latitude}, Longitude: {location.longitude}</p>
            <p>Address: {address}</p>
          </div>
        ) : (
          <p>Fetching location...</p>
        )}
      </div>
    </LoadScript>
  );
};

export default LocationFetcher;