import React, { useState, useEffect, useRef } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { BiChevronDown } from "react-icons/bi";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useSetAtom } from "jotai";
import { setUserAtom } from "../store/login-store";
import { setStoredLanguage, getStoredLanguage } from "../store/languageStore";

import nawi from "../assets/Images/nawi.svg";

function EnhancedNavbar({ category }) {
  const { t, i18n } = useTranslation();
  const setUser = useSetAtom(setUserAtom);

  const [nav, setNav] = useState(false);
  const [language, setLanguage] = useState(i18n.language);
  const [selected, setSelected] = useState("");
  const [open, setOpen] = useState(false);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [image, setImage] = useState('');
  const [role, setRoleId] = useState(4);

  const sidebarRef = useRef(null);

  useEffect(() => {
    const currentLanguage = getStoredLanguage();
    setSelected(currentLanguage === "en" ? "English" : "العربية");
    fetchUserProfile();
  }, []);

  const fetchUserProfile = async () => {
    const token = localStorage.getItem('token');
    try {
      const { data } = await axios.get('https://nawyapp.com/api/Myprofile', {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      setUsername(data.username);
      setEmail(data.email);
      setImage(data.image);
      setRoleId(data.role_id);
    } catch (error) {
      console.error('Error fetching user profile:', error);
    }
  };

  const handleNav = () => setNav(!nav);
  const closeNav = () => setNav(false);

  const handleLogout = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      if (token) {
        await axios.post('https://nawyapp.com/api/logout', {}, {
          headers: { 'Authorization': `Bearer ${token}` }
        });
      }
      localStorage.removeItem('token');
      setUser(null);
      window.location.href = '/login';
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setStoredLanguage(lang);
    setSelected(lang === "en" ? "English" : "العربية");
    setOpen(false);
  };

  const LanguageSelector = () => (
    <div className="relative bg-[#990033] rounded-lg shadow-md p-2">
      <div
        onClick={() => setOpen(!open)}
        className="bg-[#990033] text-white w-full flex items-center justify-between cursor-pointer hover:border-purple-500 transition-colors duration-200"
      >
        <span>{selected || t('setting_ar')}</span>
        <BiChevronDown size={20} className={`transition-transform duration-300 ${open ? "rotate-180" : ""}`} />
      </div>
      {open && (
        <ul className="absolute z-10 w-full mt-1 bg-white text-black border rounded-md shadow-lg">
          {["en", "ar"].map((lang) => (
            <li
              key={lang}
              className="p-3 flex items-center hover:bg-purple-50 cursor-pointer transition-colors duration-200"
              onClick={() => changeLanguage(lang)}
            >
              {t(`setting_${lang}`)}
            </li>
          ))}
        </ul>
      )}
    </div>
  );

  const joinOptions = [
    { value: 'broker', label: t('navbar_broker'), path: '/JoinBroker' },
    { value: 'influencer', label: t('navbar_influanser'), path: '/JionInfluencer' },
    { value: 'travel_office', label: t('navbar_travel_office'), path: '/JoinTravel_officee' },
  ];

  const getNavItems = () => {
    const commonItems = [
      { label: t('chat'), path: "/chat" },
      { label: t('My_interest'), path: "/choose_categories" },
      { label: t('chat_profile'), path: "/Profile" },
    ];

    const categorySpecificItems = {
      1: [
        { label: t('main'), path: "/choose_categories/homepage_properties" },
        { label: t('add_property_sale'), path: "/property/add" },
        { label: t('map'), path: "/map" },
      ],
      2: [
        { label: t('main'), path: "/choose_categories/homepage_vehicles" },
        { label: t('add_property_sale'), path: "/vehicle/add1" },
      ],
      3: [
        { label: t('main'), path: "/choose_categories/homepage_order_travel" },
      ],
    };

    const roleSpecificItems = {
      2: [
        { label: t('statistics'), path: "/statistics_broker" },
        { label: t('order'), path: "/Order" },
      ],
      3: [
        { label: t('statistics'), path: "/statistics_influancer" },
        { label: t('order'), path: "/Orderinfluancer" },
        { label: t('content_header'), path: "/Post" },
      ],
      5: [
        { label: t('travelOffice_des1'), path: "/order_travel/AllOrders" },
      ],
    };

    return [
      ...commonItems,
      ...(categorySpecificItems[category] || []),
      ...(roleSpecificItems[role] || []),
    ];
  };

  const navItems = getNavItems();

  return (
    <div className="bg-white shadow-md">
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center h-16">
          <img className="h-12" src={nawi} alt="Nawi Logo" />

          <ul className="hidden lg:flex items-center space-x-4">
            {navItems.map((item, index) => (
              <li key={index}>
                <Link to={item.path}>
                  <button className="px-3 py-2 rounded-full text-black hover:bg-[#800080] hover:text-white transition-colors duration-300">
                    {item.label}
                  </button>
                </Link>
              </li>
            ))}
            <li>
              <select
                className="px-3 py-2 text-black hover:text-[#800080] text-sm border border-purple-500 rounded-md cursor-pointer"
                onChange={(e) => {
                  const selectedOption = joinOptions.find(option => option.value === e.target.value);
                  if (selectedOption) {
                    window.location.href = selectedOption.path;
                  }
                }}
              >
                <option value="" className="text-center">{t('navbar_join')}</option>
                {joinOptions.map((option, index) => (
                  <option key={index} value={option.value} className="text-center">{option.label}</option>
                ))}
              </select>
            </li>
            <li>
              <LanguageSelector />
            </li>
            <li>
              <button
                onClick={handleLogout}
                className="px-3 py-2 rounded-full text-black hover:bg-[#800080] hover:text-white transition-colors duration-300"
              >
                {t('profile_logout')}
              </button>
            </li>
          </ul>

          <div onClick={handleNav} className="block lg:hidden text-[#800080] cursor-pointer z-50">
            {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
          </div>
        </div>
      </div>

      {/* Mobile sidebar */}
      {nav && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-30" onClick={closeNav}></div>
      )}

      <div
        ref={sidebarRef}
        className={`fixed top-0 ${
          nav ? "right-0" : "right-[-100%]"
        } w-[60%] h-full bg-[#800080] ease-in-out duration-500 z-40`}
      >
        <img className="h-16 mx-auto my-4" src={nawi} alt="Nawi Logo" />
        <ul className="pt-8">
          {navItems.map((item, index) => (
            <Link key={index} to={item.path} onClick={closeNav}>
              <li className="p-4 border-b border-gray-500 text-white hover:text-[#FFA500] cursor-pointer">
                {item.label}
              </li>
            </Link>
          ))}
          <li className="p-4 border-b border-gray-500 text-white">
            <LanguageSelector />
          </li>
          <li className="p-4 border-b border-gray-500 text-white hover:text-[#FFA500] cursor-pointer" onClick={handleLogout}>
            {t('profile_logout')}
          </li>
        </ul>
      </div>
    </div>
  );
}

export default EnhancedNavbar;