import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactStars from "react-stars";
import { useTranslation } from 'react-i18next';
import axios from "axios";
import { useSetAtom } from 'jotai';
import { setUserAtom } from "../store/login-store";
import { FaUser, FaCog, FaSort, FaStar, FaSignOutAlt } from 'react-icons/fa';

function ProfileComponents() {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [rating, setRating] = useState(0);
  const [userStats, setUserStats] = useState({ buying: 0, sale: 0, review: 0 });
  const [userName, setUserName] = useState("");
  const [userAvatar, setUserAvatar] = useState("");
  const setUser = useSetAtom(setUserAtom);

  const token = localStorage.getItem('token');

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await axios.get('https://nawyapp.com/api/user-profile', {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      const { name, avatar, stats } = response.data;
      setUserName(name);
      setUserAvatar(avatar);
      setUserStats(stats);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const ratingChanged = (newRating) => {
    setRating(newRating);
  };

  const submitRating = async (e) => {
    e.preventDefault();
    try {
      await axios.post('https://nawyapp.com/api/rateing', 
        { score: rating },
        { headers: { 'Authorization': `Bearer ${token}` } }
      );
      alert(t('rating_success'));
      setShowModal(false);
    } catch (error) {
      console.error('Error submitting rating:', error);
      alert(t('rating_error'));
    }
  };

  const stats = [
    { value: userStats.buying, label: t('editinf_buying'), icon: '🛒' },
    { value: userStats.sale, label: t('editinf_sale'), icon: '💰' },
    { value: userStats.review, label: t('editinf_review'), icon: '⭐' },
  ];

  const profileLinks = [
    { to: "/EditProfile", label: t('profile_inf'), icon: <FaUser /> },
    { to: "/Settings", label: t('profile_setting'), icon: <FaCog /> },
    { to: "/Sort", label: t('sort_title'), icon: <FaSort /> },
  ];

  const handleLogout = async (e) => {
    e.preventDefault();
    try {
      if (token) {
        await axios.post('https://nawyapp.com/api/logout', {}, {
          headers: { 'Authorization': `Bearer ${token}` }
        });
      }
      localStorage.removeItem('token');
      setUser(null);
      window.location.href = '/login';
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <div className="bg-gradient-to-b from-[#ebeafa] to-white min-h-screen py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto">
        <div className="bg-white rounded-lg shadow-lg p-6 mb-8">
         
          <h2 className="text-2xl font-bold text-center text-gray-800 mb-4">{userName}</h2>
          <div className="grid grid-cols-3 gap-4 mb-6">
            {stats.map((stat, index) => (
              <div key={index} className="bg-gray-50 rounded-lg p-4 text-center">
                <div className="text-3xl mb-2">{stat.icon}</div>
                <div className="text-2xl font-bold text-gray-800 mb-1">{stat.value}</div>
                <div className="text-sm text-gray-600">{stat.label}</div>
              </div>
            ))}
          </div>
        </div>

        <div className="space-y-4 mb-8">
          {profileLinks.map((link, index) => (
            <Link key={index} to={link.to} className="block">
              <button className="w-full text-right bg-white hover:bg-gray-50 transition-colors duration-200 rounded-lg p-4 shadow-md flex items-center justify-between">
                <span className="text-gray-400">{link.icon}</span>
                <span>{link.label}</span>
              </button>
            </Link>
          ))}
          <button
            onClick={() => setShowModal(true)}
            className="w-full text-right bg-white hover:bg-gray-50 transition-colors duration-200 rounded-lg p-4 shadow-md flex items-center justify-between"
          >
            <span className="text-gray-400"><FaStar /></span>
            <span>{t('profile_ev')}</span>
          </button>
        </div>

        <div className="text-center">
          <button 
            onClick={handleLogout} 
            className="bg-[#FFA500] hover:bg-[#800080] text-white font-bold py-3 px-6 rounded-full transition-colors duration-200 flex items-center justify-center mx-auto"
          >
            <FaSignOutAlt className="mr-2" />
            {t('profile_logout')}
          </button>
        </div>
      </div>

      {showModal && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white rounded-lg shadow-xl p-6 m-4 max-w-sm w-full">
            <h3 className="text-2xl font-semibold mb-4 text-center text-gray-800">
              {t('profile_Evaluation')}
            </h3>
            <form onSubmit={submitRating}>
              <div className="flex justify-center mb-6">
                <ReactStars
                  count={5}
                  onChange={ratingChanged}
                  size={48}
                  color2={"#FFA500"}
                  value={rating}
                />
              </div>
              <div className="flex justify-end space-x-2">
                <button
                  type="button"
                  onClick={() => setShowModal(false)}
                  className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400 transition-colors duration-200"
                >
                  {t('profile_close')}
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-[#FFA500] text-white rounded-md hover:bg-[#800080] transition-colors duration-200"
                >
                  {t('profile_send')}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default ProfileComponents;