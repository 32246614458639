import React, { useEffect, useState } from "react";
import Storyadmin from "./asset_comontants/story_admin";
import SearchVehicle from "./searchVehicle";
import Important from "./asset_comontants/important";
import CarsAgency from "./asset_comontants/CarsAgency";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Agency = () => {
  const { id } = useParams();
  console.log(id);
  const [vehicles_make, setvehicles_make] = useState([]);

  useEffect(() => {
    fetchvehiclesMake();
    fetchvehiclesMakes();
  }, []);
  const [vehicles, setvehicles] = useState([]);
  const fetchvehiclesMake = async () => {
    await axios
      .get("https://nawyapp.com/api/vehiclesMake/" + id)
      .then(({ data }) => {
        setvehicles(data);
        console.log(data);
      });
  };

  const fetchvehiclesMakes = async () => {
    await axios.get("https://nawyapp.com/api/vehiclesMake").then(({ data }) => {
      setvehicles_make(data);
    });
  };

  const { t } = useTranslation();
  return (
    <div className=" bg-[#e0cce7]">
      {vehicles.length ? (
        <div className="">
          {/* <Storyadmin /> */}
          {/* <SearchVehicle /> */}
          {/* <Important vehicles_make={vehicles_make} /> */}
          <CarsAgency vehicles={vehicles} isSpecial={true} />
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
          {/* Animation Spinner */}
          <div className="flex justify-center items-center mb-5">
            <div className="w-12 h-12 border-4 border-purple-500 border-solid rounded-full border-t-transparent animate-spin"></div>
          </div>
          {/* Loading Text */}
          <div className="text-lg font-semibold text-purple-600">
            {t("loading Vehicles in This Agency....")}
          </div>
        </div>
      )}
    </div>
  );
};

export default Agency;