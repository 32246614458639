import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaMapMarkerAlt } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Modal from "react-modal";

const Share = () => {

  const [selectedOption, setSelectedOption] = useState("");
  const [selectedType, setSelectedType] = useState('');
  const [selectedId, setSelectedId] = useState(0);
  const handleRadioChange = (e) => {
    const { value, dataset } = e.target;
    setSelectedType(value);
    setSelectedId(dataset.id); // Assuming the id is stored in data-id attribute
  };
  const token = localStorage.getItem('token');
  console.log(selectedType)

  const [influancers, setinfluancers] = useState([]);
  useEffect(() => {
    fatchbrokers();
    fatchinfluancers();
  }, [])

  const fatchinfluancers = async () => {
    await axios.get('https://nawyapp.com/api/Influencer').then(({ data }) => {
      setinfluancers(data)
      console.log(data)
    })
  }
  const [showmessage,setshowmessage] = useState(false)

  const adsubmit = async (e) => {
    e.preventDefault();

    if (selectedType === 'influencer') {
      const data ={
        'influancer_id': selectedId,
        'typeable_type': 'property',
        'typeable_id': 1
      };
      await axios.post('https://nawyapp.com/api/RequestAdInfluancer',data,{
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      ).then(({ data }) => {
        setshowmessage(true)
      }).then()
    }
    if (selectedType === 'broker') {
      await axios.post('https://nawyapp.com/api/RequestAdBroker','',{
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }).then(({ data }) => {
        setshowmessage(true)
      }).then()
    }}
  const [brokers, setbrokers] = useState([]);

  const fatchbrokers = async () => {
    await axios.get('https://nawyapp.com/api/Broker').then(({ data }) => {
      setbrokers(data);
      console.log(data);
    }).then()
  }
  const { t } = useTranslation();

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const [modalIsOpen2, setModalIsOpen2] = useState(false);

  const openModal2 = () => {
    setModalIsOpen2(true);
  };

  const closeModal2 = () => {
    setModalIsOpen2(false);
  };

  const [modalIsOpenN, setModalIsOpenN] = useState(false);

  const openModalN = () => {
    setModalIsOpenN(true);
  };

  const closeModalN = () => {
    setModalIsOpenN(false);
  };
  const nav= useNavigate();


  const detailsInf = (id) => {
    nav(`/property/influancer_services/${id}`)
  }

  return (
    <div className=" text-right pt-12 bg-[#EBEAFA]">
      <main>
        <section className="flex flex-wrap justify-center max-w-7xl mx-auto py-3">
          <p className="w-full text-right p-8 font-bold text-2xl">
            <p className="text-left pt-[30px] ml-[-25px] mb-[-25px] p-3">
              <Link to="">
                <svg
                  className="mr-[20px]"
                  // onClick={openModal}
                  width="36"
                  height="36"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.22004 4.92242C6.36504 4.92242 4.92004 6.36492 4.92004 8.22242V31.7799C4.92004 33.6349 6.36504 35.0799 8.22004 35.0799H31.78C33.635 35.0799 35.08 33.6349 35.08 31.7799V8.21992C35.08 6.36492 33.635 4.91992 31.78 4.91992L8.22004 4.92242ZM17.5 9.99992H22.5V14.9999H17.5V9.99992ZM17.5 17.4999H22.5V29.9999H17.5V17.4999Z"
                    fill="#474747"
                  />
                </svg>
                <Modal
                  isOpen={modalIsOpen}
                  // onRequestClose={closeModal}
                  className="fixed inset-0 flex items-center justify-center p-4"
                  overlayClassName="fixed inset-0 bg-gray-800 bg-opacity-75"
                >
                  <div className="bg-[#f5f1f5] p-6 text-center shadow-lg w-full max-w-md">
                    <h2 className="text-2xl mb-4 font-bold text-[#800080]">
                      {t("share_title")}
                    </h2>
                    <form className="relative space-y-3">
                      <div
                        className="absolute bottom-10 mb-[95px] right-0 text-xl text-black font-semibold cursor-pointer px-2 mr-[-20px] hover:text-white hover:bg-red-700 rounded-full border border-white bg-[#FFA500]"
                        // onClick={closeModal}
                      >
                        X
                      </div>
                      <p>{t("share_des")}</p>
                    </form>
                  </div>
                </Modal>
              </Link>
            </p>
            {t('prop_share_with')}
            <span className="font-normal text-[15px] text-gray-500 ml-2">
              {t('prop_share_fee')}
            </span>
          </p>
          {influancers.map((influancer) => (
            <div
              key={influancer.id}
              className="w-full sm:w-1/2 lg:w-1/2 p-2 my-[-60px] mt-[-30px] pb-[-40px]">
              <div className="w-full h-[60%] rounded-lg shadow-md overflow-hidden mb-2 sm:p-2 bg-white float-right">
                <div className="flex">
                  <img
                    className="w-20 h-20 mt-[20px] object-cover"
                    src={`https://nawyapp.com/storage/` + influancer.image}
                    alt="/"
                  />
                  <div className="p-4 flex flex-col justify-between float-right mt-[-5px]">
                    <div>
                      <div className="grid mr-[390px] mb-[-30px]">
                        <span className=" text-gray-500 px-2 py-2 rounded-full text-xs font-semibold mr-2">
                          <input
                            type="radio"
                            className="form-radio h-5 w-5 ml-2 mb-[45px]"
                            name="option"
                            value="influencer"
                            data-id={influancer.id} // Replace with dynamic ID if needed
                            // onChange={handleRadioChange}
                          />
                        </span>
                      </div>

                      <div className="mb-12">
                        <h3 className="text-[25px] mr-[8px] font-semibold">
                          {influancer.user.username}
                        </h3>
                        <p className="text-gray-500 flex float-right ml-3 pt-2 pb-2">
                          {/* {influancer.location.city} - {influancer.location.country} */}
                          <FaMapMarkerAlt className="ml-2 mt-[5px]" />
                        </p>
                        <p className="text-blue-700 text-md p-2 ml-[290px] text-lg font-semibold cursor-pointer hover:text-[#FFA500]">
                          {t('navbar_influanser')}
                        </p>
                        <div className="flex mt-[-30px] pb-9">
                          <button onClick={()=> detailsInf(influancer.id)}>
                            <span className=" text-gray-500 px-3 rounded-full text-lg font-semibold  hover:text-[#800080]">
                              {t('inf_ser_simila_details')}
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </section>
        <section className="flex flex-wrap justify-center max-w-7xl mx-auto py-3">
          <p className="w-full text-right p-8 font-bold text-2xl">
          <p className="text-left pt-[30px] ml-[-25px] mb-[-25px] p-3">
              <Link to="">
                <svg
                  className="mr-[20px]"
                  // onClick={openModal2}
                  width="36"
                  height="36"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.22004 4.92242C6.36504 4.92242 4.92004 6.36492 4.92004 8.22242V31.7799C4.92004 33.6349 6.36504 35.0799 8.22004 35.0799H31.78C33.635 35.0799 35.08 33.6349 35.08 31.7799V8.21992C35.08 6.36492 33.635 4.91992 31.78 4.91992L8.22004 4.92242ZM17.5 9.99992H22.5V14.9999H17.5V9.99992ZM17.5 17.4999H22.5V29.9999H17.5V17.4999Z"
                    fill="#474747"
                  />
                </svg>
                <Modal
                  isOpen={modalIsOpen2}
                  // onRequestClose={closeModal2}
                  className="fixed inset-0 flex items-center justify-center p-4"
                  overlayClassName="fixed inset-0 bg-gray-800 bg-opacity-75"
                >
                  <div className="bg-[#f5f1f5] p-6 text-center shadow-lg w-full max-w-md">
                    <h2 className="text-2xl mb-4 font-bold text-[#800080]">
                      {t("share_title2")}
                    </h2>
                    <form className="relative space-y-3">
                      <div
                        className="absolute bottom-10 mb-[95px] right-0 text-xl text-black font-semibold cursor-pointer px-2 mr-[-20px] hover:text-white hover:bg-red-700 rounded-full border border-white bg-[#FFA500]"
                        // onClick={closeModal2}
                      >
                        X
                      </div>
                      <p>{t("share_des2")}</p>
                    </form>
                  </div>
                </Modal>
              </Link>
            </p>
            <span className="p-2">
              <input
                type="radio"
                name="option"
                 className="form-radio h-5 w-5 ml-2 mb-[45px]"
                value="broker"
                data-id="2" // Replace with dynamic ID if needed
                // onChange={handleRadioChange}
              />
            </span>
            {t('prop_share_broker')}
            <span className="font-normal text-[15px] text-gray-500">
              {t('prop_share_broker_fee')}
            </span>
          </p>
          {brokers.map((broker) => (
            <div
              key={broker.id}
              className="w-full sm:w-1/2 lg:w-1/2 p-2 my-[-60px] mt-[-30px] pb-[-40px]"
            >
              <div className="w-full mt-[35px] h-[60%] rounded-lg shadow-md overflow-hidden mb-2 sm:p-2 bg-white float-right">
                <div className="flex">
                  <img
                    className="w-20 h-20 mt-[20px] object-cover"
                    src={`https://nawyapp.com/storage/` + broker.image}
                    alt="/"
                  />
                  <div className="p-4 flex flex-col justify-between float-right mt-[-5px] ml-[70px]">
                    <div>
                      <div className="mb-12">
                        <h3 className="text-[25px] text-right font-semibold">
                          {broker.user.username}
                        </h3>

                        <p className="text-gray-500 flex float-right ml-3 pt-2 pb-2">
                          {broker.location}
                          <FaMapMarkerAlt className="ml-2 mt-[5px]" />
                        </p>
                        <p className="text-blue-700 text-md p-2 ml-[290px] text-lg font-semibold cursor-pointer hover:text-[#FFA500]">
                          سمسار
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {/* <Link to="">
            <button className="bg-[#FFA500] mr-[45px] mt-2 hover:bg-[#800080] text-[#fff] w-[170px] rounded-full font-medium my-6 mx-auto md:mx-90 py-3 text-[23px]">
              {t('prop_share_publish')}
            </button>
          </Link> */}
        </section>

        <section className="flex flex-wrap justify-center max-w-7xl mx-auto py-3">
          <p className=" w-full text-right p-8 font-bold text-2xl">
            <span className="p-2">
            <input
            type="radio"
            name="option"
              className="form-radio h-5 w-5 ml-2 mb-[45px]"
            value="free"
            data-id="3" // Replace with dynamic ID if needed
            onChange={handleRadioChange}
          />
            </span>
            {t('prop_share_publish_option')}
          </p>
          <div className="">
            <div className="mb-4">
              <label className="flex items-center ml-4">
                <span className="text-lg font-semibold ml-[-65px]">
                  {t('prop_share_publish_option_p')}
                  <span className="text-gray-500"> {t('prop_share_publish_option_p1')} </span>
                </span>
                <input
                  type="radio"
                  name="participation"
                  className="form-radio h-5 w-5 text-blue-600 mr-2  ml-[23px]"
                  value="option1"
                  checked={selectedOption === "option1"}
                  onChange={() => setSelectedOption("option1")}
                />
              </label>
            </div>
            <div className="mb-4">
              <label className="flex items-center">
                <span className="text-lg font-semibold">
                  {t('prop_share_publish_option_engage')}
                  <span className="text-gray-500">{t('prop_share_publish_option_engage1')}</span>
                </span>
                <input
                  type="radio"
                  name="participation"
                  className="form-radio h-5 w-5 bg-blue-600 mr-2 ml-[23px]"
                  value="option2"
                  checked={selectedOption === "option2"}
                  onChange={() => setSelectedOption("option2")}
                />
              </label>
            </div>
            <div className="mb-4">
              <label className="flex items-center ml-7">
                <span className="text-lg font-semibold ml-[17px]">
                  {t('prop_share_publish_option_free')}
                  <span className="text-gray-500"> {t('prop_share_publish_option_free1')}</span>
                </span>
                <input
                  type="radio"
                  name="participation"
                  className="form-radio h-5 w-5 text-blue-600 mr-2  ml-[23px]"
                  value="option3"
                  checked={selectedOption === "option3"}
                  onChange={() => setSelectedOption("option3")}
                />
              </label>
            </div>
            <Link to="">
              <button
              onClick={openModalN}
              className="bg-[#FFA500] mr-[110px] mt-12 hover:bg-[#800080] text-[#fff] w-[170px] rounded-full font-medium my-6 mx-auto md:mx-90 py-3 text-[23px]">
                {t('add1_vehicle_next')}
              </button>
              <Modal
                isOpen={modalIsOpenN}
                onRequestClose={closeModalN}
                className="fixed inset-0 flex items-center justify-center p-4 w-full"
                overlayClassName="fixed inset-0 bg-gray-800 bg-opacity-75"
              >
                <div className="bg-white p-6 text-center rounded shadow-lg w-full max-w-2xl">
                  <h2 className="text-2xl mb-4 font-bold text-[#800080]">
                    {t("Payment_details")}
                  </h2>
                  <form>
                    <div className="grid grid-cols-1 ml-[30px] md:grid-cols-1 gap-4 font-semibold text-lg">
                      <div className="text-center grid grid-cols-2 md:grid-cols-2">
                        <p
                          type="text"
                          readOnly
                          value={""}
                          className="w-[50%] h-[30%] p-2 pr-10 text-right"
                        >
                          10 000
                        </p>{" "}
                        <p
                          type="text"
                          readOnly
                          value={""}
                          className="w-[100%] h-[30%] text-[#990033] p-2 pr-10 text-right"
                        >
                          {t('ads_price')}
                        </p>
                      </div>
                      <div className="text-center grid grid-cols-2 md:grid-cols-2">
                        <p
                          type="text"
                          readOnly
                          value={""}
                          className="w-[50%] h-[30%] p-2 pr-10 text-right"
                        >
                          8%
                        </p>{" "}
                        <p
                          type="text"
                          readOnly
                          value={""}
                          className="w-[100%] h-[30%] text-[#990033] p-2 pr-10 text-right"
                        >
                          {t('ads_tax1')}
                        </p>
                      </div>
                      <div className="text-center grid grid-cols-2 md:grid-cols-2">
                        <p
                          type="text"
                          readOnly
                          value={""}
                          className="w-[50%] h-[30%] p-2 pr-10 text-right"
                        >
                          2500
                        </p>{" "}
                        <p
                          type="text"
                          readOnly
                          value={""}
                          className="w-[100%] h-[30%] text-[#990033] p-2 pr-10 text-right"
                        >
                          {t('ads_tax2')}
                        </p>
                      </div>
                      <div className="text-center grid grid-cols-2 md:grid-cols-2">
                        <p
                          type="text"
                          readOnly
                          value={""}
                          className="w-[50%] h-[30%] p-2 pr-10 text-right"
                        >
                          1200
                        </p>{" "}
                        <p
                          type="text"
                          readOnly
                          value={""}
                          className="w-[100%] h-[30%] text-[#990033] p-2 pr-10 text-right"
                        >
                          {t('ads_tax3')}
                        </p>
                      </div>
                      <div className="text-center grid grid-cols-2 md:grid-cols-2">
                        <p
                          type="text"
                          readOnly
                          value={""}
                          className="w-[50%] h-[30%] p-2 pr-10 text-right"
                        >
                          500
                        </p>{" "}
                        <p
                          type="text"
                          readOnly
                          value={""}
                          className="w-[100%] h-[30%] text-[#990033] p-2 pr-10 text-right"
                        >
                          {t('ads_tax4')}
                        </p>
                      </div>

                      <div className="flex justify-center space-x-2 mr-[40px]">
                    <button
                      type="button"
                      className="px-8 py-2 bg-[#FFA500] mx-[50px] text-white rounded-full hover:bg-amber-600 font-semibold text-lg"
                      onClick={closeModalN}>
                      {t('content_back')}
                    </button> 
                    <button
                      type="submit"
                      onClick={adsubmit}
                      className="px-8 py-2 bg-[#800080] text-white rounded-full hover:bg-purple-700 font-semibold text-lg"
                    >
                      {t('content_sure')}
                    </button>
                  </div>


                    </div>
                  </form>
                </div>
              </Modal>
            </Link>

            <Link to="/property/add">
              <div className=" mt-[60px] ml-[-40px] bg-white rounded-full w-10 h-10 p-3 flex justify-center float-left hover:bg-[#FFA500]">
                <svg
                  width="15"
                  height="20"
                  viewBox="0 0 10 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.24023 14.48L1.76023 7.99998L8.24023 1.51998"
                    stroke="#000"
                    stroke-width="1.6"
                  />
                </svg>
              </div>
            </Link>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Share;
