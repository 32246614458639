import { CheckIcon } from "@heroicons/react/solid";
import classNames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

const Add5 = ({ updateFormData, updatecheckbox, formData }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [selectedOptions, setSelectedOptions] = useState('');
  const [isChecked0, setIsChecked0] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleNext = () => {
    navigate('/vehicle/add6');
  };

  const handPayment = (e, name) => {
    setSelectedOptions(name);
    // formData.payment_option = name;
    updatecheckbox(e);
  };

  const handleCheckboxChange0 = (e) => {
    setIsChecked0(!isChecked0);
    updatecheckbox(e);
    setShowModal(true);
  };

  const options = [t('add5_vehicle_cash'), t('des_property_paypal'), t('des_property_visa')];

  return (
    <div className="bg-[#EBEAFA]  flex items-center justify-center">
      <div className="p-8 bg-white rounded-lg shadow-md max-w-3xl w-full">
        {/* License Status */}
        <h3 className="text-xl font-semibold mb-4">{t('add5_vehicle_lic')}</h3>
        <div className="w-[60%] mb-6">
          <select
          value={formData.license_status}
            name="license_status"
            onChange={updateFormData}
            className="p-3 w-full rounded-xl border-2 border-[#800080] h-16 text-center"
          >
             <option value="">{t('choose')}</option>
            <option value="مرخص">{t('add5_vehicle_lic2')}</option>
            <option value="غير مرخص">{t('add5_vehicle_lic3')}</option>
          </select>
        </div>

        {/* Insurance Status */}
        <h3 className="text-xl font-semibold mb-4">{t('add5_vehicle_insur')}</h3>
        <div className="w-[60%] mb-6">
          <select
            value={formData.insurance_status}
            name="insurance_status"
            onChange={updateFormData}
            className="p-3 w-full rounded-xl border-2 border-[#800080] h-16 text-center"
          >
            <option value="">{t('choose')}</option>
            <option value="مؤمنة">{t('add5_vehicle_insur2')}</option>
            <option value="غير مؤمنة">{t('add5_vehicle_insur3')}</option>
          </select>
        </div>

        {/* Tax Status */}
        <h3 className="text-xl font-semibold mb-4">{t('add5_vehicle_tax')}</h3>
        <div className="w-[60%] mb-6">
          <select
          value={formData.taxes}
          name="taxes"
            onChange={updateFormData}
            className="p-3 w-full rounded-xl border-2 border-[#800080] h-16 text-center"
          >
             <option value="">{t('choose')}</option>
            <option value="1">{t('add5_vehicle_tax2')}</option>
            <option value="0">{t('add5_vehicle_tax3')}</option>
          </select>
        </div>

        {/* Service History */}
        <h3 className="text-xl font-semibold mb-4">{t('add5_vehicle_servi')}</h3>
        <div className="w-[60%] mb-6">
          <input
            type="text"
            value={formData.service_history}
            name="service_history"
            onChange={updateFormData}
            className="w-full p-3 border rounded-xl text-right"
            placeholder={t('Service history number')}
          />
        </div>

        {/* Payment Options */}
        <h3 className="text-xl font-semibold mb-4">{t('add5_vehicle_pay')}</h3>
        <div className="w-[60%] grid grid-cols-1 sm:grid-cols-3 gap-4 mb-6">
          {options.map((option2) => (
            <button
              key={option2} parment_option
              name="payment_option"

              onClick={(e) => handPayment(e, option2)}
              className={`py-3 px-6 rounded-2xl ${selectedOptions === option2 ? "bg-[#800080] text-white" : "bg-white"}`}
            >
              {option2}
            </button>
          ))}
        </div>

        {/* Price */}
        <h3 className="text-xl font-semibold mb-4">{t('add5_vehicle_price')}</h3>
        <div className="w-[60%] mb-6">
          <input
            type="text"
            value={formData.price}
            name="price"
            onChange={updateFormData}
            className="w-full p-3 border rounded-xl text-right"
            placeholder={t('price')}
          />
          <label className="flex items-center mt-2">
            <input
              type="checkbox"
              className="hidden"
              name="is_price_negotiable"
              checked={isChecked0}
              onChange={handleCheckboxChange0}
            />
            <span className={classNames("w-4 h-4 border rounded-sm mr-2", { "bg-green-500": isChecked0 })}>
              {isChecked0 && <CheckIcon className="w-4 h-4 text-white" />}
            </span>
            {t('add5_vehicle_price2')}
          </label>
        </div>

        {/* Navigation Buttons */}
        <div className="flex justify-between items-center mt-8">
          <Link to="/vehicle/add4">
            <div className="bg-white rounded-full w-10 h-10 flex justify-center items-center shadow-md hover:bg-[#FFA500] transition duration-300">
              <svg
                width="15"
                height="20"
                viewBox="0 0 10 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M8.24023 14.48L1.76023 7.99998L8.24023 1.51998" stroke="#000" strokeWidth="1.6" />
              </svg>
            </div>
          </Link>
          <span className="text-lg font-medium">5/6</span>
          <button
            onClick={handleNext}
            className="bg-[#FFA500] text-white rounded-full px-8 py-3 text-lg font-semibold shadow-md hover:bg-[#FF8C00] transition duration-300"
          >
            {t('add1_vehicle_next')}
          </button>
        </div>

        {/* Modal for Negotiable Price */}
        {showModal && (
          <div className="fixed inset-0 z-50 flex items-center justify-center  bg-gray-500 bg-opacity-75">
            <div className="bg-white rounded-lg shadow-xl p-6 w-full max-w-lg">
              <h3 className="text-2xl font-semibold mb-4 text-center">{t('price_negotiable')}</h3>
              <input
                type="text"
                value={formData.price_negotiable}
                name="price_negotiable"
                onChange={updateFormData}
                className="w-full p-3 border rounded-xl text-right"
                placeholder="Enter negotiable price"
              />
              <div className="flex justify-center mt-6">
                <button
                  className="bg-[#FFA500] text-white py-2 px-6 rounded-md shadow-md hover:bg-[#800080] transition duration-300"
                  onClick={() => setShowModal(false)}
                >
                  {t('profile_send')}
                </button>
                <button
                  className="ml-4 bg-white text-gray-700 py-2 px-6 rounded-md border border-gray-300 shadow-md hover:bg-gray-50 transition duration-300"
                  onClick={() => setShowModal(false)}
                >
                  {t('profile_close')}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Add5;
