import React, { useEffect, useState } from "react";
import DescriptionCard from "./DescriptionCard";
import VehicleList from "./VehicleList";
import CarouselVehicle from "./CarouselVehicle";
import DescriptionCarouselVehicle from "./DescriptionCarouselVehicle";
import ImagesvVehicle from "./ImagesvVehicle";
import DescriptionVehicleBroker from "./DescriptionVehicleBroker";
import { useParams } from "react-router-dom";
import axios from "axios";
import { t } from "i18next";

const DetailsCardsVehicleBroker = () => {
  const [DesCard, setDesCard] = useState(0);
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [car, setcar] = useState([]);
  const [images, setimages] = useState([]);
  const fetchproperty = async () => {
    await axios
      .get("https://nawyapp.com/api/showVehicle/" + id)
      .then(({ data }) => {
        setcar(data);
        fetchimages(data.id);
      });
  };
  useEffect(() => {
    fetchproperty();
  }, []);

  const fetchimages = async (id) => {
    await axios
      .get(`https://nawyapp.com/api/showfiles`, {
        params: {
          type: "Vehicle",
          id: id,
        },
      })
      .then(({ data }) => {
        setimages(data);
        console.log(data);
        setLoading(false);
      });
  };

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
        {/* Animation Spinner */}
        <div className="flex justify-center items-center mb-5">
          <div className="w-12 h-12 border-4 border-purple-500 border-solid rounded-full border-t-transparent animate-spin"></div>
        </div>

        {/* Loading Text */}
        <div className="text-lg font-semibold text-purple-600">
          {t('lodi')}
        </div>
      </div>
    );
  }

  return (
    <div className="bg-[#EBEAFA]">
      <CarouselVehicle
        images={images}
        make={car.vechicle_make.name}
        vId={car.id}
      />
      <DescriptionCarouselVehicle
        make={car.vechicle_make.name}
        model={car.vechicle_model.name}
      />
      <DescriptionCard setDesCard={setDesCard} />
      {DesCard === 0 && <DescriptionVehicleBroker car={car} />}
      {DesCard === 1 && <ImagesvVehicle images={images} />}
      <div className="">
        {DesCard === 2 && <VehicleList vId={car.id} />}
      </div>
    </div>
  );
};

export default DetailsCardsVehicleBroker;
