// Navbar.js
import React, { useState, useEffect } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import nawi from "../assets/Images/nawi.svg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useSetAtom } from "jotai";
import { setUserAtom } from "../store/login-store";
import { BiChevronDown } from "react-icons/bi";
import { setStoredLanguage, getStoredLanguage } from "../store/languageStore";


const Navbar = () => {
  const setUser = useSetAtom(setUserAtom);
  const [nav, setNav] = useState(false);

  const {t, i18n} = useTranslation();
  const [language, setLanguage] = useState(i18n.language);


  const toggleLanguage = () => {
    const newLanguage = language === 'en'? 'ar' : 'en';
    setLanguage(newLanguage);
    i18n.changeLanguage(newLanguage);
  }

  const [selected, setSelected] = useState("");
  const [open, setOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  useEffect(() => {
    const currentLanguage = getStoredLanguage();
    setSelected(currentLanguage === "en" ? "English" : "العربية");
  }, []);

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    setStoredLanguage(language);
    setSelected(language === "en" ? "English" : "العربية");
    setOpen(false);
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");}


  const handleNav = () => {
    setNav(!nav);
    document.body.style.overflow = nav ? 'auto' : 'hidden';
  };
  const handleLogout = async (e) => {
    e.preventDefault();


    try {
      const token = localStorage.getItem('token');

      if (token) {
        await axios.post('https://nawyapp.com/api/logout', {}, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
      }

      localStorage.removeItem('token');
      setUser(null); // Clear the user data in Jotai atom

      window.location.href = '/login';

    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  useEffect(() => {

    // Add padding to the body to account for the fixed header
    document.body.style.paddingTop = "80px";
    return () => {
      document.body.style.overflow = 'auto';
      document.body.style.paddingTop = "0px";
    };
  }, []);

  const navItems = [
    { label: 'map', path: '/map' },
    { label: 'navbar_prpoerties', path: '/choose_categories/homepage_properties' },
    { label: 'navbar_travels', path: '/choose_categories/homepage_order_travel' },
    { label: 'navbar_cars', path: '/choose_categories/homepage_vehicles' },
    { label: 'main', path: '/' },
  ];

const LanguageSelector = ({ selected, open, setOpen, changeLanguage, t }) => (
  <div className="relative bg-[#990033] rounded-lg shadow-md p-2">
    <div
      onClick={() => setOpen(!open)}
      className="bg-[#990033] text-white w-full flex items-center justify-between cursor-pointer hover:border-purple-500 transition-colors duration-200"
    >
      <span>{selected || t('setting_ar')}</span>
      <BiChevronDown size={20} className={`transition-transform duration-300 ${open ? "rotate-180" : ""}`} />
    </div>
    {open && (
      <ul className="absolute z-10 w-full mt-1 bg-white border rounded-md shadow-lg">
        {["en", "ar"].map((lang) => (
          <li
            key={lang}
            className="p-3 flex items-center hover:bg-purple-50 cursor-pointer transition-colors duration-200"
            onClick={() => { changeLanguage(lang); }}
          >
            {t(`setting_${lang}`)}
          </li>
        ))}
      </ul>
    )}
  </div>
);

  return (
    <>
      {/* Mobile Menu Button */}
      <div className="fixed top-0 right-0 z-[100] md:hidden">
        <div onClick={handleNav} className="cursor-pointer p-4">
          <AiOutlineMenu size={25} color="black" />
        </div>
      </div>

      {/* Desktop Menu */}
      <nav className="hidden md:block fixed top-0 left-0 w-full h-20 z-[100] bg-white shadow-md">
        <div className="flex justify-between items-center w-full h-full px-4 2xl:px-16">
          <Link to="/">
            <img src={nawi} alt="Logo" className="w-auto h-12 md:h-16" />
          </Link>
          <div className="flex items-center">
            <ul className="flex">
              {navItems.map((item, index) => (
                <NavLink key={index} to={item.path} label={t(item.label)} />
              ))}
            </ul>

            <Link to="/login">
              <button className="mx-2 px-6 py-2 border border-[#FFA500] rounded-md hover:bg-[#FFA500] hover:text-white transition duration-300">
                {t('navbar_signin')}
              </button>
            </Link>
            
            <Link to="/register">
              <button className="mx-2 px-6 py-2 bg-[#FFA500] text-white rounded-md hover:bg-amber-600 transition duration-300">
                {t('navbar_register_now')}
              </button>
            </Link>
            <LanguageSelector
                selected={selected}
                open={open}
                setOpen={setOpen}
                changeLanguage={changeLanguage}
                t={t}
              />
          </div>
        </div>
      </nav>

      {/* Mobile Menu Overlay */}
      <div
        className={`fixed top-0 left-0 w-full h-screen bg-black/70 z-[10000] transition-opacity duration-300 ease-in-out ${nav ? 'opacity-100' : 'opacity-0 pointer-events-none'
          }`}
        onClick={handleNav}
      ></div>

      {/* Mobile Menu Sidebar */}
      <div
        className={`
          fixed top-0 left-0 w-[75%] sm:w-[60%] md:w-[45%] h-screen 
          bg-[#800080] transition-transform duration-300 ease-in-out z-[10001]
          ${nav ? 'translate-x-0' : '-translate-x-full'}
          overflow-y-auto
        `}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="p-10">
          <div className="flex w-full items-center justify-between">
            <Link to="/">
              <img src={nawi} alt="Logo" className="w-auto h-8" />
            </Link>
            <div onClick={handleNav} className="rounded-full shadow-lg shadow-gray-400 p-3 cursor-pointer">
              <AiOutlineClose color="white" />
            </div>
          </div>
          <div className="border-b border-gray-300 my-4">
            <p className="w-[85%] md:w-[90%] py-4 text-white">Let's build something legendary together</p>
          </div>
          <div className="py-4 flex flex-col">
            <ul className="uppercas">
              {navItems.map((item, index) => (
                <NavLink key={index} to={item.path} label={t(item.label)} mobile onClick={handleNav} />
              ))}
            </ul>
            <div className="flex flex-col gap-4">
              <Link to="/login" onClick={handleNav}>
                <button className="w-full px-6 py-2 border border-[#FFA500] rounded-md bg-white hover:bg-[#FFA500] hover:text-white transition duration-300">
                  {t('navbar_signin')}
                </button>
              </Link>
    
              <Link to="/register" onClick={handleNav}>
                <button className="w-full px-6 py-2 bg-[#FFA500] text-white rounded-md hover:bg-amber-600 transition duration-300">
                  {t('navbar_register_now')}
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const NavLink = ({ to, label, mobile, onClick }) => (
  <li className={`${mobile ? 'py-4 text-sm text-white' : 'ml-10 text-sm uppercase hover:border-b'}`}>
    <Link to={to} onClick={onClick}>{label}</Link>
  </li>
  
);

export default Navbar;