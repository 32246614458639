import React, { useEffect, useState } from "react";
import Laptop from "../assets/Images/nawi.svg";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

const CategoriesType = ({setcategory}) => {


  const [categories_properties, setCategories_properties] = useState([]);
  useEffect(() => {
    fetchcategories_properties();
  }, [])
  const fetchcategories_properties = async () => {
    await axios.get('https://nawyapp.com/api/categories_properties').then(({ data }) => {
      setCategories_properties(data)
    })
  }


  const [categoriesall, setCategories] = useState([]);
  useEffect(() => {
    fetchCategories();
  }, [])
  const fetchCategories = async () => {
    await axios.get('https://nawyapp.com/api/category').then(({ data }) => {
      setCategories(data)

    })
  }


  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleCategoryClick = (category) => {
    if (category === 1) {
       navigate(`/choose_categories/homepage_properties`);
       setcategory(1)
    }
    if (category === 2) {
      navigate(`/choose_categories/homepage_vehicles`);
      setcategory(2)
    }
    if (category === 3) {
      navigate(`/choose_categories/homepage_order_travel`);
      setcategory(3)
    }   
  };

  return (
    <div className="bg-gray-200 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <h2 className="text-3xl font-bold text-center mb-12 text-gray-900">
          {t('category_choose')}
        </h2>
        
        <div className="">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
            {categoriesall.map((category, index) => (
              <CategoryCard
                key={index}
                id={category.id}
                name={category.name}
                image={category.image}
                description={category.description}
                handleCategoryClick={handleCategoryClick}
              />
            ))}
          </div>
          <div className="mt-12 text-center">

          </div>
        </div>
        <br />
      </div>
    </div>
  );
};

const CategoryCard = ({id, description, name, image, handleCategoryClick }) => (
  <div className="bg-white rounded-lg shadow-md overflow-hidden hover:shadow-xl transition duration-300 ease-in-out transform hover:-translate-y-1">
    <div className="p-6">
      <img src={`https://nawyapp.com/storage/${image}`} width="300px" height="300px" alt={name} className="w-16 h-16 mb-4 flex justify-center" />
      <h3 className="text-xl font-semibold mb-2 text-gray-900">{name}</h3>
      <p className="text-base p-3 text-gray-600">{description}</p>
  
        <button
        onClick={() => handleCategoryClick(id)}
          type="button"
          className="inline-flex justify-center items-center px-6 py-3 border border-transparent text-base font-medium rounded-full shadow-sm text-white bg-[#FFA500] hover:bg-[#FF8C00] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#FFA500] transition duration-150 ease-in-out">
          {name}
        </button>
    </div>
  </div>
);

export default CategoriesType;