import React, { useEffect, useState } from 'react'
import Carousel from './Carousel'
import DescriptionCarousel from './DescriptionCarousel'
import DescriptionTravelOrder from './DescriptionTravelOrder'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { useTranslation } from 'react-i18next'

const DetailsTravelOrder = () => {
  const { id } = useParams(); // احصل على id من الرابط
  const [order_travel, setorder_travel] = useState(null);

  useEffect(() => {
    fetchproperty();
  }, [])
  const fetchproperty = async () => {
    await axios.get('https://nawyapp.com/api/orders_travel/' + id).then(({ data }) => {
      setorder_travel(data)
    });
  }

  const {t} = useTranslation();

  return (
    <div className='bg-[#EBEAFA]'>
      {
        order_travel === null ?  <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
        {/* Animation Spinner */}
        <div className="flex justify-center items-center mb-5">
          <div className="w-12 h-12 border-4 border-purple-500 border-solid rounded-full border-t-transparent animate-spin"></div>
        </div>
        {/* Loading Text */}
        <div className="text-lg font-semibold text-purple-600">
          {t('lodinD')}
        </div>
      </div> :
          <div className="">
            <Carousel order_travel={order_travel} />
            <DescriptionCarousel order_travel={order_travel}  />
            <DescriptionTravelOrder order_travel={order_travel} />
          </div> 
      }
    </div>
  )
}
export default DetailsTravelOrder