import React, { useEffect, useState } from 'react'
import BackgroundB from '../Property/BackgroundInf';
import AboutBroker from './AboutBroker';
import DescriptionBroker from './DescriptionBroker';
import AllOrderBroker from './AllOrderBroker';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const BrokerServices = ({ props }) => {
  const [Des3, setDes3] = useState(0);
  const { broker_id } = useParams(); 
  const {id} = useParams();
  console.log(broker_id)

  const [username, setusername] = useState('')
  const [description, setdescription] = useState('')
  const [country, setcountry] = useState('')
  const [city, setcity] = useState('')
  const [imageName, setimage] = useState('')
  const [defaultCenter, setDefaultCenter] = useState({ lat: 0, lng: 0 });
  const [order_property,setorder_property] = useState([]);
  const [order_vehicles,setorder_vehicles] = useState();
  const imageUrl = `https://nawyapp.com/storage/${imageName}`;
  const fetchCategories = async () => {
    await axios.get('https://nawyapp.com/api/Broker/' + id,
    ).then(({ data }) => {
      setusername(data.user.username)
      setdescription(data.description)
      setDefaultCenter({
        lat: data.user.location.latitud,
        lng: data.user.location.longitude
      })
      setcountry(data.user.location.country)
      setcity(data.user.location.city)
      setimage(data.image)

    });
  } 
  useEffect(() => {
    fetchCategories();
    all_order_broker();
  }, [])


  const all_order_broker = async () => {
    const data = {
      broker_id:1
    };
    await axios.get('https://nawyapp.com/api/show_all_order/'+ id,
    ).then(({ data }) => {
      setorder_property(data.properties)
      setorder_vehicles(data.vehicles)
  
    });
  }

  const {t} = useTranslation();

  return (

    <div className='bg-[#EBEAFA]'>
      {username? 
      <div className="">
      <BackgroundB type={'Broker'} username={username} imageUrl={imageUrl} country={country} city={city} id={id} />
      <DescriptionBroker setDes={setDes3} />
      {Des3 === 0 && <AboutBroker description={description} center={defaultCenter} />}
      {Des3 === 1 && <AllOrderBroker order_property={order_property} order_vehicles={order_vehicles} />}
      </div> :  <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
        {/* Animation Spinner */}
        <div className="flex justify-center items-center mb-5">
          <div className="w-12 h-12 border-4 border-purple-500 border-solid rounded-full border-t-transparent animate-spin"></div>
        </div>
        {/* Loading Text */}
        <div className="text-lg font-semibold text-purple-600">
          {t('lodi')}
        </div>
      </div>
      }
    </div>
  )
}
export default BrokerServices