import React from "react";
import { Link } from "react-router-dom";
import image from "../../../assets/Images/airplane.png";

const importantT = ({ title, countries }) => {
  return (
    <div className=" flex flex-col items-center pb-12">
      <h1 className="text-2xl font-bold mb-4">{title}</h1>
      <div className="grid grid-cols-3 sm:grid-cols-3 lg:grid-cols-7 gap-14 pt-6">
        {countries.map((city) => (
          <Link to="">
            <div
              key={city.id}
              className="flex flex-col-2 px-4 py-2 float-left rounded-full shadow-md bg-white hover:bg-[#990033] hover:text-white"
            >
              <div className="float-left mt-[10px] hover:text-white">
                <p className="text-lg font-semibold">{city.name}</p>
              </div>
              <div className="pr-2 ml-2 mt-2">
                <img
                  src={image}
                  alt={city.name}
                  className="w-8 h-8 object-cover rounded-full mb-2"
                />
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default importantT;
