import React, { useEffect, useState } from "react";
import Img from "../assets/Images/Flat1.png";
import { useTranslation } from "react-i18next";
import axios from "axios";

function Sale({ Img, description }) {
  const [isExpanded, setIsExpanded] = useState(false);
  // دالة لتغيير حالة الوصف عند النقر
  const toggleDescription = () => {
    setIsExpanded(!isExpanded);
  };

  const { t } = useTranslation();

  const [post, setPost] = useState([]);
  const token = localStorage.getItem("token");
  useEffect(() => {
    axios
      .get("https://nawyapp.com/api/post_influncer", {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        setPost(response.data);
      })
      .catch((error) => console.error("Error fetching posts:", error));
  }, [token]);

  return (
    <div>
      <section
          className="grid sm:grid-cols-1 lg:w-[70%] lg:grid-cols-3 w-full lg:ml-64 gap-4">
          {/* الكارت الأول */}
        {post.map((ad) => (
          <div key={ad.id} className="mb-2 border-2">
            <img src={ `https://nawyapp.com/storage/${ad.content_type}` } className="w-full h-33 object-cover" alt="" />
            <div className="p-4">
              <div className="flex justify-between items-center mb-2"></div>
              <div className="flex justify-between text-xs text-gray-600 mb-4">
                <span className="flex items-center">
                  {/* إذا كان الوصف موسعًا، اعرض النص كاملاً؛ إذا لم يكن، اعرضه مقطوعًا */}
                  <p
                    className={`text-sm ${
                      isExpanded
                        ? ""
                        : "overflow-hidden text-ellipsis line-clamp-3"
                    }`}
                  >
                    {ad.description}
                  </p>
                </span>
              </div>
              {/* زر للتحكم في عرض الوصف */}
              <button
                className="text-blue-500 text-xs"
                onClick={toggleDescription}
              >
                {isExpanded ? "إخفاء الوصف" : "عرض المزيد"}
              </button>
            </div>
          </div>
        ))}
        </section>
    </div>
  );
}
export default Sale;
