import React from "react";

const DescriptionCarousel = ({ order_travel }) => {
  return (
    <div>
      <div className="bg-white">
        <p className="text-right pr-8 font-bold text-[25px]">
          {order_travel.locationfor.city} - {order_travel.locationto.city}
          <br />
          <span className="mr-4 p-2 text-[16px] text-[#800080] font-small">
            {order_travel.start_time}
          </span>
        </p>
      </div>
    </div>
  );
};
export default DescriptionCarousel;