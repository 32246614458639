import React, {useEffect, useState} from 'react'
import AboutOffice from './AboutOffice';
import OrdersTravelS from './OrdersTravelS';
import BackgroundB from '../Property/BackgroundInf';
import DescriptionOffices from './DescriptionOffices';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const   TravelOfficesServices = () => {
  const [Des3, setDes3] = useState(0);
  const {id} = useParams();

  const [username, setusername] = useState('')
  const [bio, setbio] = useState('')
  const [country, setcountry] = useState('')
  const [city, setcity] = useState('')
  const [imageName, setimage] = useState('')
  const [defaultCenter, setDefaultCenter] = useState({ lat: 0, lng: 0 });
  const imageUrl = `https://nawyapp.com/storage/${imageName}`;
 
  const fetchCategories = async () => {
    await axios.get('https://nawyapp.com/api/Travel_officee/'+ id,
    ).then(({ data }) => {
      console.log(data)
      setusername(data.user.username)
      setbio(data.bio)
      setDefaultCenter({
        lat: data.user.location.latitud,
        lng: data.user.location.longitude
      })
      setcountry(data.user.location.country)
      setcity(data.user.location.city)
      setimage(data.image)
    });
  }
  useEffect(() => {
    fetchCategories();
  }, [])

  return (
    
    <div className='bg-[#EBEAFA]'>
   <BackgroundB type={'Travel_officee'} username={username} imageUrl={imageUrl} country={country} city={city} id={id} />
      <DescriptionOffices setDes={setDes3}/>
      {Des3 === 0 && <AboutOffice bio={bio} location={defaultCenter} /> }
      {Des3 === 1 && <OrdersTravelS id={id}/> }
      <br></br>
    </div>
  )
}

export default TravelOfficesServices