import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaHeart, FaMapMarkerAlt, FaBed, FaBath, FaRulerCombined, FaSearch, FaKey, FaBuilding, FaBell } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { MdLocationOn } from "react-icons/md";
import axios from "axios";

const Cards = ({ property, fetchpropertyallow_buy_or_sell, show, address }) => {

  const { i18n } = useTranslation();

  const currentLanguage = i18n.language;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const title = t("details_header");
  const bath = t("des_property_room");
  const room = t("details_header");
  const detailsproperty = (id) => {
    navigate(`/details_cards_property/${id}`);
  };

  useEffect(() => {
    // fetchCategories();
  }, []);
  const [country, setcountry] = useState("");
  const [city, setcity] = useState("");
  const token = localStorage.getItem("token");
  // const fetchCategories = async () => {
  //   await axios
  //     .get("https://nawyapp.com/api/Myprofile", {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     })
  //     .then(({ data }) => {
  //       setcountry(data.location.country);
  //       setcity(data.location.city);
  //     });
  // };

  const [searchQuery, setSearchQuery] = useState("");
  const filteredCards = property.filter((card) => {
    const search = searchQuery.toLowerCase();
    const matchesSearch =
      (card.price && card.price.toString().includes(searchQuery)) ||
      (card.property_type &&
        card.property_type.name_en &&
        card.property_type.name_en.toLowerCase().includes(search)) ||
      (card.property_type &&
        card.property_type.name_ar &&
        card.property_type.name_ar.toLowerCase().includes(search)) ||
      // (card.location &&
      //   card.location.country &&
      //   card.location.country.toLowerCase().includes(search)) ||
      (card.location &&
        card.location.city &&
        card.location.city.toLowerCase().includes(search)) ||
      (card.allow_buy_or_sell &&
        card.allow_buy_or_sell.toLowerCase().includes(search)) ||
      (card.square_footage &&
        card.square_footage.toString().includes(searchQuery)) ||
      (card.number_of_bathroom &&
        card.number_of_bathroom.toString().includes(searchQuery)) ||
      (card.number_of_rooms &&
        card.number_of_rooms.toString().includes(searchQuery));
    return matchesSearch;
  });

 

  return (
    <div className="bg-[#e5c3f3] py-10 px-4 sm:px-6 lg:px-8">
    {show === 1 ?
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 items-center mb-10">
        {/* Filter */}
        <div className="w-[60%]">
          <div className="bg-white rounded-full shadow-md flex justify-between items-center p-2">
            <button
              onClick={() => fetchpropertyallow_buy_or_sell("أجار")}
              type="button"
            >
              <FilterButton icon={<FaKey />} text={t('navbar_rent')} />
            </button>
            <button
              onClick={() => fetchpropertyallow_buy_or_sell("شراء")}
              type="button"
            >
              <FilterButton icon={<FaBuilding />} text={t('navbar_buying')} />
            </button>
          </div>
        </div>

        {/* Location */}
        <div className="w-full">
          <div className="relative">
            <select className="w-full bg-white border border-gray-300 text-gray-900 text-sm rounded-full py-3 px-4 pr-8 appearance-none focus:outline-none focus:ring-2 focus:ring-purple-600">
              <option selected className="text-center">
               {address}
              </option>
            </select>
            <MdLocationOn
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400"
              size={20}
            />
          </div>
        </div>

        {/* Notification */}
        <div className="w-full md:w-auto flex justify-center">
            <Link to="/Notifications">
              <button className="bg-white border-2 border-green-400 rounded-full p-2 hover:bg-green-100 transition-colors duration-200">
                <FaBell className="text-gray-600 text-xl" />
              </button>
            </Link>
          </div>

        {/* Search */}
        <div className="w-full col-span-1 md:col-span-2 lg:col-span-1">
          <div className="relative">
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full bg-white border border-gray-300 text-gray-900 text-sm rounded-full py-3 px-4 pr-12 pl-12 focus:outline-none focus:ring-2 focus:ring-purple-600"
              placeholder={t('all_order_input_search')}
            />
            <FaSearch
              className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-400"
              size={20}
            />
            <div className="absolute left-2 top-1/2 transform -translate-y-1/2 flex"></div>
          </div>
        </div>
      </div>
     : ""}
     
      <h2 className="text-2xl font-bold text-right mb-6">{t("page_best")}</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {filteredCards.length === 0 ? (
          // عرض الرسالة إذا لم يتم العثور على أي نتائج
          <p className="p-5 m-5 font-bold justify-center text-center text-2xl text-[#990033]">
            {t("msg")}
          </p>
        ) : (
          filteredCards.map((card) => (
            <Card
              key={card.id}
              card={card}
              title={title}
              detailsproperty={detailsproperty}
              bath={bath}
              room={room}
              currentLanguage={currentLanguage}
            />
          ))
        )}
      </div>
    </div>
  );
};

const Card = ({ card, title, bath, room, detailsproperty, currentLanguage }) => (
  <div className="bg-white rounded-lg shadow-lg overflow-hidden">
    <div className="relative">
      <img
        src={`https://nawyapp.com/storage/${card.image?.url_file}`}
        className="w-full h-48 object-cover"
      />
      <button className="absolute top-3 right-3 bg-white p-2 rounded-full shadow-md hover:bg-gray-100 transition duration-300 ease-in-out">
        <FaHeart className="text-purple-600" />
      </button>
    </div>
    <div className="p-4">
      <div className="flex justify-between items-center mb-2">
        <span className="text-purple-600 font-bold text-xl">{card.price} $</span>
        <h3 className="text-xl font-bold">{currentLanguage === "ar"
                        ? card.property_type.name_ar
                        : card.property_type.name_en} - {card.allow_buy_or_sell}</h3>
      </div>
      {/* <p className="text-gray-600 text-right flex items-center justify-end mb-4">
        <FaMapMarkerAlt className="ml-1" /> {card.location.country} -{" "}
        {card.location.city}
      </p> */}
      <div className="flex justify-between text-sm text-gray-600 mb-4">
        <span className="flex items-center">
          <FaRulerCombined className="mr-1" /> {card.square_footage} m
          <sup>2</sup>
        </span>
        <span className="flex items-center">
          <FaBath className="mr-1" /> {card.number_of_bathroom} {bath}
        </span>
        <span className="flex items-center">
          <FaBed className="mr-1" /> {card.number_of_rooms} {room}
        </span>
      </div>
      <button
        onClick={() => detailsproperty(card.id)}
        className="block w-full text-center bg-gray-100 hover:bg-gray-200 text-purple-600 font-medium py-2 rounded transition duration-300 ease-in-out"
      >
        {title}
      </button>
    </div>
  </div>
);

const FilterButton = ({ icon, text }) => (
  <button className="flex items-center space-x-2 px-4 py-2 rounded-full hover:bg-purple-100 transition-colors duration-200">
    {icon}
    <span>{text}</span>
  </button>
);
export default Cards;
