

import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GoogleMap, LoadScript, Marker, InfoWindow, Autocomplete } from "@react-google-maps/api";
import axios from "axios";
import { IoIosSearch } from "react-icons/io";
import { useTranslation } from "react-i18next";

const MapComponent = () => {

  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [pricefor, setPricefor] = useState(0)
  const [priceto, setPriceto] = useState(0)
  const [message,setmessage] = useState(false)
  const [availibility_date, setavailibility_date] = useState()

  const currentLanguage = i18n.language;




  // const [categoriesall, setCategories] = useState([]);
  // useEffect(() => {
  //   fetchCategories();
  // }, [])
  // const fetchCategories = async () => {
  //   await axios.get('https://nawyapp.com/api/property-types').then(({ data }) => {
  //     setCategories(data)
  //   });
  // }
  const token = localStorage.getItem('token');

  const [categoriesall, setPropertyTypes] = useState([]);

  useEffect(() => {
    // استدعاء API لجلب أنواع العقارات بناءً على اللغة المختارة
    const fetchPropertyTypes = async () => {
      try {
        const response = await axios.get('https://nawyapp.com/api/property-types', {
          params: {
            locale: currentLanguage, // إرسال اللغة المختارة إلى API
          },
        });
        setPropertyTypes(response.data); // حفظ البيانات المستلمة من API
      } catch (error) {
        console.error('Error fetching property types:', error);
      }
    };

    fetchPropertyTypes();
  }, []);


  const addmessage = async () => {
    const data={
      pricefor:pricefor,
      priceto:priceto,
      room: bedrooms,
      bathroom: bathrooms,
      kitchen: kitchens,
      windows: windows
   
    };
    await axios.post('http://143.198.166.228/api/Buy',data,
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }
    )
   navigate('/share')
  }








  const sortproperty = async (id) => {

    await axios.get('https://nawyapp.com/api/sortproperty/' + id).then(({ data }) => {
      setproperties(data)
    });
  }

  const [selectedProperty, setSelectedProperty] = useState(null);
  const [mapCenter, setMapCenter] = useState({ lat: 24.7136, lng: 46.6753 }); // المركز الافتراضي للخريطة
  const navigate = useNavigate();
  const [description, setdescription] = useState('')

  const autocompleteRef = useRef(null); // مرجع للـ Autocomplete
  const [serarch, setsearch] = useState(false)
  const [showmodel, setshowmodel] = useState(false)

  const mapStyles = {
    height: "100vh",
    width: "100%"
  };
  const [properties, setproperties] = useState([])
  const [bedrooms, setBedrooms] = useState(1);
  const [bathrooms, setBathrooms] = useState(1);
  const [kitchens, setKitchens] = useState(1);
  const [livingRooms, setLivingRooms] = useState(1);
  const [windows, setWindow] = useState(1);
  const [streets, setStreets] = useState(1);
  const [Balcons, setBalcons] = useState(1);
  const [safety_rank, setsafety_rank] = useState(1);

  const detailsproperty = (id) => {
    navigate(`/details_cards_property/${id}`);
  }



  const handleIncrement = (setter) => () => setter((prev) => prev + 1);
  const handleDecrement = (setter) => () =>
    setter((prev) => (prev > 0 ? prev - 1 : 0));

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };


  const api_properties = async () => {
    await axios.get('https://nawyapp.com/api/property',
    ).then(({ data }) => {
      setproperties(data)
    });
  }
  useEffect(() => {
    api_properties();
  }, [])

  const handlePlaceChanged = () => {
    const place = autocompleteRef.current.getPlace();
    if (place.geometry) {
      setMapCenter({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
    }
  };

  return (
    <div className="">
      <div className="flex flex-wrap justify-center gap-2 mb-4 bg-gray-100 p-4 rounded-lg shadow">
        {/* <button onClick={toggleLanguage}> 
          {language === 'en' ? 'Arabic' : 'English'}
        </button> */}
        <button onClick={() => setshowmodel(true)} className='w-40  bg-[#FFA500] rounded-2xl m-2 text-white'>
        {t('Request_your_property')} 
        </button>
        {categoriesall.map((type) =>
          <button onClick={() => sortproperty(type.id)} type="button" className="p-3 bg-white hover:bg-[#800080] rounded-2xl m-2 hover:text-white">{type.name}</button>
        )}

      </div>
      <div className="bg-gray-100">
      <LoadScript googleMapsApiKey="AIzaSyCN1ldCkq6kz0PbBE6Y2eUTLYIGyNfcQxY" libraries={["places"]}>
        <Autocomplete
          onLoad={ref => (autocompleteRef.current = ref)}
          onPlaceChanged={handlePlaceChanged}
        >
          <input
            type="text"
            placeholder={t('Search')}
            className="w-full p-2 rounded-lg shadow-sm focus:ring-2  focus:ring-purple-500 focus:outline-none"
            style={{
              boxSizing: "border-box",
              height: "40px",
              fontSize: "16px",
              width: "60rem",
              position: "center",
              left: "100%",
              // transform: "translateX(-50%)",
              top: "-40px",
              // zIndex: "1000"
            }}
          />
        </Autocomplete>

        <GoogleMap
          mapContainerStyle={mapStyles}
          zoom={10}
          center={mapCenter} // تحريك الخريطة بناءً على الموقع الجديد
        >

          {properties.length === 0 ? '' :
            properties.map((property) => (
              <Marker
                key={property.id}
                position={{ lat: property.location.latitud, lng: property.location.longitude }}
                onClick={() => setSelectedProperty(property)}
              />
            ))}
          {selectedProperty && (
            <InfoWindow
              position={{ lat: selectedProperty.location.latitud, lng: selectedProperty.location.longitude }}
              onCloseClick={() => setSelectedProperty(null)}
            >
              <div>
                <h2>{selectedProperty.price}</h2>
                <p>{selectedProperty.allow_buy_or_sell}</p>
                <button onClick={() => detailsproperty(selectedProperty.id)}> عرض تفاصيل العقار</button>

              </div>
            </InfoWindow>
          )}

        </GoogleMap>
      </LoadScript>
      </div>
      {showmodel && (
     <div className="fixed inset-0 z-50 flex items-center justify-center min-h-screen bg-gray-500 bg-opacity-75 overflow-y-auto">
     <div className="bg-white rounded-lg shadow-xl p-6 w-full max-w-lg">
       <h3 className="text-2xl font-semibold mb-4 text-center"> {t('Request_your_property')} </h3>
       <div className="max-h-[80vh] overflow-y-auto">
              <div className="flex">
                <h3 className="text-xl font-semibold ">من</h3>
                <input
                  type="number"

                  value={pricefor}
                  onChange={(e) => { setPricefor(e.target.value) }}
                  className="w-[63%] border text-black rounded-2xl focus:text-white-2 py-5 px-3 text-right focus:outline-none focus:border-purple-800 transition-colors"
                  placeholder="300"
                />
                <span className="ml-2"></span>
              </div>
              <br />

              <div className="flex">
                <h3 className="text-xl font-semibold">إلى</h3>
                <input
                  type="number"
                  value={priceto}
                  onChange={(e) => { setPriceto(e.target.value) }}
                  className="w-[63%] border text-black rounded-2xl focus:text-white-2 py-5 px-3 text-right focus:outline-none focus:border-purple-800 transition-colors"
                  placeholder="300"
                />
                <span className="ml-2"></span>
              </div>
              <br />
              <div className="col-span-1 md:col-span-2 text-right">
              <h3 className="text-xl font-semibold mb-[-35px] inline-block">
                {t('add_property_featu')}
              </h3>
              <div className="flex flex-wrap gap-2 p-2 mr-[20px]">
                <div className="w-[60%] flex items-center bg-white rounded-2xl focus:bg-[#800080] focus:text-white ml-[17px]">
                  <button
                    type="button"
                    className="bg-red-500 text-white py-1 px-3 rounded-2xl focus:bg-[#800080] focus:text-white m-4"
                    onClick={handleDecrement(setBedrooms)}
                  >
                    -
                  </button>
                  <span className="mx-2">{bedrooms}</span>
                  <button
                    type="button"
                    className="bg-green-500 text-white py-1 px-3 rounded-2xl focus:bg-[#800080] focus:text-white m-4"
                    onClick={handleIncrement(setBedrooms)}
                  >
                    +
                  </button>
                  <span className="pl-[44px] mr-8">{t('details_rooms')}</span>
                </div>
              </div>

              <div className="flex flex-wrap gap-2 p-2 mr-[20px]">
                <div className="w-[60%] flex items-center bg-white rounded-2xl focus:bg-[#800080] focus:text-white ml-[17px]">
                  <button
                    type="button"
                    className="bg-red-500 text-white py-1 px-3 rounded-2xl focus:bg-[#800080] focus:text-white m-4"
                    onClick={handleDecrement(setBathrooms)}
                  >
                    -
                  </button>
                  <span className="mx-2">{bathrooms}</span>
                  <button
                    type="button"
                    className="bg-green-500 text-white py-1 px-3 rounded-2xl focus:bg-[#800080] focus:text-white m-4"
                    onClick={handleIncrement(setBathrooms)}
                  >
                    +
                  </button>
                  <span className="pl-[44px] mr-8">{t('add_property_bathroom')}</span>
                </div>
              </div>

              <div className="flex flex-wrap gap-2 p-2 2 mr-[20px]">
                <div className="w-[60%] flex items-center bg-white rounded-2xl focus:bg-[#800080] focus:text-white ml-[17px]">
                  <button
                    type="button"
                    className="bg-red-500 text-white py-1 px-3 rounded-2xl focus:bg-[#800080] focus:text-white m-4"
                    onClick={handleDecrement(setKitchens)}
                  >
                    -
                  </button>
                  <span className="mx-2">{kitchens}</span>
                  <button
                    type="button"
                    className="bg-green-500 text-white py-1 px-3 rounded-2xl focus:bg-[#800080] focus:text-white m-4"
                    onClick={handleIncrement(setKitchens)}
                  >
                    +
                  </button>
                  <span className="pl-[44px] mr-8">{t('add_property_kitchen')}</span>
                </div>
              </div>

             

              <div className="flex flex-wrap gap-2 p-2 mr-[20px]">
                <div className="w-[60%] flex items-center bg-white rounded-2xl focus:bg-[#800080] focus:text-white ml-[17px]">
                  <button
                    type="button"
                    className="bg-red-500 text-white py-1 px-3 rounded-2xl focus:bg-[#800080] focus:text-white m-4"
                    onClick={handleDecrement(setWindow)}
                  >
                    -
                  </button>
                  <span className="mx-2">{windows}</span>
                  <button
                    className="bg-green-500 text-white py-1 px-3 rounded-2xl focus:bg-[#800080] focus:text-white m-4"
                    onClick={handleIncrement(setWindow)}
                    type="button"
                  >
                    +
                  </button>
                  <span className="pl-[44px] mr-8">{t('add_property_window')}</span>
                </div>
              </div>
          

            
            </div>

              <label className="text-xl block mb-2 font-semibold text-black text-right">
                {t('add_property_delivery_details')}
              </label>
              <div className="flex justify-center items-centr p-4">
                <textarea

                  className="text-right w-[100%] h-24 p-4 border-2 border-gray-300 rounded-lg focus:outline-none focus:border-purple-800 transition-colors"
                  placeholder={t('add_property_delivery_details2')}
                  value={description}
                  onChange={(e) => { setdescription(e.target.value) }}
                />
              </div>
            </div>
            <div className="flex justify-center mt-6">
              <button
                className="bg-[#FFA500] text-white py-2 px-6 rounded-md shadow-md hover:bg-[#800080] transition duration-300"
                onClick={ addmessage}
              >
                {t('profile_send')}
              </button>
              <button
                className="ml-4 bg-white text-gray-700 py-2 px-6 rounded-md border border-gray-300 shadow-md hover:bg-gray-50 transition duration-300"
                onClick={() => setshowmodel(false)}
              >
                {t('profile_close')}
              </button>
            </div>
          </div>
        </div>
      )}

    </div>
  );
};

export default MapComponent;
