import React, { useEffect, useState } from "react";
import CardsTravel from "./asset_comontants/CardsTravel";
import Storyadmin from "./asset_comontants/story_admin";
import ImportantT from "./asset_comontants/importantT";
import ImportantOffices from "./asset_comontants/ImportantOffices";
import { useTranslation } from 'react-i18next';
import axios from "axios";

const PageOrderTravel = () => {

  const [Travel_officee,setTravel_officee] = useState([]);
  const [location, setLocation] = useState({ latitude: null, longitude: null  });


  useEffect(() => {
    fetchshowproperty();
    // fetchCategories();
    fetchTravel_officee();
    fetchcountries();
  }, [])

  // const fetchCategories = async () => {
  //   await axios.get('https://nawyapp.com/api/Myprofile',
  //     {
  //       headers: {
  //         'Authorization': `Bearer ${token}`
  //       }
  //     }
  //   ).then(({ data }) => {
  //     setcountry(data.location.country)
  //     setcity(data.location.city)
  //       });
  // }



  const {t} = useTranslation();
  const [order_travel,setorder_travel] = useState([]);
  const [countries,setcountries] = useState([]);
  const fetchshowproperty = async () => {
    await axios.get('https://nawyapp.com/api/show_order_travel',
    ).then(({ data }) => {
      setorder_travel(data)
    });
  }


  const fetchTravel_officee = async () => {
    await axios.get('https://nawyapp.com/api/Travel_officee',
    ).then(({ data }) => {
      setTravel_officee(data)
    });
  }

  const fetchcountries = async () => {
    await axios.get('https://nawyapp.com/api/countries/create',
    ).then(({ data }) => {
      setcountries(data)
    });
  }

  return (
    <div className=" bg-[#EBEAFA]">
      {
        order_travel===0?  <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
        {/* Animation Spinner */}
        <div className="flex justify-center items-center mb-5">
          <div className="w-12 h-12 border-4 border-purple-500 border-solid rounded-full border-t-transparent animate-spin"></div>
        </div>
        {/* Loading Text */}
        <div className="text-lg font-semibold text-purple-600">
          {t('lodinT')}
        </div>
      </div> :
        <div className="">
        <Storyadmin />
        <CardsTravel
          order_travel={order_travel}
          title={t('pageorder_title')}
          isSpecial={true}
        />
        <ImportantOffices Travel_officee={Travel_officee}  title={t('pageoeder_travel')} /><br></br><br></br><br></br><br></br>
        <ImportantT countries={countries} title={t('pageorder_d')} />
        </div> 
      }
    </div>
  );
};

export default PageOrderTravel;