import React from "react";
import  { useTranslation } from "react-i18next";
import  { FaCcVisa, FaMoneyBillAlt, FaPaypal, FaShieldAlt, FaCarCrash, FaWrench, FaLightbulb, FaCloud, FaTint, FaCarSide, FaWindowRestore, FaPalette, FaBuilding,
         FaTachometerAlt, FaRoad, FaGasPump, FaHorseHead, FaChair, FaCogs, FaCar, FaRegIdCard, FaFileContract, FaMoneyBillWave, FaFan, FaWind, FaCompactDisc, FaUsb, FaBluetooth,
        } from "react-icons/fa";
import  { Link } from "react-router-dom";
import  { LuBedDouble } from "react-icons/lu";

const DescriptionVehicle = ({car}) => {

  const {t} = useTranslation();
  console.log(car)
  
  const featuresExternal = [
    { key: 'has_been_in_accidents', label: t('Has_been_in_accidents'), value: car.has_been_in_accidents, icon: <FaCarCrash className="text-[#800080] text-3xl" /> },  
    { key: 'Spare_tire', label: t('Spare_tire'), value: car.Spare_tire, icon: <FaWrench className="text-[#800080] text-3xl" /> }, 
    { key: 'lights', label: t('Lights'), value: car.lights, icon: <FaLightbulb className="text-[#800080] text-3xl"/> }, 
    { key: 'Fog_lights', label: t('Fog_lights'), value: car.Fog_lights, icon: <FaCloud className="text-[#800080] text-3xl" /> },  
    { key: 'tinted_windows', label: t('Tinted_windows'), value: car.tinted_windows, icon: <FaTint className="text-[#800080] text-3xl" /> }, 
    { key: 'electric_mirrors', label: t('Electric_mirrors'), value: car.electric_mirrors, icon: <FaCarSide className="text-[#800080] text-3xl" /> }, 
    { key: 'panorama', label: t('Panorama_roof'), value: car.panorama, icon: <FaWindowRestore className="text-[#800080] text-3xl" /> },  
    { key: 'original_color', label: t('Original_color'), value: car.original_color, icon: <FaPalette className="text-[#800080] text-3xl" /> }, 
    { key: 'structure_type', label: t('Structure_Type'), value: car.structure_type, icon: <FaBuilding className="text-[#800080] text-3xl" /> }, 
  ];

  const featuresInterior = [
    { key: 'Interior_color', label: t('Interior_color'), value: <div style={{ backgroundColor: car.Interior_color, width: '130px', height: '130px', borderRadius: '50%', border: '1px solid #000' }}></div>, icon: <FaPalette className="text-[#800080] text-3xl" /> },
    { key: 'air_conditioner', label: t('Air_Conditioner'), value: car.air_conditioner, icon: <FaFan className="text-[#800080] text-3xl" /> }, 
    { key: 'Airbags', label: t('Airbags'), value: car.Airbags, icon: <FaWind className="text-[#800080] text-3xl" /> },                    
    { key: 'Electric_windows', label: t('Electric_windows'), value: car.Electric_windows, icon: <FaWindowRestore className="text-[#800080] text-3xl" /> },
    { key: 'CD', label: t('CD Player'), value: car.CD, icon: <FaCompactDisc className="text-[#800080] text-3xl" /> },  
    { key: 'usb', label: t('USB'), value: car.usb, icon: <FaUsb className="text-[#800080] text-3xl" /> },                 
    { key: 'Electric_seats', label: t('Electric_seats'), value: car.Electric_seats, icon: <FaChair className="text-[#800080] text-3xl" /> },
  ];

  const technicalSpecifications = [
    { key: 'Touch_screen', label: t('Touch_Screen'), value: car.Touch_screen, icon: <FaFan className="text-[#800080] text-3xl" /> }, 
    { key: 'apple_carplay', label: t('Apple_Carplay'), value: car.apple_carplay, icon: <FaWind className="text-[#800080] text-3xl" /> },                    
    { key: 'rear_camera', label: t('Rear_Camera'), value: car.rear_camera, icon: <FaWindowRestore className="text-[#800080] text-3xl" /> },
    { key: 'media_screen', label: t('Media_Screen'), value: car.media_screen, icon: <FaCompactDisc className="text-[#800080] text-3xl" /> },  
    { key: 'bluetooth', label: t('Bluetooth'), value: car.bluetooth, icon: <FaBluetooth className="text-[#800080] text-3xl" /> },                 
    { key: 'cruise_control', label: t('Cruise_Control'), value: car.cruise_control, icon: <FaChair className="text-[#800080] text-3xl" /> },
  ];

  return (
    <div className="container p-[80px] rounded-lg">
       <h2 className="text-2xl font-bold text-center mb-12">{t('des_vehicle_top')}</h2>
        <div className="" >
        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 gap-4 mb-12">
          {[
            {
              icon: <FaShieldAlt className="text-[#800080] text-3xl" />,
              value: `${car.status_car === 'مستعملة' ? t('add1_vehicle_used'): t('add1_vehicle_new')}`,
              label: t("des_vehicle1"),
            },
            {
              icon: <FaTachometerAlt className="text-[#800080] text-3xl" />,
              value: `${car.mileage}`,
              label: t("des_vehicle2"),
            },
            {
              icon: <LuBedDouble className="text-[#800080] text-3xl" />,
              value: `${car.year}`,
              label: t("des_vehicle3"),
            },
            {
              icon: <FaGasPump className="text-[#800080] text-3xl" />,
              value: `${car.fuel_type === 'بنزين' ? t('add1_vehicle_fuel1') : car.fuel_type === 'مازوت' ? t('add1_vehicle_fuel2'): t('add1_vehicle_fuel3')}`,
              label: t("des_vehicle4"),
            },
            {
              icon: <FaChair className="text-[#800080] text-3xl" />,
              value: `${car.number_of_seats}`,
              label: t("des_vehicle5"),
            },
            {
              icon: <FaHorseHead className="text-[#800080] text-3xl" />,
              value: `${car.horsepower}`,
              label: t("des_vehicle6"),
            },
            {
              icon: <FaRoad className="text-[#800080] text-3xl" />,
              value: `${car.Motion_vector === 'عادي' ? t('add1_vehicle_trans2') : t('add1_vehicle_trans1')}`,
              label: t("des_vehicle7"),
            },
            {
              icon: <FaCogs className="text-[#800080] text-3xl" />,
              value: `${car.engine_size}`,
              label: t("des_vehicle8"),
            },
            {
              icon: <FaRegIdCard className="text-[#800080] text-3xl" />,
              value: `${car.license_status}`,
              label: t("add5_vehicle_lic"),
            },
            {
              icon: <FaFileContract className="text-[#800080] text-3xl" />,
              value: `${car.insurance_status}`,
              label: t("add5_vehicle_insur"),
            },
            {
              icon: <FaMoneyBillWave className="text-[#800080] text-3xl" />,
              value: `${car.taxes === 1 ? t("yes") : t("no")}`,
              label: t("add5_vehicle_tax"),
            },
            {
              icon: <FaCar className="text-[#800080] text-3xl" />,
              value: `${car.service_history}`,
              label: t("add5_vehicle_servi"),
            },
          ].map((item, index) => (
            <div
              key={index}
              className="bg-white border rounded-xl shadow-xl p-4 text-center"
            >
              <div className="flex justify-center mb-2">{item.icon}</div>
              <div className="text-xl font-semibold">{item.value}</div>
              <div>{item.label}</div>
            </div>
          ))}
        </div>

          <h2 className="text-2xl font-bold text-center mb-12">{t('add3_vehicle_featu')}</h2>
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mb-[60px]">
            {featuresExternal.map((item, index) => (
              item.value === 1 && (
                <div key={index} className="bg-white border rounded-xl shadow-xl p-4 text-center">
                  <div className="flex justify-center mb-2">{item.icon}</div>
                  <div className="text-xl font-semibold">{item.label}</div>
                </div>
              )
            ))}
          </div>

          <h2 className="text-2xl font-bold text-center mb-12">{t('add1_vehicle_featu')}</h2>
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mb-[60px]">
            {featuresInterior.map((item, index) => (
              item.value === 1 && (
                <div key={index} className="bg-white border rounded-xl shadow-xl p-4 text-center">
                  <div className="flex justify-center mb-2">{item.icon}</div>
                  <div className="text-xl font-semibold">{item.label}</div>
                </div>
              )
            ))}
          </div>

          <h2 className="text-2xl font-bold text-center mb-12">{t('add4_vehicle_tech')}</h2>
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mb-[60px]">
            {technicalSpecifications.map((item, index) => (
              item.value === 1 && (
                <div key={index} className="bg-white border rounded-xl shadow-xl p-4 text-center">
                  <div className="flex justify-center mb-2">{item.icon}</div>
                  <div className="text-xl font-semibold">{item.label}</div>
                </div>
              )
            ))}
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mb-12">
            <div className="col-span-3 text-center">
              <span className="block font-bold text-[24px] mb-4">{t('description')}</span>
              <span className="block p-3 m-3 text-[20px] text-center max-w-1xl mx-auto break-words">{car.description}</span>
            </div>
          </div>

          <h2 className="text-2xl font-bold text-center mb-8">{t('des_property_pay')}</h2>
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mb-12">
            {car.payment_option === 'فيزا كارد' ?
              <div className="bg-white border rounded-xl shadow-xl p-4 text-center">
                <div className="flex justify-center mb-2"> <FaCcVisa className="text-[#800080] text-3xl" /></div>
                <div> {t('des_property_visa')}</div>
              </div>
              : car.payment_option === ' كاش' ?
                <div className="bg-white border rounded-xl shadow-xl p-4 text-center">
                  <div className="flex justify-center mb-2"> <FaMoneyBillAlt className="text-[#800080] text-3xl" /></div>
                  <div> {t('des_property_cash')}</div>
                </div> :
                <div className="bg-white border rounded-xl shadow-xl p-4 text-center">
                  <div className="flex justify-center mb-2"> <FaPaypal className="text-[#800080] text-3xl" /></div>
                  <div> {t('des_property_paypal')}</div>
                </div>}
          </div>

          <div className="text-center bg-white p-6 rounded-xl shadow-xl">
            <div className="mb-4">
              <span className="font-bold">{t('des_proprty_price')}</span>
              <span className="text-2xl ml-2"><span className="text-blue-600">${car.price}$</span></span>
            </div>
            <Link to=""><button className="bg-[#FFA500] hover:bg-amber-500 text-white w-full sm:w-auto px-8 py-3 rounded-full font-medium text-xl">{t('des_proprty_now')}</button></Link>
          </div>
        </div>
    </div>
  );
};
export default DescriptionVehicle;