import React, { useEffect, useState } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer} from "recharts";
import { useTranslation } from "react-i18next";
import axios from "axios";

const StatisticsBroker = ({ brokerId }) => {
  const { t } = useTranslation();
  const [adsData, setAdsData] = useState([]);
  const [adsData2, setAdsData2] = useState([]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `https://nawyapp.com/api/broker-ads-by-month`,{
          headers: { 'Authorization': `Bearer ${token}` } }
      );
      const data = response.data;
      // تجهيز البيانات للمخطط
      const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov", "Dec",];
      const formattedData = months.map((month, index) => {
        const monthData = data.find((item) => item.month === index + 1);
        return { month: month, ads: monthData ? monthData.count : 0 }; });
      setAdsData(formattedData);
    } catch (error) {console.error("Error fetching broker request ads data", error);}
  };
  useEffect(() => {
    fetchData();
  }, [brokerId]);

  const fetchData2 = async () => {
    try {
      const response = await axios.get(
        `https://nawyapp.com/api/broker-ads-by-month2`,{
          headers: { 'Authorization': `Bearer ${token}`  } }
      );
      const data2 = response.data;
      // تجهيز البيانات للمخطط
      const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov", "Dec",];
      const formattedData2 = months.map((month, index2) => {
        const monthData = data2.find((item) => item.month === index2 + 1);
        return { month: month, ads2: monthData ? monthData.count : 0 };});
      setAdsData2(formattedData2);
    } catch (error) { console.error("Error fetching broker ads data", error);}
  };
  useEffect(() => {
    fetchData2();
  }, [brokerId]);

  const [totalTransactions, setTotalTransactions] = useState(0);
  const [totalAds, setTotalAds] = useState(0);
  const [totalAds2, setTotalAds2] = useState(0);
  const [earnings, setEarnings] = useState({ totalCommission: 0 });

  const [orders,setorders] = useState([]);
  const [orders2,setorders2] = useState([]);
  const [commission, setCommission] = useState(null);
  const token = localStorage.getItem("token");
  useEffect(() => {
    fetchCategories();
  }, [])
  const fetchCategories = async () => {
    await axios.get('https://nawyapp.com/api/RequestAdBroker',
      {headers: { 'Authorization': `Bearer ${token}` }}
    ).then(({ data }) => { setorders(data);});
  }

  useEffect(() => {
    fetchCategories2();
  }, [])
  const fetchCategories2 = async () => {
    await axios.get('https://nawyapp.com/api/Ad_broker',
      {
        headers: {'Authorization': `Bearer ${token}` }}
    ).then(({ data }) => {setorders2(data); });
  }

  useEffect(() => {
    axios
    .get('https://nawyapp.com/api/Rejected-ads',{
      headers: { 'Authorization': `Bearer ${token}`}
    })
      .then(response => { setCommission(response.data);})
      .catch(error => {
        console.error("There was an error fetching commissions!", error);
      });
  }, []); // يتم تنفيذ هذا الاستدعاء مرة واحدة عند تحميل المكون

  useEffect(() => {
    // استدعاء API لجلب عدد التحويلات المالية
    axios.get('https://nawyapp.com/api/broker/transactions', {
      headers: {'Authorization': `Bearer ${token}`}
    })
    .then((response) => {
      // تأكد من أن البيانات تتطابق مع الهيكلية المتوقعة
      const { totalTransactionsPending, totalAds, totalAds2, totalCommission } = response.data;
      // تعيين القيم في الحالة
      setTotalTransactions(totalTransactionsPending);
      setTotalAds(totalAds);
      setTotalAds2(totalAds2);
      setEarnings({ totalCommission: totalCommission });
    })
    .catch((error) => console.error("Error fetching transactions:", error));
  }, [token]); // تأكد من إدراج `token` في قائمة الاعتماد على التحديث

  return (
    <div className="p-4 bg-[#EBEAFA]">
      <div className="grid grid-cols-1 sm:grid-cols-3 p-12 gap-4">
        <div className="bg-white p-6 rounded-lg shadow-md text-center">
          <h2 className="text-gray-700 text-xl font-medium text-right">{t("statis_b_total")}</h2>
          <p className="text-gray-900 text-3xl pb-2 font-bold text-left">${earnings.totalCommission.toFixed(2)}</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-md text-center">
          <h2 className="text-gray-700 text-xl font-medium text-right">{t("statis_b_profit")}</h2>
          <p className="text-gray-900 text-3xl pb-2 font-bold text-left">{totalAds}</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-md text-center">
          <h2 className="text-gray-700 text-xl font-medium text-right">{t("statis_b_profit0")}</h2>
          <p className="text-gray-900 text-3xl pb-2 font-bold text-left">{totalAds2}</p>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-md text-center">
          <h2 className="text-gray-700 text-xl font-medium text-right">{t("statis_b_conve")}</h2>
          <p className="text-gray-900 text-3xl pb-2 font-bold text-left">{totalTransactions}</p>
        </div>
      </div>
       {/* Request Orders  */}
      <div className="grid grid-cols-1 sm:grid-cols-2 p-4 gap-4">
        <div className="h-[400px] mt-[45px] p-4 bg-white">
          <ResponsiveContainer width="100%" height={400}>
            <LineChart data={adsData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }} >
              <CartesianGrid strokeDasharray="3 3" /><XAxis dataKey="month" /> <YAxis /> <Tooltip /> <Legend />
              <Line type="monotone" dataKey="ads" stroke="#8884d8" activeDot={{ r: 8 }} />
            </LineChart>
          </ResponsiveContainer>
        </div>
        <div>
          <div className="bg-white rounded-lg shadow-md p-4">
            <h2 className="text-xl font-semibold mb-4 text-right">{t("statis_b_compl")}</h2>
            <table className="min-w-full">
              <thead>
                <tr>
                  <th className="px-4 py-2">{t("statis_b_det")}</th>
                  <th className="px-4 py-2">{t("statis_b_profit2")}</th>
                  <th className="px-4 py-2">{t("statis_b_profit3")}</th>
                  <th className="px-4 py-2">{t("statis_b_price2")}</th>
                  <th className="px-4 py-2">{t("statis_b_source3")}</th>
                  <th className="px-4 py-2">{t("statis_b_source2")}</th>
                </tr>
              </thead>
              <tbody>
                {orders.map((order) => (
                  <tr key={order.id} className="text-center border-t">
                    <td className="px-4 py-2">
                      <button className="text-white px-2 py-1"
                        onClick={() => (window.location.href = `https://nawyapp.com/${order.typeable_type}/${order.typeable_id}`) }>
                        <svg
                          width="35" height="36" viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.19219 5.01C5.56906 5.01 4.30469 6.27219 4.30469 7.8975V28.5103C4.30469 30.1334 5.56906 31.3978 7.19219 31.3978H27.8072C29.4303 31.3978 30.6947 30.1334 30.6947 28.5103V7.89531C30.6947 6.27219 29.4303 5.00781 27.8072 5.00781L7.19219 5.01ZM15.3122 9.45281H19.6872V13.8278H15.3122V9.45281ZM15.3122 16.0153H19.6872V26.9528H15.3122V16.0153Z" fill="#800080" />
                        </svg>
                      </button>
                    </td>
                    <td className="px-4 py-2">{commission ? commission.find(el => order.id == el.id ).commission : '-'}</td>
                    <td className="px-4 py-2">{order.status === 0 ? 'معلق' : order.status === 1 ? 'ناجح' : 'مرفوض'}</td>
                    <td className="px-4 py-2">{order.typeable.price} $</td>
                    <td className="px-4 py-2">{order.typeable_type === 'Vehicle'? t('statis_b_source4'): t('statis_b_source5')}</td>
                    <td className="px-4 py-2">{order.typeable.owner.username}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
       {/* All Ads  */}
      <div className="grid grid-cols-1 sm:grid-cols-2 p-4 gap-4">
        <div className="h-[400px] mt-[45px] p-4 bg-white">
          <ResponsiveContainer width="100%" height={400}>
            <LineChart data2={adsData2} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
              <CartesianGrid strokeDasharray="3 3" /><XAxis dataKey="month" /> <YAxis /> <Tooltip /><Legend />
              <Line type="monotone" dataKey="ads" stroke="#8884d8" activeDot={{ r: 8 }} />
            </LineChart>
          </ResponsiveContainer>
        </div>

        <div>
          <div className="bg-white rounded-lg shadow-md p-4">
            <h2 className="text-xl font-semibold mb-4 text-right"> {t("statis_b_compl0")} </h2>
            <table className="min-w-full">
              <thead>
                <tr>
                  <th className="px-4 py-2">{t("statis_b_det")}</th>
                  <th className="px-4 py-2">{t("statis_b_profit2")}</th>
                  <th className="px-4 py-2">{t("statis_b_profit3")}</th>
                  <th className="px-4 py-2">{t("statis_b_price2")}</th>
                  <th className="px-4 py-2">{t("statis_b_source2")}</th>
                  <th className="px-4 py-2">{t("statis_b_source3")}</th>
                </tr>
              </thead>
              <tbody>
                {orders2.map((order) => (
                  <tr key={order.id} className="text-center border-t">
                    <td className="px-4 py-2">
                      <button className="text-white px-2 py-1"
                        onClick={() =>(window.location.href = `https://nawyapp.com/${order.typeable_type}/${order.typeable_id}`)}>
                        <svg width="35" height="36" viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.19219 5.01C5.56906 5.01 4.30469 6.27219 4.30469 7.8975V28.5103C4.30469 30.1334 5.56906 31.3978 7.19219 31.3978H27.8072C29.4303 31.3978 30.6947 30.1334 30.6947 28.5103V7.89531C30.6947 6.27219 29.4303 5.00781 27.8072 5.00781L7.19219 5.01ZM15.3122 9.45281H19.6872V13.8278H15.3122V9.45281ZM15.3122 16.0153H19.6872V26.9528H15.3122V16.0153Z" fill="#800080" />
                        </svg>
                      </button>
                    </td>
                    <td className="px-4 py-2">{commission ? commission.find(el => order.id == el.id ).commission : '-'}</td>
                    <td className="px-4 py-2">{order.status === 0 ? 'معلق' : order.status === 1 ? 'ناجح' : 'مرفوض'}</td>
                    <td className="px-4 py-2">{order.typeable.price} $</td>
                    <td className="px-4 py-2">{order.typeable_type === 'Vehicle'? t('statis_b_source4'): t('statis_b_source5')}</td>
                    <td className="px-4 py-2">{order.user.username}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
export default StatisticsBroker;