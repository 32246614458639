import React, { useEffect, useState } from "react";
import CardsVehicle from "./asset_comontants/CardsVehicle";
import Storyadmin from "./asset_comontants/story_admin";
import Important from "./asset_comontants/important";
import { useTranslation } from "react-i18next";
import axios from "axios";
import LocationFetcher from "../LocationFetcher";
// import Agency from "./Agency";

const PageVehicle = () => {
  const { t } = useTranslation();
  const [vehicles, setvehicles] = useState([]);
  const [vehicle_nea, setvehicle_nea] = useState([]);
  const [location, setLocation] = useState({ latitude: null, longitude: null, address: '' });
  const token = localStorage.getItem("token");
  const [city, setcity] = useState("");
  const [country, setcountry] = useState("");
  const [vehicles_make, setvehicles_make] = useState([]);

  // const fetchCategories = async () => {
  //   await axios
  //     .get("https://nawyapp.com/api/Myprofile", {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     })
  //     .then(({ data }) => {
  //       setcountry(data.location.country);
  //       setcity(data.location.city);
  //       fetchvehicle_nea(data.location.latitud, data.location.longitude);
  //     });
  // };
  const fetchvehicles_best = async () => {
    await axios
      .get("https://nawyapp.com/api/vehicles_best")
      .then(({ data }) => {
        setvehicles(data);
      });
  };
  useEffect(() => {
    // fetchCategories();
    fetchvehicles_best();
    fetchvehiclesMake();
  }, []);
  const fetchvehicle_nea = async (lat, lng, address) => {
    await axios
      .get("https://nawyapp.com/api/vehicle_nea", {
        params: {
          lat: parseFloat(lat),
          lng: parseFloat(lng)
        },
      })
      .then(({ data }) => {
        setvehicle_nea(data);
        setLocation({ lat, lng, address })
        setcity(address)
        
      });
  };

  const fetchshowstatus_car = async (status) => {
    await axios
      .get("https://nawyapp.com/api/showstatus_car/" + status)
      .then(({ data }) => {
        setvehicles(data);
      });
  };

  const fetchvehiclesMake = async () => {
    await axios.get("https://nawyapp.com/api/vehiclesMake").then(({ data }) => {
      setvehicles_make(data);
    });
  };

  return (
    <div className=" bg-[#EBEAFA]">
      {vehicles.length ? (
        <div className="">

          <Storyadmin />
          <CardsVehicle
            show={1}
            title={t("best_vehicles")}
            vehicles={vehicles}
            isSpecial={true}
            country={city}
            fetchshowstatus_car={fetchshowstatus_car}
          />
          <Important vehicles_make={vehicles_make} title={t("top_agencies")} />
          {/* <NewCars  title={': أحدث السيارات 2024'}/> */}
          <CardsVehicle
            show={0}
            vehicles={vehicle_nea}
            title={t("top_vehicles")}
            isSpecial={false}
            country={location.address}
          />
          <div className=""  style={{ display: 'none' }}>
            <LocationFetcher onLocationFetched={(latitude, longitude, address) => {
           
                fetchvehicle_nea(latitude, longitude, address)
            }
            } />
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
          {/* Animation Spinner */}
          <div className="flex justify-center items-center mb-5">
            <div className="w-12 h-12 border-4 border-purple-500 border-solid rounded-full border-t-transparent animate-spin"></div>
          </div>
          {/* Loading Text */}
          <div className="text-lg font-semibold text-purple-600">
            {t("lodin")}
          </div>


        </div>
      )}
    </div>
  );
};

export default PageVehicle;
