import React, { useRef, useState } from "react";
import image from "../../assets/ImagesVehicle/car.png";
import image1 from "../../assets/ImagesVehicle/car.png";
import { toPng } from 'html-to-image';
import axios from "axios";
import { FacebookIcon, FacebookShareButton, TelegramIcon, TelegramShareButton, TwitterShareButton, WhatsappIcon, WhatsappShareButton, XIcon } from "react-share";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";
import { FaQrcode, FaRegHeart, FaShareAlt } from "react-icons/fa";
import { Link } from "react-router-dom";

// const images = [image, image1, image, image1, image, image1, image];

const CarouselVehicle = ({ images, make, vId }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState('');
  const qrRef = useRef();
  const { t } = useTranslation();
  const [showQR, setShowQR] = useState(false);
  const urlRef = useRef(null);

  // دالة نسخ الـ URL
  const copyURL = (e) => {
    e.preventDefault();
    // تحديد النص داخل الحقل
    urlRef.current.select();
    urlRef.current.setSelectionRange(0, 99999); // للأجهزة المحمولة
    // نسخ النص إلى الحافظة
    document.execCommand("copy");
    // إعلام المستخدم بأن النص تم نسخه
    alert("تم نسخ الـ URL: " + urlRef.current.value);
  };
  const delete_api = async (id) => {
    await axios.delete('https://nawyapp.com/api/Favorite/' + id
    ).then(() => {
      setFavoriteColor(false);
    });
  }

  const shareUrl = window.location.href; // الرابط الحالي للصفحة
  const title = make || "Check out this property";

  const [Favorite_color, setFavoriteColor] = useState(false);

  const handleFavoriteToggle = () => {
    if (Favorite_color) {
      delete_api();
    } else {
      Favorite();
    }
  };
  const [showModal, setShowModal] = useState(false);

  const Favorite = async () => {
    const token = localStorage.getItem('token');
    const formdata = new FormData();
    formdata.append('favoritable_type', 'Vechicle')
    formdata.append('favoritable_id', vId)
    await axios.post('https://nawyapp.com/api/Favorite', formdata,
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }).then(() => {
        setFavoriteColor(true)
      }).catch((error) => {
        console.log(error);
      });
  }

  const goToSlide = (index) => {
    setCurrentImageIndex(index);
  };
  const handleGenerateQR = () => {
    setShowQR(true);
  };
  const handleDownloadQR = (e) => {
    e.preventDefault();
    if (qrRef.current) {
      toPng(qrRef.current)
        .then((dataUrl) => {
          const link = document.createElement('a');
          link.download = 'qr-code.png';
          link.href = dataUrl;
          link.click();
        })
        .catch((err) => {
          console.error('Failed to download QR code', err);
        });
    }
  };



  return (
    <div className="relative w-full h-96 overflow-hidden flex">
      {/* Big image slider */}
      <a href="#1">
        <div className=" relative bg-white pr-2 right-[-85rem] top-[18px] w-[42px] h-[40px] shadow-lg rounded-full z-50">

          {Favorite_color === false ? (
            <button onClick={Favorite} className=" m-2 text-gray-600">
              <FaRegHeart className="size-7 text-black" />
            </button>
          ) : (
            <button className=" text-gray-600">
              <FaRegHeart className="size-7 text-red-700" />
            </button>
          )}
        </div>
      </a>
      <a>
        <div className=" relative bg-white pr-2 mx-2 right-[-85rem] top-[18px] w-[42px] h-[40px] shadow-lg rounded-full z-50">
        <button onClick={() => setShowModal(true)} className="m-2 text-gray-600">
        <FaShareAlt className="size-7 text-black" />
      </button>
       
        </div>
      </a>
      <a href="#1">
        <div className=" relative bg-white pr-2 right-[-85rem] top-[18px] w-[42px] h-[40px] shadow-lg rounded-full z-50">
          
      <button onClick={handleGenerateQR} className=" text-gray-600 m-2">
        <FaQrcode className="size-7 text-black" />
      </button>

        </div>
      </a>



      <div
        className="absolute resize-none  inset-0 w-[1740px] h-[385px] bg-cover bg-center"
        style={{ backgroundImage: `url(${currentImageIndex})`, backgroundSize: "cover" }}
      ></div>
      <div className="absolute inset-0 bg-black opacity-25"></div>

      {/* small images sliders */}
      <div className="absolute inset-x-0 bottom-6 flex justify-center items-center">
        {images.map((index) => (
          <button
            key={index.id}
            className={`w-10 h-10 m-1 border-2 rounded-lg ${index === currentImageIndex
              ? "border-purple-500"
              : "border-gray-200"
              }`}
            onClick={() => goToSlide(`https://nawyapp.com/storage/${index.url_file}`)}
          >
            <img
              src={`https://nawyapp.com/storage/${index.url_file}`}
              alt={`Thumbnail ${index.id}`}
              className="w-[full] h-full object-cover"
            />
          </button>
        ))}
      </div>
      {showModal && (
        <div className="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="flex items-end justify-center  pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <form action="" method="post">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <h3 className="text-2xl font-semibold mb-4 text-center" id="modal-title">
                    {t('share-p')}
                  </h3>
                  <div className="">
                    <input type="text" name="" ref={urlRef} className="p-2 border-2 rounded-full border-[#FFA500] w-[350px] mx-9" value={window.location.href} id="" />
                    <button className="p-2 -mx-6 bg-[#800080] rounded-xl text-white" onClick={copyURL}>نسخ</button>
                    <br /> <br />
                    <div className="flex justify-center">
                      <WhatsappShareButton url={shareUrl} title={title}>
                        <WhatsappIcon className="m-1" size={32} round />
                      </WhatsappShareButton>
                      <TelegramShareButton url={shareUrl} quote={title}>
                        <TelegramIcon className="m-1" size={32} round />
                      </TelegramShareButton>
                      <FacebookShareButton url={shareUrl} quote={title}>
                        <FacebookIcon className="m-1" size={32} round />
                      </FacebookShareButton>
                      <TwitterShareButton url={shareUrl} title={title}>
                        <XIcon className="m-1" size={32} round />
                      </TwitterShareButton>
                    </div>

                  </div>

                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">

                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-[#FFA500] text-base font-medium text-white hover:bg-[#800080] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#FFA500] sm:ml-3 sm:w-auto sm:text-sm transition-colors duration-200"
                    onClick={() => setShowModal(false)}
                  >
                    {t('profile_close')}
                  </button>
                </div>
              </form>
            </div>

          </div>
        </div>
      )}

      {showQR && (
        <div className="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="flex items-end justify-center  pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <form action="" method="post">

                <div >
                  <div ref={qrRef} className="flex justify-center" style={{ margin: '20px' }}>
                    <QRCode value={window.location.href} />
                  </div>
                  <div className="flex justify-center">
                    <button className="p-3 border-2 bg-[#800080] text-white " onClick={handleDownloadQR}>تحميل QR Code</button>
                    <button
                      type="button"
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-[#FFA500] text-base font-medium text-white hover:bg-[#800080] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#FFA500] sm:ml-3 sm:w-auto sm:text-sm transition-colors duration-200"
                      onClick={() => setShowQR(false)}
                    >
                      {t('profile_close')}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CarouselVehicle;
