import React, { useEffect, useState } from "react";
import imgscr from "../assets/ImagesVehicle/person.png";
import axios from "axios";

function Vectorimg() {
  const token = localStorage.getItem("token");
  const [username, setusername] = useState("");
  const fetchCategories = async () => {
    await axios
      .get("https://nawyapp.com/api/Myprofile", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(({ data }) => {
        setusername(data.username);
      });
  };

  useEffect(() => {
    fetchCategories();
  });
  return (
    <div className="w-full bg-[#ebeafa] p-6 flex flex-col items-center">
      <div className="rounded-full w-[190px] h-[190px] overflow-hidden">
        <img src={imgscr} width="190px" alt="" />
      </div>
      <h1 className="font-bold text-[25px] mt-4 text-center">{username}</h1>
    </div>
  );
}
export default Vectorimg;