import React, {useEffect, useState} from 'react'
import DescriptionInfluancer from './DescriptionInfluancer'
import AboutInfluancer from './AboutInfluancer';
import SimilarInfluancer from './SimilarInfluancer';
import axios from 'axios';
import BackgroundB from './BackgroundInf';
import { useParams } from 'react-router-dom';

const InfluancerServices = () => {

  const {id} = useParams();
  console.log(id)
  const [username, setusername] = useState('')
  const [bio, setbio] = useState('')
  const [country, setcountry] = useState('')
  const [city, setcity] = useState('')
  const [imageName, setimage] = useState('')
  const [defaultCenter, setDefaultCenter] = useState({ lat: 0, lng: 0 });
  const imageUrl = `https://nawyapp.com/storage/${imageName}`;
  const [Des3, setDes3] = useState(0);

  const fetchCategories = async () => {
    await axios.get('https://nawyapp.com/api/Influencer/' + id,
    ).then(({ data }) => {
      console.log(data)
      setusername(data.user.username)
      setbio(data.bio)
      setDefaultCenter({
        lat: data.user.location.latitud,
        lng: data.user.location.longitude
      })
      setcountry(data.user.location.country)
      setcity(data.user.location.city)
      setimage(data.image)
    });
  }
  useEffect(() => {
    fetchCategories();
  }, [])

  return (
    <div className='bg-[#EBEAFA]'>
      <BackgroundB type={'Influencer'} username={username} imageUrl={imageUrl} country={country} city={city} id={id} />
      <DescriptionInfluancer setDes={setDes3}/>
      {Des3 === 0 && <AboutInfluancer bio={bio} center={defaultCenter}/> }
      {Des3 === 1 && <SimilarInfluancer city={city}/> }
      <br></br>
    </div>
  )
}

export default InfluancerServices