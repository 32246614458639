import React from "react";
import { GoogleMap, LoadScript, Marker,   } from '@react-google-maps/api';
import { Link } from "react-router-dom";
import { FaBath, FaCcVisa, FaDumbbell, FaMoneyBillAlt, FaPaypal, FaShieldAlt, FaSwimmer, FaUtensils, FaWifi,} from "react-icons/fa";
import { BsTextareaResize } from "react-icons/bs";
import { LuBedDouble } from "react-icons/lu";
import { useTranslation } from "react-i18next";

const DescriptionProperty = ({property}) => {
  
  const { t } = useTranslation();
  const mapStyles = {
    height: "400px",
    width: "100%"
  };

  const lat = Number(property[0].location.latitud);
 const lng = Number(property[0].location.longitude);
  const defaultCenter ={ lat, lng};

  return (
    <div className="container mx-auto px-4 py-8 mb-8">
      <h2 className="text-2xl font-bold text-center mb-12">{t('des_property_top')}</h2>
      {property.map((p, index1) => (
        <div className="" key={index1}>
          <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 gap-4 mb-12">
            {[
              { icon: <FaShieldAlt className="text-[#800080] text-3xl" />, value: `${p.safety_rank}`, label: t('des_property_safe') },
              { icon: <FaBath className="text-[#800080] text-3xl" />, value: `${p.number_of_bathroom}`, label: t('des_property_bath') },
              { icon: <LuBedDouble className="text-[#800080] text-3xl" />, value: `${p.number_of_dining}`, label: t('des_property_room_d') },
              { icon: <BsTextareaResize className="text-[#800080] text-3xl" />, value: `${p.square_footage}`, label: t('des_property_space') },
              { icon: <BsTextareaResize className="text-[#800080] text-3xl" />, value: `${p.orientation}`, label: t('des_property_orientation') },
              { icon: <BsTextareaResize className="text-[#800080] text-3xl" />, value: `${p.number_of_streets_around}`, label: t('des_property_streets_around') },
              { icon: <BsTextareaResize className="text-[#800080] text-3xl" />, value: `${p.number_of_rooms}`, label: t('des_property_room') },
            ].map((item, index) => (

              <div key={index} className="bg-white border rounded-xl shadow-xl p-4 text-center">
                <div className="flex justify-center mb-2">{item.icon}</div>
                <div className="text-xl font-semibold">{item.value}</div>
                <div>{item.label}</div>
              </div>
            ))}
          </div>

          <h2 className="text-2xl font-bold text-center mb-8">{t('des_property_gen')}</h2>
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-6 gap-4 mb-12">
            {[
              p.has_garage === 1 ?
                { icon: <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/317fd1d0940baf4c7f064f2098f456b942387c0f6f6e40ec2cb58989e3de7a7c?" className="w-8 h-8" alt="" />, label: t('des_property_park') } : '',
              p.has_swimming === 1 ?
                { icon: <FaSwimmer className="text-[#800080] text-3xl" />, label: t('des_property_pool') } : '',
              p.has_gym === 1 ?
                { icon: <FaDumbbell className="text-[#800080] text-3xl" />, label: t('des_property_gym') } : '',
              p.kitchen === 1 ?
                { icon: <FaUtensils className="text-[#800080] text-3xl" />, label: t('des_property_rest') } : '',
              p.has_wifi === 1 ?
                { icon: <FaWifi className="text-[#800080] text-3xl" />, label: t('des_property_wifi') } : '',
              p.has_air_conditioning === 1 ?
                { icon: <FaWifi className="text-[#800080] text-3xl" />, label: t('has_conditioning') } : '',
              p.has_helper_room === 1 ?
                { icon: <FaWifi className="text-[#800080] text-3xl" />, label: t('has_helper_room') } : '',
              p.furnisheh === 1 ?
                { icon: <FaWifi className="text-[#800080] text-3xl" />, label: t('furnisheh') } : '',
              p.add_property_fireplace === 1 ?
                { icon: <FaWifi className="text-[#800080] text-3xl" />, label: t('add_property_fireplace') } : '',
              p.has_basement === 1 ?
                { icon: <FaWifi className="text-[#800080] text-3xl" />, label: t('add_property_basement') } : '',
              p.add_property_garden === 1 ?
                { icon: <FaWifi className="text-[#800080] text-3xl" />, label: t('add_property_garden') } : '',
              p.has_elevator === 1 ?
                { icon: <FaWifi className="text-[#800080] text-3xl" />, label: t('add_property_elevator') } : '',
              p.has_alarm_system === 1 ?
                { icon: <FaWifi className="text-[#800080] text-3xl" />, label: t('add_property_alarm') } : '',
              p.has_terrace === 1 ?
                { icon: <FaWifi className="text-[#800080] text-3xl" />, label: t('add_property_terrace') } : '',
            ].map((item, index) => (
              <div>
                {item.icon ?
                  <div key={index} className="bg-white border rounded-xl shadow-xl p-4 text-center">
                    <div className="flex justify-center mb-2">{item.icon}</div>
                    <div>{item.label}</div>
                  </div>
                  : <h1></h1>}
              </div>
            ))}
          </div>
          <h2 className="text-2xl font-bold text-center mb-8">{t('des_property_pay')}</h2>
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mb-12">
            {p.payment_option === 'كارد' ?
              <div className="bg-white border rounded-xl shadow-xl p-4 text-center">
                <div className="flex justify-center mb-2"> <FaCcVisa className="text-[#800080] text-3xl" /></div>
                <div> {t('des_property_visa')}</div>
              </div>
              : p.payment_option === ' كاش' ?
                <div className="bg-white border rounded-xl shadow-xl p-4 text-center">
                  <div className="flex justify-center mb-2"> <FaMoneyBillAlt className="text-[#800080] text-3xl" /></div>
                  <div> {t('des_property_cash')}</div>
                </div> :
                <div className="bg-white border rounded-xl shadow-xl p-4 text-center">
                  <div className="flex justify-center mb-2"> <FaPaypal className="text-[#800080] text-3xl" /></div>
                  <div> {t('des_property_paypal')}</div>
                </div>}
          </div>
          <h1> </h1>
          <h2 className="text-2xl font-bold text-center mb-8">{t('des_property_address')}</h2>
          <LoadScript googleMapsApiKey="AIzaSyCN1ldCkq6kz0PbBE6Y2eUTLYIGyNfcQxY">
            <GoogleMap
              mapContainerStyle={mapStyles}
              zoom={30}
              center={defaultCenter}>
              <Marker position={defaultCenter} />
            </GoogleMap>
          </LoadScript>
          <div className="text-center bg-white p-6 rounded-xl shadow-xl">
            <div className="mb-4">
              <span className="font-bold">{t('des_proprty_price')}</span>
              <span className="text-2xl ml-2">
                <span className="text-blue-600">{p.price} $</span>
              </span>
            </div>
            <Link to="">
              <button className="bg-[#FFA500] hover:bg-amber-500 text-white w-full sm:w-auto px-8 py-3 rounded-full font-medium text-xl">
                {t('des_proprty_now')}
              </button>
            </Link>
          </div>
        </div>
      ))
      }
    </div>

  );
};
export default DescriptionProperty;