import React, { useEffect, useState } from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, BarChart, Bar } from "recharts";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { FaDollarSign, FaChartLine, FaAd, FaExchangeAlt } from 'react-icons/fa';

const StatisticsBroker = ({ influncer_id }) => {
  const { t } = useTranslation();
  const [adsData, setAdsData] = useState([]);
  const [adsData2, setAdsData2] = useState([]);
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [totalAds, setTotalAds] = useState(0);
  const [totalAds2, setTotalAds2] = useState(0);
  const [earnings, setEarnings] = useState({ totalCommission: 0 });
  const [orders, setOrders] = useState([]);
  const [orders2, setOrders2] = useState([]);
  const [commission, setCommission] = useState(null);
  const token = localStorage.getItem("token");

  useEffect(() => {
    fetchAdsData();
    fetchAdsData2();
    fetchCategories();
    fetchCategories2();
    fetchCommissions();
    fetchTransactions();
  }, [influncer_id]);

  const fetchAdsData = async () => {
    try {
      const response = await axios.get(
        `https://nawyapp.com/api/influencer-ads-by-month`,
        { headers: { 'Authorization': `Bearer ${token}` } }
      );
      const data = response.data;
      const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      const formattedData = months.map((month, index) => {
        const monthData = data.find((item) => item.month === index + 1);
        return { month: month, ads: monthData ? monthData.count : 0 };
      });
      setAdsData(formattedData);
    } catch (error) {
      console.error("Error fetching influencer request ads data", error);
    }
  };

  const fetchAdsData2 = async () => {
    try {
      const response = await axios.get(
        `https://143.198.166.228/api/influencer-ads-by-month2`,
        { headers: { 'Authorization': `Bearer ${token}` } }
      );
      const data2 = response.data2;
      const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      const formattedData2 = months.map((month, index2) => {
        const monthData = data2.find((item) => item.month === index2 + 1);
        return { month: month, ads2: monthData ? monthData.count : 0 };
      });
      setAdsData2(formattedData2);
    } catch (error) {
      console.error("Error fetching influencer ads data", error);
    }
  };

  const fetchCategories = async () => {
    try {
      const { data } = await axios.get('https://nawyapp.com/api/RequestAdInfluancer',
        { headers: { 'Authorization': `Bearer ${token}` } }
      );
      setOrders(data);
    } catch (error) {
      console.error("Error fetching categories", error);
    }
  };

  const fetchCategories2 = async () => {
    try {
      const { data } = await axios.get('https://nawyapp.com/api/Ad_influencer',
        { headers: { 'Authorization': `Bearer ${token}` } }
      );
      setOrders2(data);
    } catch (error) {
      console.error("Error fetching categories 2", error);
    }
  };

  const fetchCommissions = async () => {
    try {
      const response = await axios.get('https://nawyapp.com/api/Rejected-adsInf', {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      setCommission(response.data);
    } catch (error) {
      console.error("Error fetching commissions", error);
    }
  };

  const fetchTransactions = async () => {
    try {
      const response = await axios.get('https://nawyapp.com/api/influancer/transactions', {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      const { totalTransactionsPending, totalAds, totalAds2, totalCommission } = response.data;
      setTotalTransactions(totalTransactionsPending);
      setTotalAds(totalAds);
      setTotalAds2(totalAds2);
      setEarnings({ totalCommission: totalCommission });
    } catch (error) {
      console.error("Error fetching transactions:", error);
    }
  };

  const StatCard = ({ icon, title, value, color }) => (
    <div className={`bg-white p-6 rounded-lg shadow-md text-center border-l-4 ${color}`}>
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-gray-700 text-xl font-medium">{title}</h2>
        {icon}
      </div>
      <p className="text-gray-900 text-3xl font-bold">{value}</p>
    </div>
  );

  const TableComponent = ({ title, data, commission }) => (
    <div className="bg-white rounded-lg shadow-md p-6">
      <h2 className="text-2xl font-semibold mb-6 text-gray-800">{title}</h2>
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              {[t("statis_b_det"), t("statis_b_profit2"), t("statis_b_profit3"), t("statis_b_price2"), t("statis_b_source3"), t("statis_b_source2")].map((header, index) => (
                <th key={index} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{header}</th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {data.map((order) => (
              <tr key={order.id}>
                <td className="px-6 py-4 whitespace-nowrap">
                  <button className="text-purple-600 hover:text-purple-900" onClick={() => window.location.href = `https://nawyapp.com/${order.typeable_type}/${order.typeable_id}`}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM11 8V11H8V13H11V16H13V13H16V11H13V8H11Z" fill="currentColor"/>
                    </svg>
                  </button>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">{commission ? commission.find(el => order.id == el.id)?.commission : '-'}</td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                    order.status === 0 ? 'bg-yellow-100 text-yellow-800' : 
                    order.status === 1 ? 'bg-green-100 text-green-800' : 
                    'bg-red-100 text-red-800'
                  }`}>
                    {order.status === 0 ? 'معلق' : order.status === 1 ? 'ناجح' : 'مرفوض'}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">${order.typeable.price}</td>
                <td className="px-6 py-4 whitespace-nowrap">{order.typeable_type === 'Vehicle' ? t('statis_b_source4') : t('statis_b_source5')}</td>
                <td className="px-6 py-4 whitespace-nowrap">{order.typeable.owner.username}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );

  return (
    <div className="bg-gray-100 min-h-screen p-8">
     
      
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        <StatCard icon={<FaDollarSign className="text-green-500 text-3xl" />} title={t("statis_b_total")} value={`$${earnings.totalCommission.toFixed(2)}`} color="border-green-500" />
        <StatCard icon={<FaChartLine className="text-blue-500 text-3xl" />} title={t("statis_b_profit")} value={totalAds} color="border-blue-500" />
        <StatCard icon={<FaAd className="text-purple-500 text-3xl" />} title={t("statis_b_profit0")} value={totalAds2} color="border-purple-500" />
        <StatCard icon={<FaExchangeAlt className="text-yellow-500 text-3xl" />} title={t("statis_b_conve")} value={totalTransactions} color="border-yellow-500" />
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-8">
        <div className="bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-xl font-semibold mb-4 text-gray-800">{t("statis_chart_title1")}</h2>
          <ResponsiveContainer width="100%" height={400}>
            <LineChart data={adsData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="ads" stroke="#8884d8" activeDot={{ r: 8 }} />
            </LineChart>
          </ResponsiveContainer>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-md">
          <h2 className="text-xl font-semibold mb-4 text-gray-800">{t("statis_chart_title2")}</h2>
          <ResponsiveContainer width="100%" height={400}>
            <BarChart data={adsData2} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="ads2" fill="#82ca9d" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>

      <div className="space-y-8">
        <TableComponent title={t("statis_b_compl")} data={orders} commission={commission} />
      </div>
    </div>
  );
};

export default StatisticsBroker;