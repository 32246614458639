import React, { useEffect, useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { FaEdit, FaMapMarkerAlt } from 'react-icons/fa';
import MapComponent from "./mapProperty";

const EditProfileComponents = () => {
  const { t } = useTranslation();
  const [userData, setUserData] = useState({
    username: "",
    email: "",
    phone: "",
    avatar: "",
  });
  const [showModal, setShowModal] = useState(false);
  const [propertyData, setPropertyData] = useState({
    latitude: null,
    longitude: null,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const token = localStorage.getItem("token");

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const { data } = await axios.get("https://nawyapp.com/api/Myprofile", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUserData({
        username: data.username,
        email: data.email,
        phone: data.phone,
        avatar: data.avatar,
      });
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    setUserData(prev => ({ ...prev, avatar: acceptedFiles[0] }));
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop,
    multiple: false,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData(prev => ({ ...prev, [name]: value }));
  };

  const handleLocationSelect = (location) => {
    setPropertyData({
      latitude: location.lat,
      longitude: location.lng,
    });
  };

  const updateProfile = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formData = new FormData();
    Object.keys(userData).forEach(key => {
      if (key !== 'avatar' || (key === 'avatar' && userData[key] instanceof File)) {
        formData.append(key, userData[key]);
      }
    });

    try {
      await axios.post('https://nawyapp.com/api/update-profile', formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        }
      });
      alert(t('profile_updated_successfully'));
    } catch (error) {
      console.error('Error updating profile:', error);
      alert(t('error_updating_profile'));
    } finally {
      setIsSubmitting(false);
    }
  };

  const updateLocation = async () => {
    try {
      await axios.post('https://nawyapp.com/api/editlocation', propertyData, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      alert(t('location_updated_successfully'));
      setShowModal(false);
    } catch (error) {
      console.error('Error updating location:', error);
      alert(t('error_updating_location'));
    }
  };

  return (
    <div className="bg-gray-100 min-h-screen py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto bg-white rounded-lg shadow-xl p-8">
        <div className="text-center mb-8">
          <div className="relative inline-block">
            <img
              src={userData.avatar ? URL.createObjectURL(userData.avatar) : "default-avatar.png"}
              alt={userData.username}
              className="w-32 h-32 rounded-full border-4 border-purple-500"
            />
            <div {...getRootProps()} className="absolute bottom-0 right-0 bg-purple-600 rounded-full p-2 cursor-pointer">
              <input {...getInputProps()} />
              <FaEdit className="text-white" />
            </div>
          </div>
          <h2 className="mt-4 text-2xl font-bold text-gray-800">{userData.username}</h2>
        </div>

        <form onSubmit={updateProfile} className="space-y-6">
          <div>
            <label htmlFor="username" className="block text-sm font-medium text-gray-700">
              {t("username")}
            </label>
            <input
              type="text"
              id="username"
              name="username"
              value={userData.username}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring focus:ring-purple-500 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
              {t("phone")}
            </label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={userData.phone}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring focus:ring-purple-500 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
              {t("email")}
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={userData.email}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-purple-500 focus:ring focus:ring-purple-500 focus:ring-opacity-50"
            />
          </div>

          <div className="flex justify-between items-center pt-6">
            <Link to="/Profile">
              <button type="button" className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 transition-colors">
                {t("content_back")}
              </button>
            </Link>
            <button
              type="button"
              onClick={() => setShowModal(true)}
              className="px-4 py-2 bg-yellow-500 text-white rounded-md hover:bg-yellow-600 transition-colors flex items-center"
            >
              <FaMapMarkerAlt className="mr-2" />
              {t("edit_profile_loca")}
            </button>
            <button
              type="submit"
              disabled={isSubmitting}
              className="px-4 py-2 bg-purple-600 text-white rounded-md hover:bg-purple-700 transition-colors"
            >
              {isSubmitting ? t("updating") : t("content_sure")}
            </button>
          </div>
        </form>
      </div>

      {showModal && (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white rounded-lg p-8 max-w-lg w-full">
            <h3 className="text-2xl font-bold mb-4">{t("add_property_delivery_locate")}</h3>
            <MapComponent onLocationSelect={handleLocationSelect} />
            <div className="mt-6 flex justify-end space-x-4">
              <button
                onClick={() => setShowModal(false)}
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 transition-colors"
              >
                {t("profile_close")}
              </button>
              <button
                onClick={updateLocation}
                className="px-4 py-2 bg-purple-600 text-white rounded-md hover:bg-purple-700 transition-colors"
              >
                {t("profile_send")}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditProfileComponents;