import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import OrderComponentsInfluancer from "../components/OrderComponentsInfluancer";
import { FaSpinner } from "react-icons/fa";

const OrderInfluancer = () => {
  const { t } = useTranslation();
  const [orderInf, setOrderInf] = useState([]);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const token = localStorage.getItem("token");

  const fetchImages = useCallback(async (id) => {
    try {
      const { data } = await axios.get(`https://nawyapp.com/api/showfiles`, {
        params: { type: "property", id },
      });
      setImages(data);
    } catch (err) {
      console.error("Error fetching images:", err);
      setError(t("error_fetching_images"));
    }
  }, [t]);

  const fetchProperty = useCallback(async () => {
    try {
      const { data } = await axios.get("https://nawyapp.com/api/RequestAdInfluancer", {
        headers: { Authorization: `Bearer ${token}` },
      });
      setOrderInf(data);
      if (data.length > 0) {
        await fetchImages(data[0].id);
      }
    } catch (err) {
      console.error("Error fetching property:", err);
      setError(t("error_fetching_property"));
    } finally {
      setLoading(false);
    }
  }, [token, fetchImages, t]);

  useEffect(() => {
    fetchProperty();
  }, [fetchProperty]);

  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
        <FaSpinner className="w-12 h-12 text-purple-500 animate-spin mb-4" />
        <div className="text-lg font-semibold text-purple-600">{t("loading")}</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
        <div className="text-xl font-bold text-red-600 mb-4">{t("error_occurred")}</div>
        <div className="text-lg text-gray-700">{error}</div>
      </div>
    );
  }
  return (
    <div className="bg-gray-100 min-h-screen">
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold text-gray-800 mb-8">{t("order_influencer_title")}</h1>
        <div className="bg-white rounded-lg shadow-md p-6">
          <OrderComponentsInfluancer 
            OrderInf={orderInf} 
            fetchProperty={fetchProperty} 
            images={images}
          />
        </div>
      </div>
    </div>
  );
};

export default OrderInfluancer;