import React, { useRef, useEffect, useState } from "react";
import nawi from "../../../assets/Images/nawi.svg";
import { useTranslation } from "react-i18next";
import axios from "axios";

const Story_admin = ({ title }) => {
  const { t } = useTranslation();
  const scrollContainerRef = useRef(null);

  // const stories = Array(12).fill(null);
  const [stories,setstories] = useState([]);
  const getFileExtension = (url) => {
    return url.split('.').pop().toLowerCase();
  };

  const fetchCategories = async () => {
    await axios.get('https://nawyapp.com/api/storiesget',
   
    ).then(({ data }) => {
      setstories(data)
    });
  }
  useEffect(() => {
    fetchCategories();
  }, [])
  const [showModal, setShowModal] = useState(false);
  const [url,seturl] = useState([])
  const click = (url) => {
    seturl(url)
    setShowModal(true)

  }


  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      let isDown = false;
      let startX;
      let scrollLeft;

      const mouseDownHandler = (e) => {
        isDown = true;
        startX = e.pageX - scrollContainer.offsetLeft;
        scrollLeft = scrollContainer.scrollLeft;
      };

      const mouseLeaveHandler = () => {
        isDown = false;
      };

      const mouseUpHandler = () => {
        isDown = false;
      };

      const mouseMoveHandler = (e) => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - scrollContainer.offsetLeft;
        const walk = (x - startX) * 3;
        scrollContainer.scrollLeft = scrollLeft - walk;
      };

      scrollContainer.addEventListener('mousedown', mouseDownHandler);
      scrollContainer.addEventListener('mouseleave', mouseLeaveHandler);
      scrollContainer.addEventListener('mouseup', mouseUpHandler);
      scrollContainer.addEventListener('mousemove', mouseMoveHandler);

      return () => {
        scrollContainer.removeEventListener('mousedown', mouseDownHandler);
        scrollContainer.removeEventListener('mouseleave', mouseLeaveHandler);
        scrollContainer.removeEventListener('mouseup', mouseUpHandler);
        scrollContainer.removeEventListener('mousemove', mouseMoveHandler);
      };
    }
  }, []);

  const checkFileType = (url) => {
    const extension = getFileExtension(url);

    if (['png', 'jpg', 'jpeg', 'gif'].includes(extension)) {
      return "image";
    } else if (['mp4', 'avi', 'mov'].includes(extension)) {
      return "video";
    } else {
      return "نوع الملف غير معروف.";
    }
  };

  return (
    <div className="container mx-auto px-4 py-6">
      <h2 className="font-bold text-right text-2xl mb-6">{title}</h2>
      <div className="flex justify-center">
        <div 
          ref={scrollContainerRef}
          className="flex overflow-x-auto scrollbar-hide space-x-4 pb-4 max-w-full"
          style={{
            scrollSnapType: 'x mandatory',
            WebkitOverflowScrolling: 'touch',
          }}
        >
          {stories.map((story, index) => (
            <div 
              key={index} 
              className="flex-shrink-0 scroll-snap-align-start"
              style={{ scrollSnapAlign: 'start' }}
            >
              <div className="flex flex-col items-center w-20 sm:w-24">

                <a onClick={()=> click(story.url)} className="block w-16 h-16 sm:w-20 sm:h-20 mb-2">
                  <img
                    className="w-full h-full rounded-full border-2 border-gray-200 object-cover"
                    src={nawi}
                    alt={`Story ${index + 1}`}
                  />
                </a>
                {/* <p className="text-center text-xs sm:text-sm truncate w-full">{t('story_name')}</p> */}
              </div>
            </div>
          ))}
        </div>
      </div>
      {showModal && (

<div className="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
  <div className="flex items-end justify-center  pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
    <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
      <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <h3 className="text-2xl font-semibold mb-4 text-center" id="modal-title">
          {/* {t('profile_send')} */}
        </h3>

        <div className="flex justify-center">
          {
            checkFileType(url) === 'image'? <img src={`https://nawyapp.com/storage/${url}`} alt="" /> : 
            <video className="w-full h-full sm:w-auto object-cover" controls>
            <source src={`https://nawyapp.com/storage/${url}`} type="video/mp4" />
          </video> 
          }
          

        </div>
      </div>
      <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        {/* <button
          type="submit"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-[#FFA500] text-base font-medium text-white hover:bg-[#800080] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#FFA500] sm:ml-3 sm:w-auto sm:text-sm transition-colors duration-200"
        // onClick={() => setShowModal(false)}
        >
          {t('profile_send')}
        </button> */}
        <button
          type="button"
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm transition-colors duration-200"
          onClick={() => setShowModal(false)}
        >
          {t('profile_close')}
        </button>
      </div>

    </div>


  </div>


</div>
)}
    </div>
  );
};

export default Story_admin;