import React from 'react'
import { Link } from 'react-router-dom';
const ImportantOffices = ({title,Travel_officee}) => {
  return (
    <div>
      <p className="font-bold text-right text-2xl mb-[30px]">{title}</p>
      <div className=" grid grid-cols-4 lg:flex">
        {Travel_officee.map((el) => (
          <div className="lg:w-1/12 sm:w-1/4 p-2 md:flex-cols-12 pb-2">
            <Link to="/order_travel/travel_offieces_services">
              <img
                className="flex justify-between items-start rounded-full border-2 h-16 max-w-full mx-auto overflow-x-auto"
                src={`https://nawyapp.com/storage/${el.image}`}
                alt="/"
              />
            </Link>
            <p className="text-center"> {el.user.username}</p>
          </div>
        ))}
      </div>
    </div>
  )
}
export default ImportantOffices