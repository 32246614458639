import React, { useEffect, useState } from "react";
import Carousel from "./Carousel";
import DescriptionCarousel from "./DescriptionCarousel";
import DescriptionProperty from "./DescriptionProperty";
import Images from "./Images";
import DescriptionCard from "./DescriptionCard";
import PropertyList from "./PropertyList";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const DetailsCardsProperty = () => {
  const [DesCard, setDesCard] = useState(0);
  const [images, setimages] = useState([]);
  const [proId, setpeo] = useState(0);
  const { id } = useParams(); // احصل على id من الرابط

  const [property, setproperty] = useState([]);
  const fetchimages = async (id) => {
    console.log(id);
    await axios
      .get(`https://nawyapp.com/api/showfiles`, {
        params: {
          type: "property",
          id: id,
        },
      })
      .then(({ data }) => {
        setimages(data);
      });
  };
  const [lat, setlat] = useState(0);
  const [log, setlog] = useState(0);
  const fetchproperty = async () => {
    await axios
      .get("https://nawyapp.com/api/property/" + id)
      .then(({ data }) => {
        setlat(data[0].location.latitud);
        setpeo(data[0].id);
        setlog(data[0].location.longitude);
        setproperty(data);
        fetchimages(data[0].id);
      });
  };
  useEffect(() => {
    fetchproperty();
  }, []);

  const { t } = useTranslation();

  return (
    <div className="bg-[#EBEAFA]">
      {property.length ? (
        <div className="">
          <Carousel property={property[0]} proId={proId} />
          <DescriptionCarousel property={property} />
          <DescriptionCard setDesCard={setDesCard} />
          {DesCard === 0 && <DescriptionProperty property={property} />}
          {DesCard === 1 && <Images images={images} />}
          {/* {DesCard === 1 && <Videos />} */}
          <div className="  ">
            {DesCard === 2 && <PropertyList property_id={property[0].id} />}
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
          {/* Animation Spinner */}
          <div className="flex justify-center items-center mb-5">
            <div className="w-12 h-12 border-4 border-purple-500 border-solid rounded-full border-t-transparent animate-spin"></div>
          </div>
          {/* Loading Text */}
          <div className="text-lg font-semibold text-purple-600">
            {t("lod")}
          </div>
        </div>
      )}
    </div>
  );
};
export default DetailsCardsProperty;
