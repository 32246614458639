import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

const Add3 = ({ updatecheckbox }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Checkbox state management
  const [checkedState, setCheckedState] = useState({
    apple_carplay: false,
    Touch_screen: false,
    media_screen: false,
    rear_camera: false,
    cruise_control: false,
    bluetooth: false,
  });

  // Handle checkbox change dynamically
  const handleCheckboxChange = (e) => {
    const { name } = e.target;
    setCheckedState({
      ...checkedState,
      [name]: !checkedState[name],
    });
    updatecheckbox(e);
  };

  const handleNext = () => {
    navigate("/vehicle/add5");
  };

  const features = [
    { name: "apple_carplay", label: t("add4_vehicle_apple") },
    { name: "Touch_screen", label: t("add4_vehicle_scr") },
    { name: "media_screen", label: t("add4_vehicle_modes") },
    { name: "rear_camera", label: t("add4_vehicle_cam") },
    { name: "cruise_control", label: t("add4_vehicle_speed") },
    { name: "bluetooth", label: t("add4_vehicle_blu") },
  ];

  return (
    <div className="bg-[#EBEAFA]  flex items-center justify-center">
      <div className="p-8 bg-white rounded-lg shadow-md max-w-3xl w-full">
        {/* Technology Features */}
        <h3 className="text-2xl font-black mb-6 text-right">{t("add4_vehicle_tech")}</h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          {features.map((feature, index) => (
            <div key={index} className="flex items-center">
              <label className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  name={feature.name}
                  checked={checkedState[feature.name]}
                  onChange={handleCheckboxChange}
                  className="form-checkbox h-5 w-5 text-[#800080] border-gray-300 rounded"
                />
                <span className="text-lg">{feature.label}</span>
              </label>
            </div>
          ))}
        </div>

        {/* Navigation Buttons */}
        <div className="flex justify-between items-center mt-8">
          <Link to="/vehicle/add3">
            <div className="bg-white rounded-full w-10 h-10 flex justify-center items-center shadow-md hover:bg-[#FFA500] transition duration-300">
              <svg
                width="15"
                height="20"
                viewBox="0 0 10 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.24023 14.48L1.76023 7.99998L8.24023 1.51998"
                  stroke="#000"
                  strokeWidth="1.6"
                />
              </svg>
            </div>
          </Link>
          <span className="text-lg font-medium">4/6</span>
          <button
            onClick={handleNext}
            className="bg-[#FFA500] text-white rounded-full px-8 py-3 text-lg font-semibold shadow-md hover:bg-[#FF8C00] transition duration-300"
          >
            {t("add1_vehicle_next")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Add3;
