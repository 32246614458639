import React, { useEffect, useState } from "react";
import Car from "../../assets/ImagesVehicle/car.png";
import { useTranslation } from "react-i18next";
import axios from "axios";

const VehicleList = ({vId}) => {

  const {t} = useTranslation();

  useEffect(() => {
    fetchSimilar();
 
  }, [])
  const [image, setImage] = useState([])
  const [vehicles, setvehicles] = useState([]);
  const fetchSimilar = async () => {
      
    await axios.get(`https://nawyapp.com/api/similar_vehicle/` + vId).then(({ data }) => {
      console.log(data)
      setvehicles(data.Similar)
      setImage(data.images)
    });
  }
  
  return (
    <div className=" text-right pt-12 bg-[#EBEAFA] m-[60px]">
      <main>
        <section className="flex flex-wrap justify-center max-w-7xl mx-auto py-3">
          {vehicles.map((vehicle) => (
            <div
              key={vehicle.id}
              className="w-full sm:w-1/2 lg:w-1/2 p-2 my-[-60px] mt-[-20px] pb-[-40px]"
            >
              <div className="w-full h-[60%] rounded-lg shadow-md overflow-hidden mb-2 sm:p-2 bg-white float-right">

                <div className="flex text-center">
                  {
                    image.map((i)=> (
                      i.fileable_id === vehicle.id?
                      <img
                      className="w-20 h-20 mx-[20px] object-cover"
                      src={`https://nawyapp.com/storage/${i.url_file}`}
                      alt="/"
                    /> 
                    : ''
                    ))
                   
                  }
           
                  <div className="p-4 flex flex-col justify-between float-right mt-[-15px]">
                    <div>
                      <div className="mb-12">
                        <h3 className="text-[25px] mr-[2px] font-semibold">
                          {vehicle.vechicle_make.name} - {vehicle.vechicle_model.name}
                        </h3>

                        <p className="text-gray-500 flex float-right ml-3 pt-2 pb-2 mr-[2px]">
                        </p>
                        <p className="text-blue-700 text-md p-2 mr-[2px] text-lg font-semibold">
                          {vehicle.price} $
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </section>
      </main>
    </div>
  );
};

export default VehicleList;
