import React, { useEffect, useState } from 'react'
import OrderComponents from '../components/OrderComponents'
import SidebarC from '../components/SidebarC'
import axios from 'axios';
import { useTranslation } from 'react-i18next';

function Order() {
    
    const {t} = useTranslation();
    const [Order, setOrder] = useState([]);
    const [images,setimages] = useState([]);
    const [loading, setLoading] = useState(true);
    const token = localStorage.getItem("token");
        const fetchproperty = async () => {
        await axios.get('https://nawyapp.com/api/RequestAdBroker',
             {
                headers: {'Authorization': `Bearer ${token}`}
              }
        ).then(({ data }) => {
          setOrder(data)
          fetchimages(data.id); 
        });
      }
      useEffect(() => {
        fetchproperty();
      }, [])
      const fetchimages = async (id) => {
        await axios.get(`https://nawyapp.com/api/showfiles`, {
          params: {
            type: 'property',
            id: id
          }
        } ).then(({ data }) => {
          setimages(data)
          console.log(data)
          setLoading(false);
        });
      }
    
      if (loading) {
        
        return  <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
        {/* Animation Spinner */}
        <div className="flex justify-center items-center mb-5">
          <div className="w-12 h-12 border-4 border-purple-500 border-solid rounded-full border-t-transparent animate-spin"></div>
        </div>

        {/* Loading Text */}
        <div className="text-lg font-semibold text-purple-600">
          {t('loading')}
        </div>
      </div>;
      }
    return (
        <div className="bg-[#ebeafa]">
            {/* <SidebarC /> */}
            <div className="p-4 pt-32">
                <OrderComponents Order={Order} fetchproperty={fetchproperty}/>
            </div>
        </div>
    )
}
export default Order