import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import classNames from "classnames";
import { CheckIcon } from "@heroicons/react/solid";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import MapComponent from '../mapProperty'


const Add = () => {

  const [propertyData, setPropertyData] = useState({
    latitude: null,
    longitude: null,
    // أي حقول أخرى للعقار
  });
  const { i18n } = useTranslation();

  const currentLanguage = i18n.language;
  const handleLocationSelect = (location) => {
    setPropertyData({
      // ...propertyData,
      latitude: location.lat,
      longitude: location.lng
    });
  };



  const [fileimg, setFiles] = useState([]);
  const [base64Files, setBase64Files] = useState([]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*,video/*",
    onDrop: (acceptedFiles) => {
      setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
    },
  });
  const convertFilesToBase64 = async (files) => {
    const promises = files.map(file => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const fileType = file.type;
          resolve({ base64: reader.result, type: fileType });
        };
        reader.onerror = error => reject(error);
      });
    });

    try {
      const base64Files = await Promise.all(promises);
      setBase64Files(base64Files);
      return base64Files; // Return the base64 files
    } catch (error) {
      console.error('Error converting files to Base64:', error);
      throw error; // Rethrow the error for handling in the calling function
    }
  };

  const handleSubmit = async (response) => {
    try {
      const base64Files = await convertFilesToBase64(fileimg); // Await the conversion
      await axios.post('https://nawyapp.com/api/files', {
        image: base64Files,
        fileable_id: response,
        fileable_type: 'property'
      });
      console.log(base64Files)
      console.log('Upload successful:');
    } catch (error) {
      console.error('Error uploading files:', error);
  }
  };

  // const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const removeFile = (file) => () => {
    setFiles((prevFiles) => prevFiles.filter((f) => f !== file));
  };


  const [categoriesall, setCategories] = useState([]);
  useEffect(() => {
    fetchCategories();
  }, [])
  const fetchCategories = async () => {
    await axios.get('https://nawyapp.com/api/Property_type').then(({ data }) => {
      setCategories(data)
    });
  }

  const [property_id, setproperty_id] = useState(0)
  const [bedrooms, setBedrooms] = useState(1);
  const [bathrooms, setBathrooms] = useState(1);
  const [kitchens, setKitchens] = useState(1);
  const [livingRooms, setLivingRooms] = useState(1);
  const [windows, setWindow] = useState(1);
  const [streets, setStreets] = useState(1);
  const [Balcons, setBalcons] = useState(1);
  const [safety_rank, setsafety_rank] = useState(1);

  const handleIncrement = (setter) => () => setter((prev) => prev + 1);
  const handleDecrement = (setter) => () =>
    setter((prev) => (prev > 0 ? prev - 1 : 0));

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const [isChecked2, setIsChecked2] = useState(false);
  const [showmodel, setshowmodel] = useState(false)

  const handleCheckboxChange2 = () => {
    setIsChecked2(!isChecked2);
    isChecked2?setshowmodel(false):setshowmodel(true)
  };

  const [selectedOptions, setSelectedOptions] = useState([]);

  const { t } = useTranslation();

  const options = [
    t('add_property_terrace'),
    t('add_property_swimming'),
    t('add_property_assistant'),
    t('add_property_alarm'),
    t('add_property_air'),
    t('add_property_wifi'),
    t('add_property_furnished'),
    t('add_property_hall'),
    t('add_property_elevator'),
    t('add_property_garage'),
    t('add_property_garden'),
    t('add_property_fireplace'),
    t('add_property_club'),
    t('add_property_basement'),
  ];

  const toggleOption = (option) => {
    setSelectedOptions((prevSelectedOptions) =>

      prevSelectedOptions.includes(option)
        ? prevSelectedOptions.filter((selected) => selected !== option)
        : [...prevSelectedOptions, option]
    );
  };

  const [selectedOptions2, setSelectedOptions2] = useState(t('add_property_sale'));

  const options2 = [t('add_property_sale'), t('add_property_rent')];

  const toggleOption2 = (option2) => {
    setSelectedOptions2((prevSelectedOptions2) =>
      prevSelectedOptions2.includes(option2)
        ? prevSelectedOptions2.filter((selected2) => selected2 !== option2)
        : [...prevSelectedOptions2, option2]
    );
  };

  const [selectedOptions3, setSelectedOptions3] = useState(t('add_property_apartment'));


  const toggleOption3 = (option3) => {
    setSelectedOptions3((prevSelectedOptions3) =>
      prevSelectedOptions3.includes(option3)
        ? prevSelectedOptions3.filter((selected3) => selected3 !== option3)
        : [...prevSelectedOptions3, option3]
    );
  };

  const [selectedOptions4, setSelectedOptions4] = useState(t('add_property_monthly'));
  const options4 = [t('add_property_annual'), t('add_property_monthly'), t('add_property_v')];
  const toggleOption4 = (option4) => {
    setSelectedOptions4((prevSelectedOptions4) =>
      prevSelectedOptions4.includes(option4)
        ? prevSelectedOptions4.filter((selected4) => selected4 !== option4)
        : [...prevSelectedOptions4, option4]
    );
  };
 const [price_negotiable,setprice_negotiable] = useState(0);


  const [location_id, setLocation_id] = useState('');
  const [price, setPrice] = useState('');
  const [orientation, setorientation] = useState('');
  const [square_footage, setsquare_footage] = useState('');
  const [availibility_date, setavailibility_date] = useState('');
  const [has_air_conditioning, sethas_air_conditioning] = useState(0);
  const [has_helper_room, sethas_helper_room] = useState(0);
  const [furnisheh, setfurnisheh] = useState(0);
  const [has_basement, sethas_basement] = useState(0);
  const [has_garage, sethas_garage] = useState(0);
  const [has_garden, sethas_garden] = useState(0);
  const [has_elevator, sethas_elevator] = useState(0);
  const [has_terrace, sethas_terrace] = useState(0);
  const [has_alarm_system, sethas_alarm_system] = useState(0);
  const [has_gym, sethas_gym] = useState(0);
  const [has_wifi, sethas_wifi] = useState(0);
  const [has_swimming, sethas_swimming] = useState(0);
  const [fireplace, setfireplace] = useState(0);
  const [description, setdescription] = useState('');

  const register_api = async (e) => {

   


    // selectedOptions.forEach(opt => {
    //   if (opt === t('add_property_terrace')) {
    //     sethas_terrace(1);
    //   }
    //   if (opt === t('add_property_swimming')) {
    //     sethas_swimming(1);
    //   }
    //   if (opt === t('add_property_assistant')) {
    //     sethas_helper_room(1)

    //   }
    //   if (opt === t('add_property_alarm')) {
    //     sethas_alarm_system(1)

    //   }
    //   if (opt === t('add_property_air')) {
    //     sethas_air_conditioning(1)
    //   }
    //   if (opt === t('add_property_wifi')) {
    //     sethas_wifi(1)

    //   }
    //   if (opt === t('add_property_furnished')) {
    //     setfurnisheh(1)

    //   }
    //   if (opt === t('add_property_elevator')) {
    //     sethas_elevator(1)
    //   }
    //   if (opt === t('add_property_garage')) {
    //     sethas_garage(1)
    //   }
    //   if (opt === t('add_property_garden')) {
    //     sethas_garden(1)
    //   }
    //   if (opt === t('add_property_fireplace')) {
    //     setfireplace(1)
    //   }
    //   if (opt === t('add_property_club')) {
    //     sethas_gym(1)
    //   }
    //   if (opt === t('add_property_basement')) {
    //     sethas_basement(1)
    //   }
    //   if (opt === t('add_property_hall')) {
    //   }
    // });







    selectedOptions.map((op) => (


      op === t('add_property_terrace') ? sethas_terrace(1) :
        op === t('add_property_swimming') ? sethas_swimming(1) :
          op === t('add_property_assistant') ? sethas_helper_room(1) :
            op === t('add_property_alarm') ? sethas_alarm_system(1) :
              op === t('add_property_air') ? sethas_air_conditioning(1) :
                op === t('add_property_wifi') ? sethas_wifi(1) :
                  op === t('add_property_furnished') ? setfurnisheh(1) :
                    // op === t('add_property_hall') ? set(true) : ""
                    op === t('add_property_elevator') ? sethas_elevator(1) :
                      op === t('add_property_garage') ? sethas_garage(1) :
                        op === t('add_property_garden') ? sethas_garden(1) :
                          op === t('add_property_fireplace') ? setfireplace(1) :
                            op === t('add_property_club') ? sethas_gym(1) :
                              op === t('add_property_basement') ? sethas_basement(1) : ""
    ))
    e.preventDefault();
    const formdata = new FormData();
    formdata.append('allow_buy_or_sell', selectedOptions2)
    formdata.append('latitude', propertyData.latitude)
    formdata.append('longitude', propertyData.longitude)
    formdata.append('has_air_conditioning', has_air_conditioning)
    formdata.append('has_alarm_system', has_alarm_system)
    formdata.append('has_helper_room', has_helper_room)
    formdata.append('has_swimming', has_swimming)
    formdata.append('square_footage', square_footage)
    formdata.append('has_terrace', has_terrace)
    formdata.append('has_wifi', has_wifi)
    formdata.append('payment_option', selectedOptions4)
    formdata.append('furnisheh', furnisheh)
    formdata.append('has_elevator', has_elevator)
    formdata.append('has_garage', has_garage)
    formdata.append('has_garden', has_garden)
    formdata.append('fireplace', fireplace)
    formdata.append('has_gym', has_gym)
    formdata.append('has_basement', has_basement)
    formdata.append('property_type_id', selectedOptions3)
    formdata.append('price', price)
    formdata.append('is_price_negotiable', isChecked2)
    formdata.append('orientation', orientation)
    formdata.append('number_of_streets_around', streets)
    formdata.append('number_of_rooms', livingRooms)
    formdata.append('number_of_bathroom', bathrooms)
    formdata.append('number_of_dining', bedrooms)
    formdata.append('number_of_windows', windows)
    formdata.append('availibility_date', availibility_date)
    formdata.append('safety_rank', safety_rank)
    formdata.append('kitchen', kitchens)
    formdata.append('description', description)
    try {
      const response = await axios.post('https://nawyapp.com/api/property', formdata,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
      console.log(response.data.property);
      handleSubmit(response.data.property);
      navigate('/share')
    } catch (error) {
      return error;
    }

    // alert('تمت إضافة العقار');
  }

  return (
    <div className="bg-[#EBEAFA]">
      <form action="" method="post" onSubmit={register_api}>
        <div className="p-8 rounded-2xl focus:bg-[#800080] focus:text-white max-w-3xl mx-auto">
          <h2 className="text-2xl focus:bg-[#800080] focus:text-white font-semibold mb-6 text-center mr-[90px]">
            {t('add1_property_fill')}
          </h2>

          <div className="grid grid-cols-1 md:grid-cols-1 gap-8">
            <div className="p-6 ml-[-80px] text-center">
              {options2.map((option2) => (
                <button
                  type="button"
                  key={option2}
                  onClick={() => setSelectedOptions2(option2)
                  }
                  className={`py-3 px-6 mx-1 rounded-2xl ${selectedOptions2.indexOf(option2)
                    ? "bg-white"
                    : "bg-[#800080] text-white"
                    }`}>
                  {option2}
                </button>
              ))}
            </div>
            <br></br> <br/>
            <div>
              <div className="grid grid-cols-1 sm:grid-cols-5 gap-4 mt-[-40px] ml-[50px]">
                
                {categoriesall.map((option3) => (
                  <button
                    type="button"
                    onClick={() => setSelectedOptions3(option3.id)}
                    className={`py-3 px-5 rounded-2xl ${selectedOptions3 !== option3.id
                      ? "bg-white"
                      : "bg-[#800080] text-white"
                      }`}>
                        
                    {currentLanguage==='en'?option3.name_en: option3.name_ar }
                  </button>
                ))}
              </div>
            </div>
            <div className="col-span-1 md:col-span-2 text-right">
              {selectedOptions2 === t('add_property_rent') ?
                <div className="">
                  <h3 className="text-xl font-semibold mb-[-35px]">{t('add_property_price2')}</h3>

                  <div className="flex items-center">
                    <input
                      type="number"

                      value={price}
                      onChange={(e) => { setPrice(e.target.value) }}
                      className="w-[60%] border text-black rounded-2xl focus:text-white-2 py-5 px-3 text-right focus:outline-none focus:border-purple-800 transition-colors"
                      placeholder=""
                    />
                    <span className="ml-2">$</span>
                  </div>

                </div> : selectedOptions2 === t('add_property_puy') ?
                  <div className="flex mx-36" >

                    <div className="flex items-center">
                      <input
                        type="number"

                        value={price}
                        onChange={(e) => { setPrice(e.target.value) }}
                        className="w-[63%] border text-black rounded-2xl focus:text-white-2 py-5 px-3 text-right focus:outline-none focus:border-purple-800 transition-colors"
                        placeholder="300"
                      />
                      <span className="ml-2"></span>
                      <h3 className="text-xl font-semibold ">من</h3>
                    </div>

                    <div className="flex items-center">
                      <input
                        type="number"

                        value={price}
                        onChange={(e) => { setPrice(e.target.value) }}
                        className="w-[63%] border text-black rounded-2xl focus:text-white-2 py-5 px-3 text-right focus:outline-none focus:border-purple-800 transition-colors"
                        placeholder="300"
                      />
                      <span className="ml-2"></span>
                      <h3 className="text-xl font-semibold ">إلى</h3>
                    </div>
                  </div>
                  : selectedOptions2 === t('add_property_sale') ? <div>
                    <h3 className="text-xl font-semibold mb-[-35px]">{t('add_property_price')}</h3>
                    <div className="flex items-center">
                      <input
                        type="number"

                        value={price}
                        onChange={(e) => { setPrice(e.target.value) }}

                        className="w-[60%] border rounded-2xl text-black py-5 px-3 text-right focus:outline-none focus:border-purple-800 transition-colors"
                        placeholder="500"
                      />
                      <span className="ml-2">$</span>
                    </div>

                  </div> : ""}

            </div>
            <div className="col-span-1 md:col-span-2 text-right">


              <label className="flex items-center mt-2">
                <input
                  type="checkbox"
                  className="hidden"
                  checked={isChecked2}
                  onChange={handleCheckboxChange2}
                />
                <span
                  className={classNames(
                    "w-4 h-4 border border-gray-300 rounded-sm flex-shrink-0",
                    {
                      "bg-green-500": isChecked2,
                    }
                  )}
                >
                  {isChecked2 && <CheckIcon className="w-4 h-4 text-white" />}
                </span>
                {t('add5_vehicle_price2')}
              </label>
              <div className="flex gap-2 mt-[3px] float-right mr-[300px]">
                {options4.map((option4) => (
                  <button
                    type="button"
                    onClick={() => setSelectedOptions4(option4)}
                    className={`py-3 px-5 rounded-2xl ${selectedOptions4 !== option4
                      ? "bg-white"
                      : "bg-[#800080] text-white"
                      }`}
                  >
                    {option4}
                  </button>
                ))}
              </div>
            </div>

            <div className="col-span-1 md:col-span-2 text-right">
              <h3 className="text-xl font-semibold mb-[-35px]">{t('add5_property_area')}</h3>
              <div className="flex items-center">
                <input
                  type="text"

                  value={square_footage}
                  onChange={(e) => { setsquare_footage(e.target.value) }}
                  className="w-[60%] border rounded-2xl text-black py-4 px-9 text-right focus:outline-none focus:border-purple-800 transition-colors"
                  placeholder={t('add_property_input')}
                />
                <span className="ml-[-35px]">
                  <svg
                    width="28"
                    height="28"
                    viewBox="0 0 35 33"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_565_36753)">
                      <path
                        d="M14.5712 25.6479L5.70212 16.7788C4.69825 15.775 4.69825 13.9266 5.70212 12.9227L14.5712 4.05369C15.575 3.04981 17.4234 3.04981 18.4273 4.05369L27.2963 12.9227C28.3002 13.9266 28.3002 15.775 27.2963 16.7788L18.4273 25.6479C17.4234 26.6518 15.575 26.6518 14.5712 25.6479V25.6479Z"
                        stroke="#252B5C"
                        stroke-width="1.7"
                      />
                      <path
                        d="M3.30078 21.7339L10.5009 28.934"
                        stroke="#252B5C"
                        stroke-width="2.5"
                      />
                      <path
                        d="M22.5 28.934L29.7001 21.7339"
                        stroke="#252B5C"
                        stroke-width=" 2.5"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_565_36753">
                        <rect
                          width="33"
                          height="33"
                          fill="white"
                          transform="translate(0 0.000976562)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
              </div>
            </div>

            <div className="col-span-1 md:col-span-2 text-right">
              <h3 className="text-xl font-semibold mb-[-35px]">{t('add_property_direction')}</h3>
              <div className="flex items-center">
                <input
                  type="text"

                  value={orientation}
                  onChange={(e) => { setorientation(e.target.value) }}
                  className="w-[60%] border rounded-2xl text-black py-4 px-3 text-right focus:outline-none focus:border-purple-800 transition-colors"
                  placeholder={t('add_property_direction2')}
                />
              </div>
            </div>

            <div className="col-span-1 md:col-span-2 text-right">
              <h3 className="text-xl font-semibold mb-[-35px] inline-block">
                {t('add_property_featu')}
              </h3>
              <div className="flex flex-wrap gap-2 p-2 mr-[20px]">
                <div className="w-[60%] flex items-center bg-white rounded-2xl focus:bg-[#800080] focus:text-white ml-[17px]">
                  <button
                    type="button"
                    className="bg-red-500 text-white py-1 px-3 rounded-2xl focus:bg-[#800080] focus:text-white m-4"
                    onClick={handleDecrement(setBedrooms)}
                  >
                    -
                  </button>
                  <span className="mx-2">{bedrooms}</span>
                  <button
                    type="button"
                    className="bg-green-500 text-white py-1 px-3 rounded-2xl focus:bg-[#800080] focus:text-white m-4"
                    onClick={handleIncrement(setBedrooms)}
                  >
                    +
                  </button>
                  <span className="pl-[44px] mr-8">{t('add_property_bedroom')}</span>
                </div>
              </div>

              <div className="flex flex-wrap gap-2 p-2 mr-[20px]">
                <div className="w-[60%] flex items-center bg-white rounded-2xl focus:bg-[#800080] focus:text-white ml-[17px]">
                  <button
                    type="button"
                    className="bg-red-500 text-white py-1 px-3 rounded-2xl focus:bg-[#800080] focus:text-white m-4"
                    onClick={handleDecrement(setBathrooms)}
                  >
                    -
                  </button>
                  <span className="mx-2">{bathrooms}</span>
                  <button
                    type="button"
                    className="bg-green-500 text-white py-1 px-3 rounded-2xl focus:bg-[#800080] focus:text-white m-4"
                    onClick={handleIncrement(setBathrooms)}
                  >
                    +
                  </button>
                  <span className="pl-[44px] mr-8">{t('add_property_bathroom')}</span>
                </div>
              </div>

              <div className="flex flex-wrap gap-2 p-2 2 mr-[20px]">
                <div className="w-[60%] flex items-center bg-white rounded-2xl focus:bg-[#800080] focus:text-white ml-[17px]">
                  <button
                    type="button"
                    className="bg-red-500 text-white py-1 px-3 rounded-2xl focus:bg-[#800080] focus:text-white m-4"
                    onClick={handleDecrement(setKitchens)}
                  >
                    -
                  </button>
                  <span className="mx-2">{kitchens}</span>
                  <button
                    type="button"
                    className="bg-green-500 text-white py-1 px-3 rounded-2xl focus:bg-[#800080] focus:text-white m-4"
                    onClick={handleIncrement(setKitchens)}
                  >
                    +
                  </button>
                  <span className="pl-[44px] mr-8">{t('add_property_kitchen')}</span>
                </div>
              </div>

              <div className="flex flex-wrap gap-2 p-2 mr-[20px]">
                <div className="w-[60%] flex items-center bg-white rounded-2xl focus:bg-[#800080] focus:text-white ml-[17px]">
                  <button
                    type="button"
                    className="bg-red-500 text-white py-1 px-3 rounded-2xl focus:bg-[#800080] focus:text-white m-4"
                    onClick={handleDecrement(setLivingRooms)}
                  >
                    -
                  </button>
                  <span className="mx-2">{livingRooms}</span>
                  <button
                    type="button"
                    className="bg-green-500 text-white py-1 px-3 rounded-2xl focus:bg-[#800080] focus:text-white m-4"
                    onClick={handleIncrement(setLivingRooms)}
                  >
                    +
                  </button>
                  <span className="pl-[44px] mr-8">{t('add_property_livingroom')}</span>
                </div>
              </div>

              <div className="flex flex-wrap gap-2 p-2 mr-[20px]">
                <div className="w-[60%] flex items-center bg-white rounded-2xl focus:bg-[#800080] focus:text-white ml-[17px]">
                  <button
                    type="button"
                    className="bg-red-500 text-white py-1 px-3 rounded-2xl focus:bg-[#800080] focus:text-white m-4"
                    onClick={handleDecrement(setWindow)}
                  >
                    -
                  </button>
                  <span className="mx-2">{windows}</span>
                  <button
                    className="bg-green-500 text-white py-1 px-3 rounded-2xl focus:bg-[#800080] focus:text-white m-4"
                    onClick={handleIncrement(setWindow)}
                    type="button"
                  >
                    +
                  </button>
                  <span className="pl-[44px] mr-8">{t('add_property_window')}</span>
                </div>
              </div>
              <div className="flex flex-wrap gap-2 p-2 mr-[20px]">
                <div className="w-[60%] flex items-center bg-white rounded-2xl focus:bg-[#800080] focus:text-white ml-[17px]">
                  <button
                    type="button"
                    className="bg-red-500 text-white py-1 px-3 rounded-2xl focus:bg-[#800080] focus:text-white m-4"
                    onClick={handleDecrement(setBalcons)}
                  >
                    -
                  </button>
                  <span className="mx-2">{Balcons}</span>
                  <button
                    type="button"
                    className="bg-green-500 text-white py-1 px-3 rounded-2xl focus:bg-[#800080] focus:text-white m-4"
                    onClick={handleIncrement(setBalcons)}
                  >
                    +
                  </button>
                  <span className="pl-[44px] mr-8">{t('add_property_street')}</span>

                </div>
              </div>

              <div className="flex flex-wrap gap-2 p-2 mr-[20px]">
                <div className="w-[60%] flex items-center bg-white rounded-2xl focus:bg-[#800080] focus:text-white ml-[17px]">
                  <button
                    type="button"
                    className="bg-red-500 text-white py-1 px-3 rounded-2xl focus:bg-[#800080] focus:text-white m-4"
                    onClick={handleDecrement(setStreets)}
                  >
                    -
                  </button>
                  <span className="mx-2">{streets}</span>
                  <button
                    type="button"
                    className="bg-green-500 text-white py-1 px-3 rounded-2xl focus:bg-[#800080] focus:text-white m-4"
                    onClick={handleIncrement(setStreets)}
                  >
                    +
                  </button>
                  <span className="pl-[44px] mr-8">{t('add_property_balcon')}</span>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-4 gap-4 mb-6">
              {options.map((option) => (
                <button
                  type="button"
                  key={option}
                  onClick={() => toggleOption(option)}
                  className={`py-2 px-4 rounded-2xl ${selectedOptions.includes(option)
                    ? "bg-[#800080] text-white"
                    : "bg-white"
                    }`}
                >
                  {option}
                </button>
              ))}
            </div>

            <h3 className="text-xl font-semibold">{t('add_property_enviroment')}</h3>
            <br></br>
            {selectedOptions2 !== t('add_property_puy') ?
              <div className="m-6 col-span-1 md:col-span-2 relative">
                <h3 className="text-xl font-semibold text-right mb-[-38px] mr-[-20px]">
                  {t('add_property_delivery')}
                </h3>
                <div className="flex items-center mt-[-30px]">
                  <input
                    type="date"

                    value={availibility_date}
                    onChange={(e) => { setavailibility_date(e.target.value) }}
                    placeholder={t('add_property_delivery_date')}
                    className="w-[60%] border rounded-2xl pr-10 focus:text-white-2xl py-4 px-3 text-right focus:outline-none focus:border-purple-800 transition-colors"
                    onFocus={(e) => (e.target.type = "date")}
                    onBlur={(e) => (e.target.type = "text")}
                  />
                  <span className="ml-[-35px]">
                    <svg
                      width="28"
                      height="28"
                      viewBox="0 0 35 33"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_565_36753)">
                        <path
                          d="M14.5712 25.6479L5.70212 16.7788C4.69825 15.775 4.69825 13.9266 5.70212 12.9227L14.5712 4.05369C15.575 3.04981 17.4234 3.04981 18.4273 4.05369L27.2963 12.9227C28.3002 13.9266 28.3002 15.775 27.2963 16.7788L18.4273 25.6479C17.4234 26.6518 15.575 26.6518 14.5712 25.6479V25.6479Z"
                          stroke="#252B5C"
                          stroke-width="1.7"
                        />
                        <path
                          d="M3.30078 21.7339L10.5009 28.934"
                          stroke="#252B5C"
                          stroke-width="2.5"
                        />
                        <path
                          d="M22.5 28.934L29.7001 21.7339"
                          stroke="#252B5C"
                          stroke-width=" 2.5"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_565_36753">
                          <rect
                            width="33"
                            height="33"
                            fill="white"
                            transform="translate(0 0.000976562)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                </div>
              </div>
              : ''}
          </div>
          <br></br>
          <br></br>
          {selectedOptions2 != t('add_property_puy') ?
            <div className="mb-8 pt-[60px]">
              <label className="text-xl block mb-2 font-semibold text-black text-right">
                {t('add_property_delivery_photo')}
              </label>
              <div className="mt-4 grid grid-cols-3 gap-3">
                {fileimg.map((file, index) => (
                  <div key={index} className="relative">
                    <div className="absolute top-0 right-20">
                      <button
                        type="button"
                        onClick={removeFile(file)}
                        className="bg-green-600 text-white rounded-full p-1 w-6 h-8  hover:bg-red-700"
                      >
                        x
                      </button>
                    </div>
                    {file.type.startsWith("image") ? (

                      <img
                        src={URL.createObjectURL(file)}
                        alt={file.name}
                        className="w-36 h-36 object-cover rounded"
                      />
                    ) : (
                      <video
                        controls
                        src={URL.createObjectURL(file)}
                        className="w-36 h-36 object-cover rounded"
                      />
                    )}
                  </div>
                ))}
                <div
                  {...getRootProps({ className: "dropzone" })}
                  className="flex items-center justify-center w-36 h-36 rounded-2xl bg-white border-dashed border-2 cursor-pointer"
                >
                  <input {...getInputProps()} />
                  <span className="text-3xl">+</span>
                </div>
              </div>
            </div> : ''}
          {selectedOptions2 != t('add_property_puy') ? <div>
            <label className="text-xl block mb-2 font-semibold text-black text-right">
              {t('add_property_delivery_details')}
            </label>
            <div className="flex justify-center items-center p-4">
              <textarea

                className="text-right w-[60%] max-w-lg h-48 mr-[270px] p-4 border-2 border-gray-300 rounded-lg focus:outline-none focus:border-purple-800 transition-colors"
                placeholder={t('add_property_delivery_details2')}
                value={description}
                onChange={(e) => { setdescription(e.target.value) }}
              />
            </div> </div> : ''}
          <div className="mb-6">
            <label className="block mb-2 text-xl font-bold text-black text-right">
              {t('add_property_delivery_locate')}
            </label>
            <MapComponent onLocationSelect={handleLocationSelect} />
          </div>

          {/* <Link to="/property/share"> */}
          <button type="submit" className="bg-[#FFA500] ml-[130px] hover:bg-amber-500 text-[#fff] w-[250px] rounded-full font-medium my-6 mx-auto md:mx-90 py-3 text-[23px]">
            {t('add1_vehicle_next')}
          </button>
          {/* </Link> */}

          <Link to="">
            <div className=" mt-[30px] bg-white rounded-full w-10 h-10 p-3 flex justify-center float-left hover:bg-[#FFA500]">
              <svg
                width="15"
                height="20"
                viewBox="0 0 10 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.24023 14.48L1.76023 7.99998L8.24023 1.51998"
                  stroke="#000"
                  stroke-width="1.6"
                />
              </svg>
            </div>
          </Link>
        </div>
      </form>
      {showmodel && (
        <div className="fixed inset-0 z-50 flex items-center justify-center min-h-screen bg-gray-500 bg-opacity-75">
        <div className="bg-white rounded-lg shadow-xl p-6 w-full max-w-lg">
          <h3 className="text-2xl font-semibold mb-4 text-center">{t('price_negotiable')}</h3>
          <input
            type="text"
            name="price_negotiable"
            value={price_negotiable}
            onChange={(e) => setprice_negotiable(e.target.value)}
            
            className="w-full p-3 border rounded-xl text-right"
            placeholder="Enter negotiable price"
          />
          <div className="flex justify-center mt-6">
            <button
              className="bg-[#FFA500] text-white py-2 px-6 rounded-md shadow-md hover:bg-[#800080] transition duration-300"
              onClick={() => setshowmodel(false)}
            >
              {t('profile_send')}
            </button>
            <button
              className="ml-4 bg-white text-gray-700 py-2 px-6 rounded-md border border-gray-300 shadow-md hover:bg-gray-50 transition duration-300"
              onClick={() => setshowmodel(false)}
            >
              {t('profile_close')}
            </button>
          </div>
        </div>
      </div>
      )}
    </div>
  );
};

export default Add;
