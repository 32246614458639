import React, { useEffect, useState } from "react";
import Laptop from "../../assets/Images/Flat1.png";
import Nawi from "../../assets/Images/Flat2.png";
import { useTranslation } from "react-i18next";
import axios from "axios";


const Images = ({images}) => {
  const { t } = useTranslation();

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="mb-8">
        <h2 className="text-2xl font-bold flex justify-between items-center">
          {t('des_card_flat_img')} 
          <span className="text-[#800080]">(6)</span>
        </h2>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
      {images.map((index) => (
          <button
            key={index.id}
            className={"sm:w-auto object-cover w-full h-full"}
          >
            {index.type=== 'image'?
                 <img
                 src={`https://nawyapp.com/storage/${index.url_file}`}
                 alt={`Thumbnail ${index.type}`}
                 className="w-[500px] h-[400px] object-cover"
               /> :
               <video className="w-full h-full sm:w-auto object-cover" controls>
               <source src={`https://nawyapp.com/storage/${index.url_file}`} type="video/mp4" />
             </video> 
          }    
          </button>
        ))}
      </div>
    </div>
  );
};

export default Images;

