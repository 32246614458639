// import React, { useState } from 'react';
// import { useDropzone } from 'react-dropzone';
// import { Link, useNavigate } from 'react-router-dom';
// import { useTranslation } from "react-i18next";
// import { FaUpload } from 'react-icons/fa';
// import axios from 'axios';

// const JoinBrokerComponent = () => {
//   const { t } = useTranslation();
//   const navigate = useNavigate();

//   const [formData, setFormData] = useState({
//     license_number: '',
//     description: '',
//     gender: '',
//   });

//   const [files, setFiles] = useState([]);
//   const [error, setError] = useState('');

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData(prevData => ({ ...prevData, [name]: value }));
//   };

//   const { getRootProps, getInputProps, isDragActive } = useDropzone({
//     accept: "image/*",
//     onDrop: (acceptedFiles) => {
//       setFiles(acceptedFiles);
//     },
//   });

//   const removeFile = () => {
//     setFiles([]);
//   };

//   const [base64Files, setBase64Files] = useState([]);
//   const convertFilesToBase64 = async (files) => {
//       const promises = files.map(file => {
//           return new Promise((resolve, reject) => {
//               const reader = new FileReader();
//               reader.readAsDataURL(file);
//               reader.onload = () => {
//                   const fileType = file.type;
//                   resolve({ base64: reader.result, type: fileType });
//               };
//               reader.onerror = error => reject(error);
//           });
//       });

//       try {
//           const base64Files = await Promise.all(promises);
//           setBase64Files(base64Files);
//           return base64Files; // Return the base64 files
//       } catch (error) {
//           console.error('Error converting files to Base64:', error);
//           throw error; // Rethrow the error for handling in the calling function
//       }
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setError('');

//     try {
//       const base64File = await convertFilesToBase64(files);
//       const data = {
//         ...formData,
//         image: base64File,
//       };

//       const token = localStorage.getItem('token');
//       await axios.post('https://nawyapp.com/api/Broker', data, {
//         headers: {
//           'Authorization': `Bearer ${token}`,
//           'Content-Type': 'application/json',
//         }
//       });

//       alert(t('request_sent_successfully'));
//       navigate('/login');
//     } catch (error) {
//       setError(t('error_submitting_form'));
//       console.error('Error submitting form:', error);
//     }
//   };

//   return (
//     <div className=" bg-[#ebeafa] py-12 px-4 sm:px-6 lg:px-8">
//       <div className="max-w-3xl mx-auto bg-white shadow-xl rounded-lg overflow-hidden">
//         <form onSubmit={handleSubmit} className="space-y-6 p-8">
//           <h2 className="text-3xl font-bold text-center text-gray-900 mb-8">{t('JoinBroker_title')}</h2>
          
//           {error && <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">{error}</div>}

//           <FormField
//             label={t('JoinTravel_number')}
//             name="license_number"
//             type="text"
//             placeholder="5855524"
//             value={formData.license_number}
//             onChange={handleInputChange}
//             required
//           />

//           <div className="space-y-2">
//             <label htmlFor="gender" className="block text-sm font-medium text-gray-700">
//               {t('JionInf_gender')}
//             </label>
//             <select
//               id="gender"
//               name="gender"
//               className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
//               value={formData.gender}
//               onChange={handleInputChange}
//               required
//             >
//               <option value="">{t('JionInf_gender_select')}</option>
//               <option value="male">{t('JionInf_gender_male')}</option>
//               <option value="female">{t('JionInf_gender_female')}</option>
//             </select>
//           </div>

//           <div className="space-y-2">
//             <label className="block text-sm font-medium text-gray-700">
//               {t('image')}
//             </label>
//             <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
//               {files.length === 0 ? (
//                 <div {...getRootProps()} className="space-y-1 text-center">
//                   <FaUpload className="mx-auto h-12 w-12 text-gray-400" />
//                   <div className="flex text-sm text-gray-600">
//                     <label
//                       htmlFor="file-upload"
//                       className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
//                     >
//                       <span>{isDragActive ? t('drop_files_here') : t('upload_a_file')}</span>
//                       <input {...getInputProps()} id="file-upload" name="file-upload" type="file" className="sr-only" />
//                     </label>
//                     <p className="pl-1">{t('or_drag_and_drop')}</p>
//                   </div>
//                   <p className="text-xs text-gray-500">{t('png_jpg_gif_up_to_10mb')}</p>
//                 </div>
//               ) : (
//                 <div className="flex items-center">
//                   <img src={URL.createObjectURL(files[0])} alt="Uploaded file" className="h-32 object-cover" />
//                   <button
//                     type="button"
//                     onClick={removeFile}
//                     className="ml-4 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
//                   >
//                     {t('remove')}
//                   </button>
//                 </div>
//               )}
//             </div>
//           </div>

//           <FormField
//             label={t('description')}
//             name="description"
//             as="textarea"
//             rows="4"
//             value={formData.description}
//             onChange={handleInputChange}
//             required
//           />

//           <div className="flex items-center justify-between pt-6">
//             <Link
//               to="/"
//               className="bg-gray-200 text-gray-700 px-6 py-2 rounded-md text-sm font-medium hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
//             >
//               {t('content_back')}
//             </Link>
//             <button
//               type="submit"
//               className="bg-[#FFA500] text-white px-6 py-2 rounded-md text-sm font-medium hover:bg-[#800080] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#FFA500]"
//             >
//               {t('JionInf_send')}
//             </button>
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// };

// const FormField = ({ label, name, as: Component = 'input', ...rest }) => (
//   <div className="space-y-2">
//     <label htmlFor={name} className="block text-sm font-medium text-gray-700">
//       {label}
//     </label>
//     <Component
//       id={name}
//       name={name}
//       className="mt-1 block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 rounded-md"
//       {...rest}
//     />
//   </div>
// );

// export default JoinBrokerComponent;


import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { FaUpload, FaTimes } from 'react-icons/fa';
import axios from 'axios';

function JoinBrokerComponents() {
    const { t } = useTranslation();
    // const [files, setFiles] = useState();
    const [licenseNumber, setLicenseNumber] = useState();
    const [description, setDescription] = useState('');
    const navigate = useNavigate('');
    const [selectedValue, setSelectedValue] = useState('');
    const handleSelectChange = (event) => {
        setSelectedValue(event.target.value);
    };

    const Broker_api = async (e) => {
        e.preventDefault();
        const base64Files12 = await convertFilesToBase64(files);
        const data = {
            license_number: licenseNumber,
            description: description,
            gender: selectedValue,
            image: base64Files12[0], // إرسال Base64 كجزء من JSON
        };
        const token = localStorage.getItem('token');

        await axios.post('https://nawyapp.com/api/Broker', data,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json', // تأكد من تعيين Content-Type إلى application/json
                }
            }).then(() => {

                navigate('/login')
            }).catch((error) => {
                console.log(error);
            });
        alert('تم إرسال طلبك إلى الادمان سيتم الرد عليه قريبا')

    }


    const [files, setFiles] = useState([]);
    const { getRootProps, getInputProps } = useDropzone({
        accept: "image/*",
        onDrop: (acceptedFiles) => {
            setFiles((prevFiles) => []);
            setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
        },
    });
    const removeFile = (file) => () => {
        setFiles((prevFiles) => prevFiles.filter((f) => f !== file));
    };

    const [base64Files, setBase64Files] = useState([]);
    const convertFilesToBase64 = async (files) => {
        const promises = files.map(file => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    const fileType = file.type;
                    resolve({ base64: reader.result, type: fileType });
                };
                reader.onerror = error => reject(error);
            });
        });

        try {
            const base64Files = await Promise.all(promises);
            setBase64Files(base64Files);
            return base64Files; // Return the base64 files
        } catch (error) {
            console.error('Error converting files to Base64:', error);
            throw error; // Rethrow the error for handling in the calling function
        }
    };





    // const onDrop = useCallback((acceptedFiles) => {
    //     setFiles(prevFiles => [...prevFiles, ...acceptedFiles.map(file => Object.assign(file, {
    //         preview: URL.createObjectURL(file)
    //     }))]);
    // }, []);

    // const { getRootProps, getInputProps, isDragActive } = useDropzone({
    //     accept: 'image/*,video/*',
    //     onDrop
    // });



    // const removeFile = (file) => {
    //     setFiles(prevFiles => prevFiles.filter(f => f !== file));
    //     URL.revokeObjectURL(file.preview);
    // };


    return (
        <div className=' bg-[#ebeafa] py-12 px-4 sm:px-6 lg:px-8'>
            <form action="" method="post" onSubmit={Broker_api}>
                <div className="max-w-3xl mx-auto bg-white rounded-lg shadow-md p-6">
                    <h2 className="text-2xl font-bold text-center mb-8">{t('JoinBroker_title')}</h2>
                    <div className="space-y-6">
                        <div className="flex flex-col space-y-2">
                            <label htmlFor="licenseNumber" className='text-lg font-medium'>{t('JoinTravel_number')}</label>
                            <input
                                id="licenseNumber"
                                type="text"
                                required
                                value={licenseNumber}
                                onChange={(e) => setLicenseNumber(e.target.value)}
                                placeholder="5855524"
                                className="p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600"
                            />
                        </div>
                        <div className="flex flex-col space-y-2">
                            <label className='px-9 text-lg font-bold'> {t('JionInf_gender')} </label>
                            <select required className="p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600" value={selectedValue} onChange={handleSelectChange}>
                                <option value="">{t('JionInf_gender_select')}</option>
                                <option value="ذكر">{t('JionInf_gender_male')}</option>
                                <option value="انثى">{t('JionInf_gender_female')}</option>
                            </select>
                        </div>

                        <div className="relative w-full  ">
                                {files.length === 0 ?
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <label className='px-9 text-lg font-bold'> {t('image')}  </label> <br/> <br/>
                                        <div className="flex items-center justify-center w-[100%]">
                                            <label for="dropzone-file" className="flex flex-col items-center justify-center  w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50  dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                                    <svg className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                                                    </svg>
                                                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Click to upload</span> or drag and drop</p>
                                                    <p className="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                                                </div>
                                            </label>
                                            
                                        </div>

                                    </div>

                                    : files.map((file, index) => (
                                        <div>
                                            <label className='px-24 text-lg  font-bold'> {t('image')}  </label> <br/>
                                            <button
                                                    onClick={removeFile(file)}
                                                    className="bg-green-600 text-white z-10 relative rounded-full p-1 w-10 h-8  hover:bg-red-700"
                                                >
                                                    x
                                                </button>

                                            <img key={index} src={URL.createObjectURL(file)} width="50%" className='resize-none h-64' alt="" srcset="" />
                                            <div className=" top-0 left-5 -mx-10">
                                   
                                            </div>
                                        </div>

                                    ))} </div>

                        {/* <div className="flex flex-col space-y-2">
                            <label className='text-lg font-medium'>{t('image')}</label>
                            <div {...getRootProps()} className="border-2 border-dashed border-gray-300 rounded-md p-6 cursor-pointer hover:border-purple-600 transition duration-300 ease-in-out">
                                <input {...getInputProps()} />
                                {isDragActive ? (
                                    <p className="text-center text-gray-600">{t('drop_files_here')}</p>
                                ) : (
                                    <div className="text-center">
                                        <FaUpload className="mx-auto text-4xl text-gray-400 mb-4" />
                                        <p className="text-gray-600">{t('drag_drop_or_click')}</p>
                                    </div>
                                )}
                            </div>
                        </div>

                        {files === '' && (
                            <div className="grid grid-cols-2 sm:grid-cols-3 gap-4 mt-4">

                                <div className="relative group">
                                    <img src={files.preview} alt={`preview`} className="w-full h-32 object-cover rounded-md" />
                                    <button
                                        onClick={() => removeFile(files)}
                                        className="absolute top-1 right-1 bg-red-500 text-white rounded-full p-1 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                        <FaTimes />
                                    </button>
                                </div>

                            </div>
                        )} */}

                        <div className="flex flex-col space-y-2">
                            <label htmlFor="description" className='text-lg font-medium'>{t('description')}</label>
                            <textarea
                                id="description"
                                required
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                placeholder={t('description')}
                                rows="5"
                                className="p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600"
                            />
                        </div>
                    </div>

                    <div className="flex justify-center space-x-4 mt-8">
                        <Link to="/">
                            <button className="px-6 py-2 bg-gray-300 text-black rounded-full hover:bg-gray-400 transition duration-300 ease-in-out">
                                {t('content_back')}
                            </button>
                        </Link>

                        <button type="submit" className="px-6 py-2 bg-[#FFA500] text-white rounded-full hover:bg-[#800080] transition duration-300 ease-in-out">
                            {t('JionInf_send')}
                        </button>

                    </div>
                </div>
            </form>
        </div>
    );
}

export default JoinBrokerComponents;