import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import vector from '../assets/ImagesVehicle/person.png';
import axios from 'axios';

const NotificationItem = ({id, message, date, sender,delete_api }) => (
  <div className="bg-white rounded-xl shadow-md p-4 mb-4 transition-all duration-300 hover:shadow-lg">
    <div className="flex items-center justify-between">
      <div className="flex items-center space-x-3 rtl:space-x-reverse">
        <div className=" text-right">
          <button onClick={() => delete_api(id)} className='rounded-full p-1 bg-red-800'>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-x text-white" viewBox="0 0 16 16">
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
            </svg>
          </button>
        </div>

        <img className="w-12 h-12 rounded-full" src={vector}  />
        <div>
          <h3 className="font-bold text-lg">{sender}</h3>
          <p className="text-gray-600">{message}</p>
        </div>
      </div>
      <span className="text-sm text-gray-500">{date}</span>
    </div>
  </div>
);

const Notificationsall = () => {

  const token = localStorage.getItem('token');
  useEffect(() => {
    fetchCategories();
  }, [])
  const fetchCategories = async () => {
    await axios.get('https://nawyapp.com/api/Notification',
      {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }).then(({ data }) => {
        setNotifi(data)
      });
  }

  const { t } = useTranslation();
  const [activeFilter, setActiveFilter] = useState('all');
  const [notifications, setNotifi] = useState([]);

  const delete_api = async (id) => {

    await axios.delete('https://nawyapp.com/api/delete_notification/' + id
    ).then(() => {
        fetchCategories();
    });
}


  const filters = [
    { key: 'all', label: t('sort_all') },
    // { key: 'read_at', label: t('Unread') },
    // { key: 'property', label: t('sort_propety') },
    // { key: 'travel', label: t('sort_Travel') },
  ];

  

  // const notifications = [
  //   { id: 1, name: 'محمد خليل', message: 'New car listing added', type: 'car' },
  //   { id: 2, name: 'أحمد علي', message: 'Property inquiry received', type: 'property' },
  //   { id: 3, name: 'فاطمة حسن', message: 'Travel package booked', type: 'travel' },
  //   { id: 4, name: 'عمر محمود', message: 'Car rental confirmed', type: 'car' },
  //   { id: 5, name: 'زينب سعيد', message: 'New property review', type: 'property' },
  // ];


  return (
    <div className="bg-gray-100  p-4 md:p-8">
      <div className="max-w-3xl mx-auto">
        <h1 className="text-3xl font-bold text-center mb-8">{t('notifications')}</h1>

        {/* <div className="flex flex-wrap justify-center gap-2 mb-8">
          {filters.map((filter) => (
            <button
              key={filter.key}
              onClick={() => setActiveFilter(filter.key)}
              className={`px-4 py-2 rounded-full transition-colors duration-300 ${activeFilter === filter.key
                ? 'bg-purple-600 text-white'
                : 'bg-white text-gray-700 hover:bg-purple-100'
                }`}
            >
              {filter.label}
            </button>
          ))}
        </div> */}

        <div className="space-y-4">
          {notifications.map((notification) => (
            <NotificationItem
              id={notification.id}
              delete_api={delete_api}
              message={notification.data.message}
              date={notification.updated_at}
              sender={notification.data.sender}
            />
          ))}
        </div>

        {notifications.length === 0 && (
          <p className="text-center text-gray-500 mt-8">
            {t('no_notifications')}
          </p>
        )}
      </div>
    </div>
  );
};

export default Notificationsall;