// import React, { useState } from 'react';
// import { useDropzone } from 'react-dropzone';
// import { Link, useNavigate } from 'react-router-dom';
// import { useTranslation } from "react-i18next";
// import axios from 'axios';
// import '../assets/Css/style.css';

// const JoinInfluencer = () => {
//   const { t } = useTranslation();
//   const navigate = useNavigate();
//   const token = localStorage.getItem('token');

//   const [formData, setFormData] = useState({
//     bio: '',
//     instagram: '',
//     snapchat: '',
//     gender: '',
//   });
//   const [files, setFiles] = useState([]);

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData(prevData => ({ ...prevData, [name]: value }));
//   };

//   const { getRootProps, getInputProps } = useDropzone({
//     accept: "image/*",
//     onDrop: (acceptedFiles) => {
//       setFiles(acceptedFiles);
//     },
//   });

//   const removeFile = () => {
//     setFiles([]);
//   };

//   const convertToBase64 = (file) => {
//     return new Promise((resolve, reject) => {
//       const reader = new FileReader();
//       reader.readAsDataURL(file);
//       reader.onload = () => resolve(reader.result);
//       reader.onerror = (error) => reject(error);
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const base64File = files.length > 0 ? await convertToBase64(files[0]) : null;
//       const data = {
//         ...formData,
//         image: base64File,
//       };

//       await axios.post('https://nawyapp.com/api/Influencer/', data, {
//         headers: {
//           'Authorization': `Bearer ${token}`,
//           'Content-Type': 'application/json',
//         }
//       });

//       alert(t('request_sent_successfully'));
//       navigate('/login');
//     } catch (error) {
//       console.error('Error submitting form:', error);
//       alert(t('error_submitting_form'));
//     }
//   };

//   return (
//     <div className=" bg-[#ebeafa] py-12 px-4 sm:px-6 lg:px-8">
//       <div className="max-w-3xl mx-auto bg-white shadow-xl rounded-lg overflow-hidden">
//         <form onSubmit={handleSubmit} className="space-y-6 p-8">
//           <h2 className="text-3xl font-bold text-center text-gray-900 mb-8">{t('join_as_influencer')}</h2>

//           <FormField
//             label={t('JionInf_Instagram')}
//             name="instagram"
//             type="url"
//             placeholder="https://www.instagram.com/"
//             value={formData.instagram}
//             onChange={handleInputChange}
//             required
//           />

//           <FormField
//             label={t('JionInf_Snapchat')}
//             name="snapchat"
//             type="url"
//             placeholder="https://web.snapchat.com/"
//             value={formData.snapchat}
//             onChange={handleInputChange}
//             required
//           />

//           <div className="space-y-2">
//             <label htmlFor="gender" className="block text-sm font-medium text-gray-700">
//               {t('JionInf_gender')}
//             </label>
//             <select
//               id="gender"
//               name="gender"
//               className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
//               value={formData.gender}
//               onChange={handleInputChange}
//               required
//             >
//               <option value="">{t('JionInf_gender_select')}</option>
//               <option value="male">{t('JionInf_gender_male')}</option>
//               <option value="female">{t('JionInf_gender_female')}</option>
//             </select>
//           </div>

//           <div className="space-y-2">
//             <label className="block text-sm font-medium text-gray-700">
//               {t('image')}
//             </label>
//             <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
//               {files.length === 0 ? (
//                 <div {...getRootProps()} className="space-y-1 text-center">
//                   <svg
//                     className="mx-auto h-12 w-12 text-gray-400"
//                     stroke="currentColor"
//                     fill="none"
//                     viewBox="0 0 48 48"
//                     aria-hidden="true"
//                   >
//                     <path
//                       d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
//                       strokeWidth={2}
//                       strokeLinecap="round"
//                       strokeLinejoin="round"
//                     />
//                   </svg>
//                   <div className="flex text-sm text-gray-600">
//                     <label
//                       htmlFor="file-upload"
//                       className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
//                     >
//                       <span>{t('upload_a_file')}</span>
//                       <input {...getInputProps()} id="file-upload" name="file-upload" type="file" className="sr-only" />
//                     </label>
//                     <p className="pl-1">{t('or_drag_and_drop')}</p>
//                   </div>
//                   <p className="text-xs text-gray-500">{t('png_jpg_gif_up_to_10mb')}</p>
//                 </div>
//               ) : (
//                 <div className="flex items-center">
//                   <img src={URL.createObjectURL(files[0])} alt="Uploaded file" className="h-32 object-cover" />
//                   <button
//                     type="button"
//                     onClick={removeFile}
//                     className="ml-4 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
//                   >
//                     {t('remove')}
//                   </button>
//                 </div>
//               )}
//             </div>
//           </div>

//           <FormField
//             label={t('description')}
//             name="bio"
//             as="textarea"
//             rows="4"
//             value={formData.bio}
//             onChange={handleInputChange}
//             required
//           />

//           <div className="flex items-center justify-between pt-6">
//             <Link
//               to="/"
//               className="bg-gray-200 text-gray-700 px-6 py-2 rounded-md text-sm font-medium hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
//             >
//               {t('content_back')}
//             </Link>
//             <button
//               type="submit"
//               className="bg-indigo-600 text-white px-6 py-2 rounded-md text-sm font-medium hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
//             >
//               {t('JionInf_send')}
//             </button>
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// };

// const FormField = ({ label, name, as: Component = 'input', ...rest }) => (
//   <div className="space-y-2">
//     <label htmlFor={name} className="block text-sm font-medium text-gray-700">
//       {label}
//     </label>
//     <Component
//       id={name}
//       name={name}
//       className="mt-1 block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 rounded-md"
//       {...rest}
//     />
//   </div>
// );

// export default JoinInfluencer;

import React, { useState } from 'react'
import { useDropzone } from 'react-dropzone';
import { Link, useNavigate } from 'react-router-dom'
import '../assets/Css/style.css'
import { useTranslation } from "react-i18next";
import axios from 'axios';


function JionInfluencer() {

    const token = localStorage.getItem('token');
    const navigate = useNavigate('');
    const [bio, setBio] = useState('');
    const [instagram, setinstagram] = useState('');
    const [snapchat, setsnapchat] = useState('');


    const Influencer_api = async (e) => {
        e.preventDefault();
        const base64Files12 = await convertFilesToBase64(files);

        const data = {
            bio: bio,
            instagram: instagram,
            snapchat: snapchat,
            gender: selectedValue,
            image: base64Files12[0], // إرسال Base64 كجزء من JSON
        };

        await axios.post('https://nawyapp.com/api/Influencer', data, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json', // تأكد من تعيين Content-Type إلى application/json
            }
        }).then(() => {
            navigate('/login')
        }).catch((error) => {
            console.log(error);
        });
        alert('تم إرسال طلبك إلى الادارة سيتم الرد عليه قريباً');
    };

    const { t } = useTranslation();
    const [files, setFiles] = useState([]);
    const [selectedValue, setSelectedValue] = useState('');
    const handleSelectChange = (event) => {
        setSelectedValue(event.target.value);
    };
    const { getRootProps, getInputProps } = useDropzone({
        accept: "image/*",
        onDrop: (acceptedFiles) => {
            setFiles((prevFiles) => []);
            setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
        },
    });
    const removeFile = (file) => () => {
        setFiles((prevFiles) => prevFiles.filter((f) => f !== file));
    };
    const [base64Files, setBase64Files] = useState([]);
    const convertFilesToBase64 = async (files) => {
        const promises = files.map(file => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    const fileType = file.type;
                    resolve({ base64: reader.result, type: fileType });
                };
                reader.onerror = error => reject(error);
            });
        });

        try {
            const base64Files = await Promise.all(promises);
            setBase64Files(base64Files);
            return base64Files; // Return the base64 files
        } catch (error) {
            console.error('Error converting files to Base64:', error);
            throw error; // Rethrow the error for handling in the calling function
        }
    };
    return (
        <div className='w-full justify-center  bg-[#ebeafa]'>
            <form action="" method="post" onSubmit={Influencer_api}>
                <div className=" flex flex-col items-center bg-[#EBEAFA]">
                    <div className="w-full max-w-5xl p-4 mt-10">
                        <div className="space-y-4">
                            <div className="relative left-1/4 pt-4">
                                <input
                                    type="url"
                                    required
                                    value={instagram}
                                    onChange={(e) => { setinstagram(e.target.value) }}
                                    placeholder=" https://www.instagram.com/"
                                    className="w-[49%] p-2 pr-10 border text-right border-gray-300 rounded-2xl focus:outline-none focus:ring-2 focus:ring-purple-600"
                                    onFocus={(e) => (e.target.type = "text")}
                                    onBlur={(e) => (e.target.type = "text")}
                                />
                                <label className='px-9 text-lg font-bold'> {t('JionInf_Instagram')} </label>
                            </div>
                            <div className="relative left-1/4 pt-4">
                                <input
                                    type="url"
                                    required
                                    value={snapchat}
                                    onChange={(e) => { setsnapchat(e.target.value) }}
                                    placeholder=" https://web.snapchat.com/"
                                    className="w-[49%] p-2 pr-10 border text-right border-gray-300 rounded-2xl focus:outline-none focus:ring-2 focus:ring-purple-600"
                                    onFocus={(e) => (e.target.type = "text")}
                                    onBlur={(e) => (e.target.type = "text")}
                                />
                                <label className='px-9 text-lg font-bold'> {t('JionInf_Snapchat')} </label>
                            </div>
                            <div className="relative left-1/4 pt-4">
                                <select required className="w-[49%] p-2 pr-10 border text-right  border-gray-300 rounded-2xl focus:outline-none focus:ring-2 focus:ring-purple-600" value={selectedValue} onChange={handleSelectChange}>
                                    <option value="">{t('JionInf_gender_select')}</option>
                                    <option value="ذكر">{t('JionInf_gender_male')}</option>
                                    <option value="انثى">{t('JionInf_gender_female')}</option>
                                </select>
                                <label className='px-9 text-lg font-bold'> {t('JionInf_gender')} </label>
                            </div>
                            <div className="relative left-1/4 pt-4">
                                {files.length === 0 ?
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <div className="flex items-center justify-center w-[60%]">
                                            <label for="dropzone-file" className="flex flex-col items-center justify-center  w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50  dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                                    <svg className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                                                    </svg>
                                                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Click to upload</span> or drag and drop</p>
                                                    <p className="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                                                </div>
                                            </label>
                                            <label className='px-9 text-lg font-bold'> {t('image')}  </label>
                                        </div>

                                    </div>

                                    : files.map((file, index) => (
                                        <div className="flex">
                                            <img key={index} src={URL.createObjectURL(file)} width="50%" className='resize-none h-64' alt="" srcset="" />
                                            <div className=" top-0 left-5 -mx-10">
                                                <button
                                                    onClick={removeFile(file)}
                                                    className="bg-green-600 text-white z-10 relative rounded-full p-1 w-10 h-8  hover:bg-red-700"
                                                >
                                                    x
                                                </button>
                                            </div>
                                            <label className='px-24 text-lg  font-bold'> {t('image')}  </label>
                                        </div>

                                    ))} </div>
                            <div className="relative left-1/4 pt-4">
                                <textarea
                                    placeholder={t('description')}
                                    required
                                    rows="10"
                                    value={bio}
                                    onChange={(e) => { setBio(e.target.value) }}
                                    className="w-[49%]  p-2 pr-10 border text-right border-gray-300 rounded-2xl focus:outline-none focus:ring-2 focus:ring-purple-600"
                                />
                                <label className='px-9 text-lg font-bold'>{t('description')}</label>
                            </div>
                        </div>

                        {/* أزرار التحكم */}
                        <div className="flex justify-center p-12">
                            <Link to="/">
                                <button className="bg-gray-300 text-black mx-[80px] px-7 py-3 rounded-3xl hover:bg-[#800080] hover:text-white">
                                    {t('content_back')}
                                </button>
                            </Link>

                            <button type='submit' className="bg-[#FFA500] w- text-white mx-[90px] px-7 py-3 rounded-3xl hover:bg-[#800080]">
                                {t('JionInf_send')}
                            </button>

                        </div>
                    </div>
                </div>
            </form>
        </div >
    )
}

export default JionInfluencer

